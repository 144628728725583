import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { loadingContext, restaurantContext, themeContext } from '../../App'
import bgVideo from "../../assets/back.mp4"
import { getMainCategories } from '../../services/maincategory.service'
import { toastError } from '../../utils/toastUtils'
import { generateFilePath } from '../../utils/utils'
import Header from '../ReuseableComponents/Header'
import RenderVideo from '../ReuseableComponents/RenderVideo'
import LoadingPage from './LoadingPage'
import TextBottomBar from './TextBottomBar'
export default function BeveragesCategory() {
    const [categoryMainArr, setCategoryMainArr] = useState([]);
    const [categoryArr, setCategoryArr] = useState([]);
    const [restaurantObj, setRestaurantObj] = useContext(restaurantContext);
    const [searchParams] = useSearchParams();
    const [themeIsLight, setThemeIsLight] = useContext(themeContext);
    const [showProductsNotFound, setShowProductsNotFound] = useState(false);


    const [loading, setLoading] = useContext(loadingContext);

    const handleGetCategory = async (restaurantId) => {
        try {
            // console.log("res.data")
            setLoading(true)
            let { data: res } = await getMainCategories(`restaurantId=${restaurantId}&tableId=${searchParams.get("tableId")}&foodType=beverage`)
            // console.log(res.data, "res.data")
            if (res.data) {
                setLoading(false)

                let tempArr = []

                for (let i = 0; i <= (res.data.length - 1); i++) {
                    for (let j = 0; j <= (res.data[i].categoryArr.length - 1); j++) {
                        if (!(tempArr.some(el => el.categoryname == res.data[i].categoryArr[j].categoryname))) {
                            tempArr.push(res.data[i].categoryArr[j])
                        }
                    }
                }
                tempArr = tempArr.sort((a, b) => {
                    let fa = a.categoryname.toLowerCase(),
                        fb = b.categoryname.toLowerCase();

                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                })
                // console.log(tempArr.map(el => el.categoryname), "tempArr")
                setCategoryMainArr([...tempArr])
            }
        }
        catch (err) {
            setLoading(false)
            toastError(err)
        }
    }


    useEffect(() => {
        if (restaurantObj && restaurantObj._id) {
            handleGetCategory(restaurantObj?.menu_sharing_code)
        }
    }, [restaurantObj])


    const handleNavigateToProductsPage = (catId) => {

        searchParams.delete("categoryId")
        searchParams.set("categoryId", catId)
        searchParams.set("previousRoute", "BeveragesCategory")

        // searchParams.set("categoryName", categoryName)
        // console.log(searchParams.toString())
        navigation({
            pathname: "/Products", search: encodeURI(searchParams)
        })
    }




    useEffect(() => {
        let tempArr = categoryMainArr
        if (searchParams.get("allergen")) {
            let tempSelectedAllergenArr = decodeURIComponent(searchParams.get("allergen")).split(",");
            // console.log(decodeURIComponent(searchParams.get("allergen")).split(","), 'searchParams.get("allergen")')
            tempArr = tempArr.filter(el => el.allergensArr.some(ele => tempSelectedAllergenArr.some(elx => elx == ele)))
        }
        if (searchParams.get("filter") == "veg") {
            tempArr = tempArr.filter(el => el.categoryType == "veg")
        }
        // console.log(tempArr, "tempArr")
        setCategoryArr([...tempArr])

    }, [categoryMainArr, searchParams])


    const navigation = useNavigate()

    useEffect(() => {
        if (loading == false) {
            setTimeout(() => {
                setShowProductsNotFound(true)
            }, 1000)
        }
        return setShowProductsNotFound(false)
    }, [loading])





    return (
        <>
            {
                loading && <LoadingPage />
            }

            <Header
                showSearch
                showFilter
                showBack
                showBill
                text="Select Category"
                backUrl={"/SelectCategory"}
            />

            <div className='container-fluid '>

                <RenderVideo mainVideo={bgVideo} />

                <div className="row d-flex justify-content-around mt-5">
                    {
                        categoryArr && categoryArr.length > 0 ? categoryArr.map((el, index) => {
                            return (
                                <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-6 my-2" onClick={() => handleNavigateToProductsPage(el.categoryid)}>
                                    <div className="menu-btn">
                                        <div className="menu-btn-img" style={{ borderColor: themeIsLight ? "rgba(0, 0, 0,0.4)" : "rgb(194, 194, 194)" }}>
                                            <img src={generateFilePath(el.category_image_url)} alt="" className={themeIsLight ? "imageToBlack" : "" + 'img-fluid'} />
                                        </div>
                                    </div>
                                    <div className="category-name" style={{ color: themeIsLight ? "black" : "white" }}>{el.categoryname}</div>
                                </div>
                            )
                        })
                            :
                            <div className="no-categories-found">

                                {
                                    showProductsNotFound &&
                                    <span>
                                        No Categories Found
                                    </span>
                                }
                            </div>
                    }
                </div>

            </div>
            <TextBottomBar />
        </>
    )
}
