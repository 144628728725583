import axiosApiInstance from "../utils/axiosApiInstance";
import { url } from "./url.service";

const serverUrl = url + "/product";

export const addProduct = (formData) => {
  return axiosApiInstance.post(serverUrl + "/addProduct", formData);
};

export const getProductByIds = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getProductByIds?${query}`);
};
export const getProducts = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getProducts?${query}`);
};
export const searchProductsByName = (query) => {
  return axiosApiInstance.get(`${serverUrl}/searchProducts?${query}`);
};
export const getProductByCategoryId = (query) => {
  // console.log(query, "query")
  return axiosApiInstance.get(`${serverUrl}/getProductByCategoryId?${query}`);
};

export const deleteProductById = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateProductById = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

export const watchProductVideo = (obj) => {
  return axiosApiInstance.post(`${serverUrl}/watchProductVideo`, obj)
}