import { useContext } from "react"
import { cartArrContext, loadingContext } from "../../App"
import { useSearchParams } from "react-router-dom"
import { getCart, getCartAddon } from "../../services/tableCart.service"
import { toastError } from "../../utils/toastUtils"

export const useRefreshCart = () => {
    const [loading, setLoading] = useContext(loadingContext);

    const [cartArr, setCartArr, cartObj, setCartObj, cartAddonArr, setCartAddonArr] = useContext(cartArrContext)
    const [searchParams] = useSearchParams()

    const refreshCart = async () => {
        console.log("REFRESGIN CART")
        try {
            let { data: res } = await getCart(searchParams.get("tableId"), searchParams.get("tableNo"), searchParams.get("restId"))
            if (res.data) {
                // console.log("tableCart", res.data)
                setCartArr(res.data.items);
                setCartObj(res.data)
                setLoading(false)
                // handleCartSyncWithDb()
            }
            else {
                setCartArr([])
                setLoading(false)
            }
        } catch (error) {
            toastError(error)
            setLoading(false)
        }
    }

    const refreshCartAddon = async () => {
        try {
            let { data: res } = await getCartAddon(searchParams.get("tableId"), searchParams.get("tableNo"), searchParams.get("restId"))
            if (res?.data) {
                setCartAddonArr(res.data);
            }
        } catch (error) {
            toastError(error)
        }
    }



    const refreshFunc = async () => {
        refreshCart()
        refreshCartAddon()
    }

    return refreshFunc;
}

export const useCart = () => {
    const [cartArr, setCartArr] = useContext(cartArrContext)
    return cartArr
}


export const useCartObj = () => {
    const [cartArr, setCartArr, cartObj] = useContext(cartArrContext)
    return cartObj
}


export const useCartAddonArr = () => {
    const [cartArr, setCartArr, cartObj, setCartObj, cartAddonArr] = useContext(cartArrContext)
    return cartAddonArr
}