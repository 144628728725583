

import axiosApiInstance from "../utils/axiosApiInstance";
import { url } from "./url.service";

const serverUrl = url + "/order";

export const SaveKot = (formData) => {
    return axiosApiInstance.post(serverUrl + "/SaveKot", formData);
};

export const payNow = (formData) => {
    return axiosApiInstance.post(serverUrl + "/payForTheCart", formData);
};

export const orderCallback = async (obj, id) => {
    return axiosApiInstance.get(`${serverUrl}/paymentCallback/${id}?${obj}`);
};

export const registerCustomer = (obj) => {
    return axiosApiInstance.post(`${serverUrl}/registerCustomer`, obj);
};
export const requestForBill = (obj) => {
    return axiosApiInstance.post(`${serverUrl}/requestForBill`, obj);
};
export const checkBill = (obj) => {
    return axiosApiInstance.post(`${serverUrl}/checkBill`, obj);
};
export const getBill = (obj) => {
    return axiosApiInstance.post(`${serverUrl}/getBill`, obj);
};


export const getTableBill = (obj) => {
    return axiosApiInstance.post(`${serverUrl}/getTableBill`, obj);
};
export const createOrderForBillReceived = (obj) => {
    return axiosApiInstance.post(`${serverUrl}/createOrderForBillReceived`, obj);
};

export const getOrderByIdApi = async (id) => {
    return axiosApiInstance.get(`${serverUrl}/getOrderById/${id}`);
};
// export const paymentCallback = (obj) => {
//     return axios.post(`${serverUrl}/getTableBill`, obj);
// };
// // 