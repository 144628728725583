import React, { useContext, useEffect } from "react";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import BiillWithoutCheckOut from "../Components/UserFlow/BiillWithoutCheckOut";
import Bill from "../Components/UserFlow/Bill";
import Billdetails from "../Components/UserFlow/Billdetails";
import Cart from "../Components/UserFlow/Cart";
import Category from "../Components/UserFlow/Category";
import CheckPincode from "../Components/UserFlow/CheckPincode";
import Filter from "../Components/UserFlow/Filter";
import GetBillPage from "../Components/UserFlow/GetBillPage";
import Home from "../Components/UserFlow/Home";
import InitialScreen from "../Components/UserFlow/InitialScreen";
import Productdatails from "../Components/UserFlow/Productdatails";
import Products from "../Components/UserFlow/Products";
import SelectCategory from "../Components/UserFlow/SelectCategory";
import SplashScreen from "../Components/UserFlow/SplashScreen";

import { loadingContext, themeContext } from "../App";
import Invoice from "../Components/UserFlow/Invoice";
import LoadingPage from "../Components/UserFlow/LoadingPage";
import OtpConfirmation from "../Components/UserFlow/OtpConfirmation";
import OtpPage from "../Components/UserFlow/OtpPage";
import RegistrationPage from "../Components/UserFlow/RegistrationPage";
import SearchProducts from "../Components/UserFlow/SearchProducts";
import MainCategory from "../Components/UserFlow/MainCategory";
import BeveragesCategory from "../Components/UserFlow/BeveragesCategory";
import FoodCategoryProducts from "../Components/UserFlow/FoodCategoryProducts";
import DashRecomendation from "../Components/UserFlow/DashRecomendation";
import RecomendationCategory from "../Components/UserFlow/RecomendationCategory";


const router = createBrowserRouter([

    {
        path: "/SplashScreen",
        element: <> <SplashScreen />  </>
    },
    {
        path: "/",
        element: <> <InitialScreen /> </>,
    },
    {
        path: "/SelectCategory",
        element: <> <SelectCategory /> </>,
    },
    {
        path: "/Home",
        element: <> <Home /> </>,
    },
    {
        path: "/check-avail-pincode",
        element: <> <CheckPincode /> </>,
    },
    {
        path: "/Category",
        element: <> <Category /> </>,
    },
    {
        path: "/Products",
        element: <> <Products /> </>,
    },
    {
        path: "/Cart",
        element: <> <Cart /> </>,
    },
    {
        path: "/Filter",
        element: <> <Filter /> </>,
    },
    {
        path: "/Productdatails",
        element: <> <Productdatails /> </>,
    },
    {
        path: "/GetBill",
        element: <> <GetBillPage /> </>,
    },
    {
        path: "/Bill",
        element: <> <Bill /> </>,
    },
    {
        path: "/BiillWithoutCheckOut",
        element: <> <BiillWithoutCheckOut /> </>,
    },
    {
        path: "/Billdetails",
        element: <> <Billdetails /> </>,
    },
    {
        path: "/SearchProducts",
        element: <> <SearchProducts /> </>,
    },
    {
        path: "/Invoice/:id",
        element: <> <Invoice /> </>,
    },
    {
        path: "/RegistrationPage",
        element: <> <RegistrationPage /> </>,
    },
    {
        path: "/OtpPage",
        element: <> <OtpPage /> </>,
    },
    {
        path: "/OtpConfirmation",
        element: <> <OtpConfirmation /> </>,
    },
    {
        path: "/LoadingPage",
        element: <> <LoadingPage /> </>,
    },
    {
        path: "/MainCategory",
        element: <> <MainCategory /> </>,
    },
    {
        path: "/BeveragesCategory",
        element: <> <BeveragesCategory /> </>,
    },
    {
        path: "/FoodCategoryProducts",
        element: <FoodCategoryProducts />,
    },
    {
        path: "/DashExplore",
        element: <DashRecomendation />,
    },
    {
        path: "/RecomendationCategory",
        element: <RecomendationCategory />,
    },


]);




export default function RootRouter() {
    const [themeIsLight, setThemeIsLight] = useContext(themeContext);

    const [loading, setLoading] = useContext(loadingContext);




    useEffect(() => {


        if (themeIsLight) {
            document.body.style.backgroundColor = "transparent";
        }
        else {
            document.body.style.backgroundColor = "rgba(0,0,0,0.7)";

        }


    }, [themeIsLight])

    // if (loading) {
    //     return (
    //         <LoadingPage />
    //     )
    // }
    // else {
    return (
        <RouterProvider router={router} />
    )
}
