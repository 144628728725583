import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import bgVideo from "../../assets/back.mp4"
import DineIn from "../../assets/dineIn.png"
import logo from "../../assets/logo.png"
import Header from '../ReuseableComponents/Header'
import RenderVideo from '../ReuseableComponents/RenderVideo'

export default function Home() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    const handleNavigateToMenu = () => {
        navigate({
            pathname: "/Home",
            search: encodeURI(searchParams),
        });
    };
    return (
        <>

            <Header />

            <RenderVideo mainVideo={bgVideo} />
            <div className='container-fluid '>

                <div className='row'>
                    <div className="logo-container">
                        <div onClick={() => handleNavigateToMenu()}><img src={logo} className="main-logo" alt="" /></div>
                    </div>
                </div>

                <div className="row gap-4 d-flex justify-content-center mt-5">
                    <div className='col-12 col-sm-10 col-md-8 '>
                        <div className='row'>
                            <div className="col-12 menu-btn1" onClick={() => navigate({ pathname: "/SelectCategory", search: encodeURI(searchParams) })}> <span style={{ fontSize: 18 }}>DINE IN</span> <img src={DineIn} style={{ height: 30, width: 30 }} alt="" /></div>
                            {/* <div className="col-12 menu-btn1" onClick={() => navigation("/Category")}>TAKE AWAY <img src={TakeAway} style={{ height: 20, width: 20 }} alt="" /></div>
                            <div className="col-12 menu-btn1" onClick={() => navigation("/check-avail-pincode")}>DELIVERY <img src={Delivery} style={{ height: 20, width: 20 }} alt="" /></div>
                            <div className="col-12 menu-btn1" onClick={() => navigation("/Category")}>IN ROOM <img src={RoomService} style={{ height: 20, width: 20 }} alt="" /></div> */}
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
