import React from 'react'
import logo from "../../assets/logo.png";
import bgVideo from "../../assets/back.mp4";
import RenderVideo from "../ReuseableComponents/RenderVideo";
import { Form,InputGroup,Button } from 'react-bootstrap';
function RegistrationPage() {
  return (
    <>
    <header className='reg-mobile-header'>
    <div className="logo-img">
    <img src={logo} alt="" className="img-fluid" style={{ height: 60, width: 60 }} />
    </div>
    </header>
    <div className="container-fluid">
    <RenderVideo mainVideo={bgVideo} />
  

      {/* mobile-registration-sec */}

      <section className="mobile-registration-sec">
       
<div className="container">
    <div className="row">
  
        <div className="col-lg-12 mx-auto">
        <div className="overlay1">
<div className="mobile-reg-box">
   
    <h6 className="mobileno-head">
Continue with your Mobile No
    </h6>
    <InputGroup className="mb-4 mobile-no-ip">
        <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
        <Form.Control
          placeholder="Your Mobile No"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>
      <Button variant="light" className='mobile-no-buttn'>Continue</Button>
</div>
        </div>
    </div>
</div>
</div>
</section>
</div>
    </>
  )
}

export default RegistrationPage