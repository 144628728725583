import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSearchParams } from "react-router-dom";
import Header from "../ReuseableComponents/Header";

import { Checkbox, checkboxClasses } from "@mui/material";
import { BsFillMicMuteFill, BsMicFill } from "react-icons/bs";
import { loadingContext, speedContext, themeContext } from "../../App";
import { getAddons } from "../../services/getAddons.service";
import {
  getProductByIds,
  watchProductVideo,
} from "../../services/product.service";
import {
  addItemToCart,
  removeItemToCart,
  updateItemToCart,
} from "../../services/tableCart.service";
import { toastError } from "../../utils/toastUtils";
import { generateFilePath } from "../../utils/utils";
import { useCart, useRefreshCart } from "../CustomHooks/cartHooks";
import LoadingPage from "./LoadingPage";
import { FaMinus, FaPlus } from "react-icons/fa";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import RenderVideo from "../ReuseableComponents/RenderVideo";

export default function Productdatails() {
  const [loading, setLoading] = useContext(loadingContext);
  const videoRef = useRef();
  const [isMuted, setIsMuted] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [cartArr, setCartArr] = useContext(cartArrContext);
  const cartArr = useCart();
  const [productDescription, setProductDescription] = useState("");
  const [showLessDescription, setShowLessDescription] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const refreshCart = useRefreshCart();
  const [speed, setSpeed] = useContext(speedContext);
  const [themeIsLight, setThemeIsLight] = useContext(themeContext);

  const [addonArray, setAddonArray] = useState([]);
  const [selectedAddonArray, setSelectedAddonArray] = useState([]);
  const [addonModal, setAddonModal] = useState(false);

  const [
    selectedItemBeforeAddonAndVariant,
    setSelectedItemBeforeAddonAndVariant,
  ] = useState({});
  const [selectedItemBeforeAddon, setSelectedItemBeforeAddon] = useState({});

  const handleClose = () => setModalVisible(false);
  const handleShow = () => setModalVisible(true);

  const handleAddonClose = () => setAddonModal(false);
  const handleAddonShow = () => setAddonModal(true);
  const [productObj, setProductObj] = useState({});

  const handleGetProduct = async () => {
    try {
      setLoading(true);
      let { data: res } = await getProductByIds(
        `productId=${searchParams.get(
          "productId"
        )}&restaurantId=${searchParams.get(
          "restId"
        )}&tableId=${searchParams.get("tableId")}`
      );
      if (res.data) {
        // console.log(res.data, "productData");
        setProductObj(res.data);
        handleWatchProductVideo(res.data._id);
        if (res.data.itemdescription && res.data.itemdescription != "") {
          setProductDescription(res.data.itemdescription.slice(0, 80));
        }
        setLoading(false);
      }
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const getAddon = async (productId, variantId = "") => {
    try {
      let obj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        productId: productId,
        variantId: variantId,
      };

      let query = new URLSearchParams(obj).toString();
      const res = await getAddons(query);

      if (res.data?.data) {
        setAddonArray(res.data?.data);
      }

      // console.log(res.data);
    } catch (error) {
      toastError(error);
    }
  };

  const handleWatchProductVideo = async (id) => {
    try {
      let obj = {
        product_id: id,
      };
      const { data: res } = await watchProductVideo(obj);
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    handleGetProduct();
  }, []);

  const checkIfProductIsInCart = (obj) => {
    // console.log(obj, "obj");
    if (obj.itemallowvariation == "1") {
      let productIndex = cartArr.findIndex((el) =>
        obj.variation.some(
          (ele) => `${obj.itemname} (${ele.name})` == el.itemname
        )
      );
      // console.log(productIndex, "productIndex");
      if (productIndex != -1) {
        return true;
      } else {
        return false;
      }
    } else {
      let productIndex = cartArr.findIndex((el) => el.itemname == obj.itemname);
      if (productIndex != -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleAddOrRemoveProductFromCart = async (obj) => {
    try {
      if (checkIfProductIsInCart(obj)) {
        handleYoloRemoveFromCart(obj);
        // if (obj.itemallowvariation == "1") {
        //     obj.variation?.forEach(el => {
        //         handleYoloRemoveFromCart({ itemid: el.id });
        //     })
        // }
        setModalVisible(false);

        return;
      }
      // console.log("FFFFFFFFFFFFFFFFFFFFF", obj, productObj);
      // console.log(
      //   "FFFFFFFFFFFFFFFFFFFFF1",
      //   obj.isInCart && productObj?.itemallowvalidaddon != "1"
      // );
      // console.log(
      //   "FFFFFFFFFFFFFFFFFFFFF2",
      //   productObj?.itemallowvariation == "1"
      // );

      if (obj.isInCart && productObj?.itemallowvalidaddon != "1") {
        let finalObj = {
          restaurantId: searchParams.get("restId"),
          tableId: searchParams.get("tableId"),
          tableNo: searchParams.get("tableNo"),
          item: obj,
        };
        const res = await removeItemToCart(finalObj);
        refreshCart();
      } else if (productObj?.itemallowvalidaddon == "1") {
        setSelectedItemBeforeAddon(obj);
        getAddon(searchParams.get("productId"), obj.variationid);
        setSelectedAddonArray([]);
        handleAddonShow();
      } else {
        let finalObj = {
          restaurantId: searchParams.get("restId"),
          tableId: searchParams.get("tableId"),
          tableNo: searchParams.get("tableNo"),
          item: obj,
        };
        const res = await addItemToCart(finalObj);
        refreshCart();
      }
      setModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckIfVariantExists = (obj) => {
    // console.log("checking", obj);
    setSelectedItemBeforeAddonAndVariant(obj);
    if (obj.itemallowvariation == "1") {
      setModalVisible(true);
      // setSelectedVariant(obj);
    } else {
      if (checkIfProductIsInCart(obj)) {
        // handleYoloRemoveFromCart(obj);
        return;
      }

      if (obj.itemallowvalidaddon == "1") {
        getAddon(obj.itemid);
        setSelectedAddonArray([]);
        handleAddonShow();
      } else {
        handleAddOrRemoveProductFromCart({
          ...obj,
          quantity: obj.isInCart ? -1 : 1,
        });
      }
    }
  };

  const handleYoloRemoveFromCart = async (obj) => {
    try {
      let finalObj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        item: obj,
      };
      const res = await removeItemToCart(finalObj);
      refreshCart();
    } catch (err) {
      toastError(err);
    }
  };

  const isChecked = (group_id, id) => {
    return selectedAddonArray.some(
      (el) => el.group_id == group_id && el.id == id
    );
  };

  const selectAddon = async (groupObj, addonObj) => {
    try {
      let obj = {
        group_id: groupObj.addongroupid,
        group_name: groupObj.addongroup_name,
        id: addonObj.addonitemid,
        name: addonObj.addonitem_name,
        price: addonObj.addonitem_price,
        quantity: 1,
      };
      let addonIndex = selectedAddonArray.findIndex(
        (el) => el.group_id == obj.group_id && el.id == obj.id
      );
      if (addonIndex == -1) {
        // check max quantity
        let max_qty = parseInt(groupObj.max_qty);
        if (
          isNaN(max_qty) ||
          (!isNaN(max_qty) &&
            selectedAddonArray.filter((el) => el.group_id == obj.group_id)
              .length < max_qty)
        ) {
          setSelectedAddonArray((prev) => [...prev, obj]);
        } else {
          throw new Error(
            "You have already selected the max quantity for " + obj.group_name
          );
        }
      } else {
        setSelectedAddonArray((prev) =>
          prev.filter((el) => !(el.group_id == obj.group_id && el.id == obj.id))
        );
      }
    } catch (error) {
      toastError(error);
    }
  };

  const addCartWithAddon = async () => {
    try {
      // checking min qty
      let minErrorArr = [];
      for (let obj of addonArray) {
        let min_qty = parseInt(obj.min_qty);
        if (
          !isNaN(min_qty) &&
          min_qty > 0 &&
          selectedAddonArray.filter((el) => obj.addongroupid == el.group_id)
            .length < min_qty
        ) {
          minErrorArr.push({ min_qty, name: obj.addongroup_name });
        }
      }

      if (minErrorArr.length) {
        let errStr = minErrorArr.reduce(
          (acc, el) => acc + el.min_qty + " option(s) in " + el.name + "; ",
          "Please select aleast "
        );
        throw new Error(errStr);
      } else {
        // call api here

        let cartObj = {
          ...selectedItemBeforeAddonAndVariant,
          ...selectedItemBeforeAddon,
          addonArr: selectedAddonArray,
        };

        let finalObj = {
          restaurantId: searchParams.get("restId"),
          tableId: searchParams.get("tableId"),
          tableNo: searchParams.get("tableNo"),
          item: cartObj,
        };

        const res = await addItemToCart(finalObj);
        refreshCart();
        handleAddonClose();
      }
    } catch (error) {
      toastError(error);
    }
  };

  const [serviceTabs, setServiceTabs] = useState([
    {
      name: "Ingredients",
      active: true,
      tab: 1,
    },
    {
      name: "Allergens",
      active: false,
      tab: 2,
    },
  ]);

  const activeServiceTabs = (i) => {
    const temp = serviceTabs.map((item, index) => {
      i === index ? (item.active = true) : (item.active = false);
      return item;
    });
    setServiceTabs([...temp]);
  };

  const handleRemoveCartQuantity = async (obj) => {
    try {
      setLoading(true);

      let tempCartArr = cartArr;
      let tempProductIndex = tempCartArr.findIndex(
        (el) => el.itemid == obj.itemid
      );

      if (tempProductIndex != -1) {
        if (tempCartArr[tempProductIndex].quantity - 1 <= 0) {
          let finalObj = {
            restaurantId: searchParams.get("restId"),
            tableId: searchParams.get("tableId"),
            tableNo: searchParams.get("tableNo"),
            item: obj,
          };
          const res = await removeItemToCart(finalObj);
          refreshCart();
          setLoading(false);
          // tempCartArr = tempCartArr.filter((el) => el.itemid != obj.itemid);
        } else {
          obj.quantity = obj.quantity - 1;

          let finalObj = {
            restaurantId: searchParams.get("restId"),
            tableId: searchParams.get("tableId"),
            tableNo: searchParams.get("tableNo"),
            item: obj,
          };
          // const res = await removeItemToCart(finalObj)
          const res = await updateItemToCart(finalObj);
          refreshCart();
          setLoading(false);
          // tempCartArr[tempProductIndex].quantity -= 1;
        }
      }
      setLoading(false);

      // setCartArr([...tempCartArr]);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleAddCartQuantity = async (obj) => {
    try {
      setLoading(true);
      // console.log("addcart", obj)
      let finalObj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        item: obj,
      };
      const res = await addItemToCart(finalObj);
      refreshCart();
      // let tempCartArr = cartArr.map((el) => {
      //   if (el.itemid == obj.itemid) {
      //     el.quantity += 1;
      //   }
      //   return el;
      // });
      // setCartArr([...tempCartArr]);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const checkIsVariantInCartForVariantAddAndDelete = (variantObj) => {
    if (cartArr.some((el) => `${el?.itemname}` == variantObj.itemname)) {
      // return true
      // console.log(cartArr, "asd", cartArr.find(el => `${el?.itemname}` == variantObj.itemname))
      return cartArr.find((el) => `${el?.itemname}` == variantObj.itemname);
    } else {
      return false;
    }
  };

  const handleSearch = () => { };
  return (
    <div
      style={{ backgroundColor: "transparent" }}
    >
      {/* {
        loading &&
        <LoadingPage />
      } */}
      <Header
        showBack
        showCartIconOnTop
        searchEnabled
        showVideo
        showVideoToggle
        text="Open Baos"
        backUrl={`/${searchParams.get("previousRoute")}`}
        setSearchQuery={(value) => handleSearch(value)}
      />
      <RenderVideo />
      <div className="sticky-top">
        <div className="porduct_section">
          <button
            style={{
              zIndex: 1000000,
              position: "absolute",
              top: 10,
              right: 10,
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setIsMuted(!isMuted)}
          >
            {isMuted ? (
              <BsFillMicMuteFill
                style={{ color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white" }}
              />
            ) : (
              <BsMicFill
                style={{ color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white" }}
              />
            )}
          </button>

          {productObj?.video &&
            productObj?.video != "" &&
            speed &&
            speed > 2 ? (
            <video
              ref={videoRef}
              src={generateFilePath(productObj?.video)}
              autoPlay
              controls={false}
              muted={isMuted}
              playsInline
              loop
            ></video>
          ) : (
            <img
              src={generateFilePath(productObj?.item_image_url)}
              alt=""
              className="img-fluid"
              style={{ objectFit: "cover" }}
            />
          )}

          {/* linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.4) 100%) */}
          <div
            className="overlay"
            style={{
              background: themeIsLight
                ? "transparent"
                : "linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 1) 100%)",
            }}
          ></div>
          <div
            className="add-to-bag-pd"
            onClick={() =>
              handleCheckIfVariantExists({
                ...productObj,
                isInCart: checkIfProductIsInCart({ ...productObj }),
              })
            }
            style={{ color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white" }}
          >
            {checkIfProductIsInCart({
              ...productObj,
            }) && productObj?.itemallowvariation == "1" ? (
              <>
                ALTER <span>+/-</span>
              </>
            ) : checkIfProductIsInCart({ ...productObj }) &&
              productObj?.itemallowvariation != "1" ? (
              <>
                <div
                  className="space-between"
                  style={{
                    borderColor: themeIsLight ? "rgba(0, 0, 0, 1)" : "white",
                  }}
                >
                  <span
                    onClick={() => {
                      checkIsVariantInCartForVariantAddAndDelete({
                        ...productObj,
                      }).quantity > 0 &&
                        handleRemoveCartQuantity({
                          ...productObj,
                          AddonItem: checkIsVariantInCartForVariantAddAndDelete(
                            { ...productObj }
                          ).AddonItem,
                          addonArr: checkIsVariantInCartForVariantAddAndDelete({
                            ...productObj,
                          }).addonArr,
                          quantity: checkIsVariantInCartForVariantAddAndDelete({
                            ...productObj,
                          })
                            ? checkIsVariantInCartForVariantAddAndDelete({
                              ...productObj,
                            }).quantity
                            : 0,
                        });
                    }}
                    className="borderraduis"
                    style={{
                      color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white",
                    }}
                  >
                    {" "}
                    <AiOutlineMinus
                      style={{
                        color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white",
                      }}
                    />
                  </span>
                  <span
                    className="mx-2"
                    style={{
                      color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white",
                    }}
                  >
                    {checkIsVariantInCartForVariantAddAndDelete({
                      ...productObj,
                    })
                      ? checkIsVariantInCartForVariantAddAndDelete({
                        ...productObj,
                      }).quantity
                      : 0}
                  </span>
                  <span
                    onClick={() => {
                      handleAddCartQuantity({
                        ...productObj,
                        AddonItem: checkIsVariantInCartForVariantAddAndDelete({
                          ...productObj,
                        }).AddonItem,
                        addonArr: checkIsVariantInCartForVariantAddAndDelete({
                          ...productObj,
                        }).addonArr,
                        quantity: checkIsVariantInCartForVariantAddAndDelete({
                          ...productObj,
                        })
                          ? checkIsVariantInCartForVariantAddAndDelete({
                            ...productObj,
                          }).quantity + 1
                          : 1,
                      });
                    }}
                    className="borderraduis"
                    style={{
                      color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white",
                    }}
                  >
                    {" "}
                    <AiOutlinePlus />
                  </span>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{ border: "solid 1px transparent" }}
                  className="space-between"
                >
                  ADD <span className="plus-icn ms-2">+</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div
      // style={{ backgroundColor: themeIsLight ? "white" : "rgba(0, 0, 0, 0.7)" }}
      >
        <div style={{ marginTop: themeIsLight ? -5 : 0, borderTopColor: themeIsLight ? "rgba(0, 0, 0, 0.2)" : "rgba(255,255,255,0.2)" }} className="product_deaisl_content">
          <div className="container">
            <div className="row ">
              <div className="col-lg-12">
                <div className="datails_area">
                  <div className="d-f-fd-r">
                    <div className="col-8">
                      <div>
                        <h4 style={{ color: themeIsLight ? "black" : "white" }}>
                          {!productObj?.isBeverage && (
                            <span
                              style={{
                                backgroundColor:
                                  productObj?.item_attributeid == "1"
                                    ? "green"
                                    : "red",
                              }}
                            ></span>
                          )}
                          {productObj?.itemname}{" "}
                          {productObj.itemallowvariation == "1" &&
                            `(${productObj.variation[0].name})`}
                        </h4>

                        <div
                          style={{ color: themeIsLight ? "black" : "white" }}
                          className="product-pricee"
                        >
                          INR{" "}
                          <span>
                            {" "}
                            {productObj.itemallowvariation == "1"
                              ? productObj.variation[0].price
                              : productObj.price}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pading_tpbtm">
                  <div className="col-12">
                    <div className="filter_card">
                      <h5 style={{ color: themeIsLight ? "black" : "white" }}>
                        Ingredients
                      </h5>
                      <div className={themeIsLight ? "feature_list_light" : "feature_list"}>
                        <ul style={{ color: themeIsLight ? "black" : "white" }}>
                          {productObj?.ingredientsArr &&
                            productObj?.ingredientsArr.filter(
                              (el) => el.name != ""
                            ).length > 0 ? (
                            productObj?.ingredientsArr
                              .filter((el) => el.name != "")
                              .map((el, index) => {
                                return (
                                  <li
                                    style={{
                                      color: themeIsLight ? "black" : "white",
                                    }}
                                    key={index}
                                  >
                                    {el.name}
                                  </li>
                                );
                              })
                          ) : (
                            <li
                              style={{
                                '::before': {
                                  backgroundColor: themeIsLight ? "black" : "white"
                                },
                                color: themeIsLight ? "black" : "white",
                              }}
                            >
                              No ingredients available
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="border-bottom1" style={{ borderBottomColor: themeIsLight ? `rgba(0,0,0,1)` : `rgba(255,255,255,0.5)` }}></div>
                </div>
              </div>

              <div className="col-lg-12">
                {productObj?.allergensArr &&
                  productObj?.allergensArr.length > 0 && (
                    <div className="row pading_tpbtm pt-3">
                      <div className="col-12">
                        <div className="filter_card">
                          <h5
                            style={{ color: themeIsLight ? "black" : "white" }}
                          >
                            Allergens
                          </h5>
                          <div className={themeIsLight ? "feature_list_light" : "feature_list"}>
                            <ul
                              style={{
                                color: themeIsLight ? "black" : "white",
                              }}
                            >
                              {productObj?.allergensArr &&
                                productObj?.allergensArr.length > 0 ? (
                                productObj?.allergensArr.map((el, index) => {
                                  return (
                                    <li
                                      style={{
                                        color: themeIsLight ? "black" : "white",
                                      }}
                                      key={index}
                                    >
                                      {el.name}
                                    </li>
                                  );
                                })
                              ) : (
                                <li
                                  style={{
                                    color: themeIsLight ? "black" : "white",
                                  }}
                                >
                                  No information available
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="col-12">
                  <div className="border-bottom1"></div>
                  <ul className="description-list">
                    <li style={{ color: themeIsLight ? "black" : "white" }}>
                      {" "}
                      {productObj?.pronunciation &&
                        productObj?.pronunciation != "" && (
                          <p
                            style={{ color: themeIsLight ? "black" : "white" }}
                          >
                            {" "}
                            <span>Pronunciation</span> :{" "}
                            {productObj?.pronunciation}
                          </p>
                        )}
                    </li>
                    {productDescription && productDescription != "" && (
                      <li style={{ color: themeIsLight ? "black" : "white" }}>
                        <p style={{ color: themeIsLight ? "black" : "white" }}>
                          {productDescription}
                          {showLessDescription && "..."}{" "}
                          <span
                            style={{ color: themeIsLight ? "black" : "white" }}
                            onClick={() => {
                              setShowLessDescription(!showLessDescription);
                              setProductDescription(
                                !showLessDescription == true
                                  ? productObj?.itemdescription.slice(0, 80)
                                  : productObj?.itemdescription
                              );
                            }}
                          >
                            Show {showLessDescription ? "More" : "Less"}
                          </span>
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>

              <div className="price_info">
                <div className="row">
                  <div className="cart">
                    <div className="row">
                      <div className="col-12"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={modalVisible}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        backdropClassName=""
        className={themeIsLight ? "modelvaraitionLight" : "modelvaraition"}
      // style={{ backgroundColor: themeIsLight ? "white" : "black" }}
      >
        <Modal.Header
          closeButton
          closeVariant={themeIsLight ? "black" : "white"}
        >
          <Modal.Title>
            {" "}
            <h4 style={{ color: themeIsLight ? "black" : "white" }}>
              Select Variations
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "scroll" }}>
          {productObj &&
            productObj.variation &&
            productObj.variation.length > 0 &&
            productObj.variation.map((el) => {
              return (
                <div className={`container-fluid`}>
                  <div className={`row d-flex align-items-center py-2`}>
                    <div className="col-8 product-name-variations ">
                      <div
                        style={{ color: themeIsLight ? "black" : "white" }}
                        onClick={() =>
                          handleAddOrRemoveProductFromCart({
                            ...el,
                            itemname: `${productObj?.itemname} (${el?.name})`,
                            itemid: el.id,
                            price: el.price,
                          })
                        }
                      >
                        {productObj?.itemname} ({el?.name})
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="space-between">
                        <span
                          onClick={() => {
                            checkIsVariantInCartForVariantAddAndDelete({
                              ...el,
                              itemname: `${productObj?.itemname} (${el?.name})`,
                            }).quantity > 0 &&
                              handleRemoveCartQuantity({
                                ...el,
                                itemname: `${productObj?.itemname} (${el?.name})`,
                                itemid: el.id,
                                price: el.price,
                                quantity:
                                  checkIsVariantInCartForVariantAddAndDelete({
                                    ...el,
                                    itemname: `${productObj?.itemname} (${el?.name})`,
                                  })
                                    ? checkIsVariantInCartForVariantAddAndDelete(
                                      {
                                        ...el,
                                        itemname: `${productObj?.itemname} (${el?.name})`,
                                      }
                                    ).quantity
                                    : 0,
                              });
                          }}
                          className="borderraduis"
                        >
                          {" "}
                          <AiOutlineMinus
                            style={{ color: themeIsLight ? "black" : "white" }}
                          />
                        </span>
                        <span
                          className="mx-2"
                          style={{ color: themeIsLight ? "black" : "white" }}
                        >
                          {checkIsVariantInCartForVariantAddAndDelete({
                            ...el,
                            itemname: `${productObj?.itemname} (${el?.name})`,
                          })
                            ? checkIsVariantInCartForVariantAddAndDelete({
                              ...el,
                              itemname: `${productObj?.itemname} (${el?.name})`,
                            }).quantity
                            : 0}
                        </span>
                        <span
                          onClick={() => {
                            handleAddCartQuantity({
                              ...el,
                              itemname: `${productObj?.itemname} (${el?.name})`,
                              itemid: el.id,
                              price: el.price,
                              quantity:
                                checkIsVariantInCartForVariantAddAndDelete({
                                  ...el,
                                  itemname: `${productObj?.itemname} (${el?.name})`,
                                })
                                  ? checkIsVariantInCartForVariantAddAndDelete({
                                    ...el,
                                    itemname: `${productObj?.itemname} (${el?.name})`,
                                  }).quantity + 1
                                  : 1,
                            });
                          }}
                          className="borderraduis"
                        >
                          {" "}
                          <AiOutlinePlus
                            style={{ color: themeIsLight ? "black" : "white" }}
                          />
                        </span>
                      </div>
                      <div className="price-section mt-3">
                        <h4 style={{ color: themeIsLight ? "black" : "white" }}>
                          {" "}
                          INR{" "}
                          {productObj.itemallowvariation == "1"
                            ? el.price
                            : productObj.price}{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Modal.Body>
        <Modal.Footer>
          <button
            style={{
              color: "black",
              backgroundColor: "white",
              borderColor: themeIsLight ? "black" : "transparent",
            }}
            className="btn btnclose"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addonModal}
        onHide={handleAddonClose}
        backdrop="static"
        keyboard={false}
        className={`${themeIsLight ? "modelvaraitionLight" : "modelvaraition"
          }  prod-selectaddons`}
      >
        <Modal.Header
          closeButton
          closeVariant={themeIsLight ? "black" : "white"}
        >
          <Modal.Title>
            {" "}
            <h4 style={{ color: themeIsLight ? "black" : "white" }}>
              Select Addons
            </h4>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ maxHeight: "60vh", minHeight: "60vh", overflowY: "auto" }}
        >
          {addonArray && addonArray.length > 0 ? (
            addonArray.map((el) => (
              <div className="container-fluid mb-2">
                <div className="row">
                  <div className="col-12">
                    <h5
                      className="selectadd-deserts"
                      style={{ color: themeIsLight ? "black" : "white" }}
                    >
                      {el.addongroup_name} (
                      {
                        selectedAddonArray.filter(
                          (ele) => ele.group_id == el.addongroupid
                        ).length
                      }
                      /{el.max_qty}) :
                    </h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 ml-2">
                    {el.addongroupitems.map((elx) => (
                      <div
                        className="row py-1"
                        onClick={() => selectAddon(el, elx)}
                      >
                        <div className="col-8">
                          <div
                            className="addon-item-name"
                            style={{ color: themeIsLight ? "black" : "white" }}
                          >
                            {elx.addonitem_name}
                          </div>
                          {elx.addonitem_price != 0 && (
                            <div
                              className="select-addons-inr"
                              style={{
                                color: themeIsLight ? "black" : "white",
                              }}
                            >
                              INR {elx.addonitem_price}
                            </div>
                          )}
                        </div>
                        <div className="col-4">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="mx-2">
                              <Checkbox
                                checked={isChecked(
                                  el.addongroupid,
                                  elx.addonitemid
                                )}
                                id={"addon" + elx._id + el._id}
                                name="addon"
                                sx={{
                                  [`&, &.${checkboxClasses.checked}`]: {
                                    color: themeIsLight ? "black" : "white",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="container-fluid mb-2">
              <div className="row">
                <div className="col-12">
                  <h5>Loading Please Wait...</h5>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btnclose"
            style={{
              color: themeIsLight ? "white" : "black",
              backgroundColor: themeIsLight ? "black" : "white",
            }}
            onClick={addCartWithAddon}
          >
            Add
          </button>
          <button
            className="btn btnclose"
            style={{
              color: "black",
              backgroundColor: "white",
              borderColor: themeIsLight ? "black" : "transparent",
            }}
            onClick={handleAddonClose}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
