import React, { useContext, useEffect, useState } from 'react'
import Header from '../ReuseableComponents/Header'
import RenderVideo from '../ReuseableComponents/RenderVideo'
import bgVideo from "../../assets/back.mp4";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toastError, toastSuccess } from '../../utils/toastUtils';
import { SaveKot, getTableBill, orderCallback, payNow } from '../../services/Order.service';
import { useCart, useCartObj } from '../CustomHooks/cartHooks';

export default function BiillWithoutCheckOut() {
  // const [cartArr, setCartArr] = useContext(cartArrContext);
  const cartArr = useCart()
  const cartObj = useCartObj()
  const [searchQuery, setSearchQuery] = useState("");
  const [instruction, setInstruction] = useState("");
  const [displayCartArr, setDisplayCartArr] = useState([]);

  const [billedData, setBilledData] = useState(null)

  const [modalIsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [selectedProduct, setSelectedProduct] = useState({});

  // const handleSearch = (value) => {
  //   setSearchQuery(value);
  //   console.log(value, "value");
  //   let tempArr = cartArr;
  //   tempArr = tempArr.filter((el) =>
  //     `${el.itemname}`.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setDisplayCartArr([...tempArr]);
  // };

  useEffect(() => {
    getBillDetails()
  }, []);

  const getBillDetails = async () => {
    try {

      let finalObj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
      }
      let res = await getTableBill(finalObj)
      // console.log(res.data?.data, "DATA")
      if (res.data?.data)
        setBilledData(res.data.data)

      // if(cartObj?.billId)

    } catch (error) {
      toastError(error)
    }
  }

  // const customStyles = {
  //   content: {
  //     top: "50%",
  //     left: "50%",
  //     right: "auto",
  //     bottom: "auto",
  //     borderRadius: "10px",
  //     width: "80%",
  //     backgroundColor: "black",
  //     display: "flex",
  //     alignItems: "center",
  //     justifyContent: "center",
  //     flexDirection: "column",
  //     marginRight: "-50%",
  //     transform: "translate(-50%, -50%)",
  //   },
  // };

  // function openModal() {
  //   setIsOpen(true);
  // }

  // function closeModal() {
  //   setIsOpen(false);
  // }

  // const handleNavigateToMenu = () => {
  //   n avigate({
  //     pathname: "/SelectCategory",
  //     search: encodeURI(searchParams),
  //   });
  // };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  async function displayRazorpay(obj, orderId) {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: "rzp_test_ZQQJJGs52bq0Aa", // Enter the Key ID generated from the Dashboard
      amount: obj.amount,
      currency: obj.currency,
      name: "DashQr",
      description: "Order",
      // image: { logo },
      order_id: obj.id,
      handler: async function (response) {
        // console.log("RESPONSE", response)
        const data = {
          orderCreationId: obj.id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const serialize = function (obj) {
          var str = [];
          for (var p in obj)
            if (obj.hasOwnProperty(p)) {
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
          return str.join("&");
        };
        // console.log(data,)
        let { data: res } = await orderCallback(serialize({
          ...obj,
          ...data,
          restaurantId: searchParams.get("restId"),
          tableId: searchParams.get("tableId"),
          tableNo: searchParams.get("tableNo"),
        }), orderId);
        if (res) {
          navigate({ pathname: "/SelectCategory", search: encodeURI(searchParams) })
          alert(res.message);
        }
      },

      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }



  const handlePayment = async () => {
    try {

      let obj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        total: billedData?.order_data?.total
      }

      let res = await payNow(obj)

      displayRazorpay(res.data.data, res.data.orderId);
      // let { data: res } = await SaveKot(obj)
      // if (res.message) {
      //   toastSuccess(res.message)
      // }
      // redirect to page

      // navigate({
      //   pathname: "/GetBill",
      //   search: encodeURI(searchParams),
      // });
    }
    catch (err) {
      toastError(err)
    }
  }


  return (
    <>
      <Header showBack />
      <div className="container-fluid ">
        <RenderVideo mainVideo={bgVideo} />
        {/* <video autoplay muted loop autoPlay={true} src={bgVideo} id="myVideo" type="video/mp4"></video> */}

        <div className="row mt-3 d-flex justify-content-center">
          {billedData && billedData?.items_data?.length && billedData?.items_data.map((el, index) => {
            return (
              <div key={index} className="cart-Product">
                <div className="cart-product-name">{el.name}</div>
                <div className="bottom-part">
                  <div className="cart-price">INR {el.price} x {el.quantity}</div>
                  {/* <div className="addInstructions" onClick={() => { setSelectedProduct(el); setInstruction(el.instruction); setIsOpen(true) }}>
                                                {
                                                    el.instruction && el.instruction != "" ?
                                                        <span className='cursor-pointer'>{el.instruction}</span>
                                                        :
                                                        <>
                                                            <FaPlusCircle className='cursor-pointer' /> <span className='cursor-pointer'>Add Instructions</span>
                                                        </>
                                                }
                                            </div> */}
                  <div style={{ display: "flex", flexDirection: "row" }}>

                    <div className="cart-price">INR {el.total}</div>

                  </div>
                </div>
              </div>
            );
          })
          }

          {(!billedData) ? (
            <div className="cart-Product">
              <div className="cart-price mt-4">
                Your bill has not been generated yet, Please wait as it may take some time to be generated {" "}
                {/* <span
                  onClick={() => handleNavigateToMenu()}
                  style={{ color: "white", textDecoration: "underline" }}
                >
                  Go to menu
                </span> */}
              </div>
            </div>
          ) : (

            <div className='cart-Product'>
              <div className="d-flex justify-content-between text-white mt-2">
                <h6>
                  SubTotal
                </h6>
                <h6>
                  INR {billedData?.order_data?.core_price}
                </h6>
              </div>
              {
                !!billedData?.order_data?.discount &&
                <div className="d-flex justify-content-between text-white">
                  <h6>
                    Discount
                  </h6>
                  <h6>
                    INR {billedData?.order_data?.discount}
                  </h6>
                </div>
              }
              <div className="d-flex justify-content-between text-white">
                <h6>
                  Tax
                </h6>
                <h6>
                  INR {billedData?.order_data?.tax}
                </h6>
              </div>
              <div className="d-flex justify-content-between text-white">
                <h4>
                  Total
                </h4>
                <h4>
                  INR {billedData?.order_data?.total}
                </h4>
              </div>




            </div>
          )}


          <div className="bottom_fixed">
            <div className="leftbootm">
              <p>Total</p>
              <h4>
                INR {billedData?.order_data?.total}
              </h4>
            </div>
            <div className="centerbootm">
              <div className="openmodal">
                <div

                  onClick={() => handlePayment()}
                >
                  <span className="cursor-pointer">
                    Proceed To Pay
                  </span>
                </div>
              </div>
            </div>
            <div className="rightbootm" >
            </div>
          </div>

          {/* <div
            className="addInstructions "
            onClick={() => {
              setIsOpen(true);
            }}
          >
            {instruction && instruction != "" ? (
              <span className="cursor-pointer">{instruction}</span>
            ) : (
              <>
                <FaPlusCircle className="cursor-pointer" />{" "}
                <span className="cursor-pointer">Add Instructions</span>
              </>
            )}
          </div> */}
        </div>

      </div>
    </>
  );
}
