import React, { useContext, useState } from "react";
import bgVideo from "../../assets/back.mp4";
import RenderVideo from "../ReuseableComponents/RenderVideo";
import Header from "../ReuseableComponents/Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { images } from "../../utils/images";
import { restaurantContext, themeContext } from "../../App";
import { generateFilePath } from "../../utils/utils";
import TextBottomBar from "./TextBottomBar";
import { Modal } from "react-bootstrap";

export default function GetBillPage() {
  const [restaurantObj, setRestaurantObj] = useContext(restaurantContext);
  const [themeIsLight, setThemeIsLight] = useContext(themeContext);

  const [confirmBillModal, setConfirmBillModal] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleOrderMore = () => {
    navigate({
      pathname: "/SelectCategory",
      search: encodeURI(searchParams),
    });
  };

  const handleViewBill = () => {
    navigate({
      pathname: "/Bill",
      search: encodeURI(searchParams),
    });
  };

  return (
    <>
      <Header showBack showBillIconOnTop />
      <div className="container-fluid">
        <RenderVideo mainVideo={bgVideo} />
        <div className="row">
          <div className="col-lg-12 col-12 get-bill-main-box">
            <div className="row">
              <div className="col-6 col-lg-6 product-cardbill">
                <div className="" onClick={() => setConfirmBillModal(true)}>
                  <div
                    className="product-cardbill-white-item"
                    style={{ borderColor: themeIsLight ? "black" : "white" }}
                  >
                    <img
                      src={
                        restaurantObj?.viewBillIcon &&
                          restaurantObj?.viewBillIcon != ""
                          ? generateFilePath(restaurantObj?.viewBillIcon)
                          : images.beverage
                      }
                      alt=""
                      srcset=""
                      className={
                        themeIsLight ? "imageToBlack" : "" + "img-fluid"
                      }
                    />
                  </div>
                </div>
                <h5
                  className="product-cardbill-white-item-text"
                  style={{ color: themeIsLight ? "black" : "white" }}
                >
                  View Bill
                </h5>
              </div>

              <div
                className="col-6 col-lg-6 product-cardbill"
                onClick={() => handleOrderMore()}
              >
                <div
                  className="product-cardbill-white-item"
                  style={{ borderColor: themeIsLight ? "black" : "white" }}
                >
                  <img
                    src={
                      restaurantObj?.orderMoreIcon &&
                        restaurantObj?.orderMoreIcon != ""
                        ? generateFilePath(restaurantObj?.orderMoreIcon)
                        : images.food
                    }
                    alt=""
                    srcset=""
                    className={themeIsLight ? "imageToBlack" : "" + "img-fluid"}
                  />
                </div>

                <h5
                  className="product-cardbill-white-item-text"
                  style={{ color: themeIsLight ? "black" : "white" }}
                >
                  Order More
                </h5>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={confirmBillModal}
          onHide={() => setConfirmBillModal(true)}
          backdrop="static"
          keyboard={false}
          centered
          className={`${themeIsLight ? "modelvaraitionLight" : 'modelvaraition'} prod - selectaddons`}

        // className='modelvaraition prod-selectaddons'
        >
          <Modal.Header closeButton closeVariant={themeIsLight ? "black" : "white"} >
            <Modal.Title> <h4 style={{ color: themeIsLight ? "black" : "white" }}>Are you sure you want to continue ?</h4> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 style={{ color: themeIsLight ? "black" : "white" }}>This will send a request to the restaurant to generete the final bill and you will not be able to add more items.</h6>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btnclose' style={{ width: "50%", color: themeIsLight ? "white" : "black", backgroundColor: themeIsLight ? "black" : "white" }} onClick={() => handleViewBill()}>
              Continue to bill
            </button>
            <button className='btn btnclose' style={{ color: "black", backgroundColor: "white", borderColor: themeIsLight ? "black" : "transparent" }} onClick={() => setConfirmBillModal(false)}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
