import debounce from 'lodash.debounce';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loadingContext, themeContext } from '../../App';
import bgVideo from "../../assets/back.mp4";
import { searchProductsByName } from '../../services/product.service';
import Header from '../ReuseableComponents/Header';
import RenderVideo from '../ReuseableComponents/RenderVideo';
import LoadingPage from './LoadingPage';

export default function SearchProducts() {
    const [productsArr, setProductsArr] = useState([]);
    const [query, setQuery] = useState("");
    const [searchParams] = useSearchParams();
    const [mainProductsArr, setMainProductsArr] = useState([]);
    const navigate = useNavigate();
    const [isSearched, setIsSearched] = useState(false);
    const [loading, setLoading] = useContext(loadingContext);
    const [themeIsLight, setThemeIsLight] = useContext(themeContext);

    const handleGetProducts = async (queryValue) => {
        try {
            if (queryValue == "") {
                // setLoading(false)
                setProductsArr([])
                return
            }
            setIsSearched(true)
            let { data: res } = await searchProductsByName(`name=${queryValue}&restaurantId=${searchParams.get("restId")}&tableId=${searchParams.get("tableId")}`)
            if (res.data) {
                // console.log(res.data, "res.data")
                // setLoading(false)
                setProductsArr(res.data)
                setMainProductsArr(res.data);
            }
        }
        catch (err) {
            // setLoading(false)
            alert(err)
        }
    }






    const debouncedSave = useCallback(

        debounce((nextValue) => handleGetProducts(nextValue), 1000),

        [query] // will be created only once initially

    )

    // highlight-ends



    const handleChange = (event) => {

        const nextValue = event

        setQuery(nextValue)

        // Even though handleChange is created on each render and executed

        // it references the same debouncedSave that was created initially

        debouncedSave(nextValue)

    }

    const handleNavigate = (obj) => {
        searchParams.set("productId", obj.itemid)
        navigate({
            pathname: "/Productdatails",
            search: encodeURI(searchParams),
        })
    }




    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [])


    return (
        <>
            {
                loading && <LoadingPage />
            }

            <Header
                showBack
                showHeadingInPlaceOfText
                showCartIconOnTop
                showtext
            />

            <RenderVideo mainVideo={bgVideo} />
            <div style={{ display: "grid", placeItems: "center" }}>
                <input style={{ borderColor: themeIsLight ? "black" : "rgb(194, 194, 194)", color: themeIsLight ? "black" : "white" }} type="text" className={`${themeIsLight ? `searchInputLightMode` : `searchInputDarkMode`} ${themeIsLight ? "placeholderToBlack" : ""}`} onChange={(e) => handleChange(e.target.value)} placeholder='Please enter something to search !!!' />
            </div>

            {
                productsArr && productsArr.length > 0 ? productsArr.map((el, index) => {
                    return (
                        <div onClick={() => handleNavigate(el)} key={index} className='small-info' style={{ borderColor: themeIsLight ? "black" : "rgb(194, 194, 194)" }}>
                            {
                                el.isBeverage === false &&
                                <p style={{ minHeight: 10, minWidth: 10, height: 10, width: 10, borderRadius: 10, marginTop: 15, backgroundColor: el.item_attributeid == "1" ? "green" : "red" }}></p>
                            }
                            <span style={{ marginLeft: 10, color: themeIsLight ? "black" : "white" }}>
                                {el.itemname}
                            </span>
                        </div>
                    )
                })
                    :
                    <div className='no-data-found' style={{ borderBottomColor: themeIsLight ? "black" : "rgb(194, 194, 194)", color: themeIsLight ? "black" : "white" }}>{isSearched == true && "No data found"}</div>
            }


        </>
    )
}
