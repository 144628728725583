import './App.css';
import React, { createContext, useEffect, useState } from 'react'
import { Toaster } from "react-hot-toast";
import RootRouter from './Routes/RootRouter';
import './assets/css/Responsive.css'
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { useSearchParams } from 'react-router-dom';
export const cartArrContext = createContext()
export const restaurantContext = createContext()
export const speedContext = createContext()
export const loadingContext = createContext()
export const themeContext = createContext();
function App() {
  // const [searchParams, setSearchParams] = useSearchParams()
  const [speed, setSpeed] = useState(650);
  const [restaurantObj, setRestaurantObj] = useState({});
  const [cartArr, setCartArr] = useState([]);
  const [cartObj, setCartObj] = useState(null)
  const [cartAddonArr, setCartAddonArr] = useState([])
  const [loading, setLoading] = useState(false);
  const [themeIsLight, setThemeIsLight] = useState(false);





  return (
    <restaurantContext.Provider value={[restaurantObj, setRestaurantObj]}>
      <cartArrContext.Provider value={[cartArr, setCartArr, cartObj, setCartObj, cartAddonArr, setCartAddonArr]}>
        <speedContext.Provider value={[speed, setSpeed]}>
          <loadingContext.Provider value={[loading, setLoading]}>
            <themeContext.Provider value={[themeIsLight, setThemeIsLight]}>
              <Toaster />
              <RootRouter />
            </themeContext.Provider>
          </loadingContext.Provider>
        </speedContext.Provider>
      </cartArrContext.Provider>
    </restaurantContext.Provider>);
}

export default App;
