import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getOrderByIdApi } from '../../services/Order.service';
import { toastError } from '../../utils/toastUtils';

export default function Invoice() {

    const { id } = useParams();
    const [orderId, setorderId] = useState("");
    const [ordeObj, setOrdeObj] = useState("");
    const [orderData, setOrderData] = useState("");
    const [orderItems, setOrderItems] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            setorderId(id);
            handleleGetOrderById(id)
        } else {
            navigate('/');
        }
        // console.log(id,"params?.orderIdparams?.orderIdparams?.orderId")
    }, [id])


    const handleleGetOrderById = async (orderId) => {
        try {
            let { data: res } = await getOrderByIdApi(orderId);
            if (res.success && res.data) {
                setOrdeObj(res.data);
                if (res.data?.bill && res.data?.bill?.order_data) {
                    setOrderData(res.data?.bill?.order_data);
                }
                if (res.data?.bill && res.data?.bill?.items_data) {
                    setOrderItems(res.data?.bill?.items_data);
                }
            }

        } catch (error) {
            toastError(error)
            // console.log(error)

        }
    }


    return (
        <div>
            <div className="page-content container mt-5">
                <div className="page-header text-blue-d2">
                    <h1 className="page-title text-secondary-d1">
                        Invoice
                        <small className="page-info">
                            <i className="fa fa-angle-double-right text-80"></i>
                            ID: #{orderData?.invoice_id}
                        </small>
                    </h1>

                </div>

                <div className="container px-0">
                    <div className="row mt-4">
                        <div className="col-12 col-lg-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="text-center text-150">
                                        <i className="fa fa-book fa-2x text-success-m2 mr-1"></i>
                                        <span className="text-default-d3"> Dash - Dine In</span>
                                    </div>
                                </div>
                            </div>


                            <hr className="row brc-default-l1 mx-n1 mb-4" />

                            <div className="row">
                                <div className="col-sm-6">
                                    <div>
                                        <span className="text-sm text-grey-m2 align-middle">Biling User:</span>
                                        <span className="text-600 text-110 text-blue align-middle">{orderData?.billing_user_name}</span>
                                    </div>
                                    <div className="text-grey-m2">
                                        <div className="my-1">
                                            {orderData?.customer_name}
                                        </div>
                                        <div className="my-1">
                                            {orderData?.customer_phone}
                                        </div>
                                    </div>
                                </div>

                                <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                                    <hr className="d-sm-none" />
                                    <div className="text-grey-m2">
                                        <div className="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                                            Invoice
                                        </div>

                                        <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span className="text-600 text-90">ID:</span> #{orderData?.invoice_id}</div>

                                        <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span className="text-600 text-90">Issue Date:</span> {new Date(orderData?.created).toDateString()}</div>

                                        <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span className="text-600 text-90">Status:</span> <span className="badge badge-warning badge-pill px-25">Unpaid</span></div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4">
                                <h3>Order Items</h3>
                            </div>

                            <div className="mt-4">
                                <div className="row text-600 text-white bgc-default-tp1 py-25">
                                    <div className="d-none d-sm-block col-1">#</div>
                                    <div className="col-9 col-sm-5">Item Name</div>
                                    <div className="d-none d-sm-block col-4 col-sm-2">Qty</div>
                                    <div className="d-none d-sm-block col-sm-2">Unit Price</div>
                                    <div className="col-2">Amount</div>
                                </div>

                                <div className="text-95 text-secondary-d3">

                                    {
                                        orderItems && orderItems?.length > 0 && orderItems?.map((item, index) => (
                                            <>
                                                {
                                                    item?.name != 'VAT' && (
                                                        <div className="row mb-2 mb-sm-0 py-25">
                                                            <div className="d-none d-sm-block col-1">{index + 1}</div>
                                                            <div className="col-9 col-sm-5">{item.name}</div>
                                                            <div className="d-none d-sm-block col-2">{item.quantity}</div>
                                                            <div className="d-none d-sm-block col-2 text-95">{item.price}</div>
                                                            <div className="col-2 text-secondary-d2">{item.total}</div>
                                                        </div>
                                                    )
                                                }

                                            </>


                                        )
                                        )
                                    }



                                </div>

                                <div className="row border-b-2 brc-default-l2"></div>



                                <div className="row mt-3">
                                    <div className="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                                    </div>

                                    <div className="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                                        <div className="row my-2">
                                            <div className="col-7 text-right">
                                                Tax (18%)
                                            </div>
                                            <div className="col-5">
                                                <span className="text-110 text-secondary-d1">{orderData?.tax}</span>
                                            </div>
                                        </div>
                                        <div className="row my-2">
                                            <div className="col-7 text-right">
                                                Round Off
                                            </div>
                                            <div className="col-5">
                                                <span className="text-110 text-secondary-d1">{orderData?.roundoff}</span>
                                            </div>
                                        </div>

                                        <div className="row my-2 align-items-center bgc-primary-l3 p-2">
                                            <div className="col-7 text-right">
                                                Total Amount
                                            </div>
                                            <div className="col-5">
                                                <span className="text-150 text-success-d3 opacity-2">{orderData?.total}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
