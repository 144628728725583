import React from 'react'
import logo from "../../assets/logo.png"
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { toastError } from '../../utils/toastUtils'
import { useContext } from 'react'
import { restaurantContext } from '../../App'
import { getRestaurantsById, setLocalStorageRestaurant } from '../../services/restaurant.service'
export default function InitialScreen() {
    const navigation = useNavigate()
    const [searchParams] = useSearchParams();
    const [restaurantObj, setRestaurantObj] = useContext(restaurantContext);
    const handleGetRestaurant = async () => {
        try {
            let { data: res } = await getRestaurantsById(searchParams.get("restId"))
            if (res.data) {
                setRestaurantObj(res.data);
            }
        }
        catch (err) {
            toastError(err)
        }
    }
    useEffect(() => {
        if (searchParams.get("restId")) {
            handleGetRestaurant()
        }
        else {
            toastError("Invalid Qr scanned !!!")
        }
    }, [searchParams.get("restId")])

    useEffect(() => {
        // console.log(restaurantObj, "restaurantObj")
        if (restaurantObj && restaurantObj.restaurantname) {
            setLocalStorageRestaurant(JSON.stringify(restaurantObj))
            navigation({ pathname: "/Home", search: encodeURI(searchParams) })
        }
    }, [restaurantObj])

    return (
        <div className='initial-screen-contianer'>
            <img src={logo} className='main-logo' alt="" />
        </div>
    )
}
