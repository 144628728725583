import React, { useContext, useEffect, useState } from 'react'
import RenderVideo from '../ReuseableComponents/RenderVideo'
import Header from '../ReuseableComponents/Header'
import bgVideo from "../../assets/back.mp4";
import { toastError } from '../../utils/toastUtils';
import { getTableRecomendation } from '../../services/DashRecomendation.service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { generateFilePath } from '../../utils/utils';
import { themeContext } from '../../App';
import { addItemToCart } from '../../services/tableCart.service';
import { useRefreshCart } from '../CustomHooks/cartHooks';
import { getRecomendationCategory } from '../../services/recomendationcategory.service';

export default function RecomendationCategory() {
    const refreshCart = useRefreshCart()
    const navigate = useNavigate();

    const [themeIsLight, setThemeIsLight] = useContext(themeContext);

    const [searchParams] = useSearchParams();

    const [recomendationArr, setRecomendationArr] = useState([]);
    const [recomendationCategoryArr, setRecomendationCategoryArr] = useState([]);



    const getRecomendationCategories = async () => {
        try {
            const { data: res } = await getRecomendationCategory();
            if (res) {
                setRecomendationCategoryArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const HandleGetTableRecomendation = async () => {
        try {
            let { data: res } = await getTableRecomendation(`tableId=${searchParams.get("tableId")}&restaurantId=${searchParams.get("restId")}`)
            if (res.data) {
                // console.log(res.data, "recomendation")
                setRecomendationArr(res.data)
            }
        }
        catch (err) {
            toastError(err)
        }
    }


    const handleAddToCart = async (obj) => {

        // console.log(obj.petPujaRestaurantId)
        for (const el of obj.productsArr) {

            let finalObj = {
                restaurantId: obj.petPujaRestaurantId,
                tableId: obj.petPujaTableId,
                tableNo: searchParams.get("tableNo"),
                item: el
            }
            // console.log(finalObj, "finalObj")
            const res = await addItemToCart(finalObj)

            if (res.status) {

            }

        }
        refreshCart()
    }


    const handleDashRecomendation = (id) => {
        searchParams.set("categoryType", "beverage");
        searchParams.set("previousRoute", "SelectCategory");
        searchParams.set("recomendationcategory", id)
        searchParams.delete("maincategoryId");
        searchParams.delete("categoryId");
        navigate({
            pathname: "/DashExplore",
            search: encodeURI(searchParams),
        });
    };


    useEffect(() => {
        getRecomendationCategories()
        // HandleGetTableRecomendation()
    }, [])

    return (
        <>
            <Header
                showBack
                backUrl={"/SelectCategory"}
                showBill showCartIconOnTop />
            <div className='container-fluid '>

                <RenderVideo mainVideo={bgVideo} />
                <div className="row d-flex justify-content-around mt-5">

                    {
                        recomendationCategoryArr && recomendationCategoryArr.length > 0 && recomendationCategoryArr.map((el, index) => {
                            return (
                                <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-6 my-2" onClick={() => handleDashRecomendation(el?._id)}>
                                    <div className="menu-btn">
                                        <div className={`menu-btn-img ${themeIsLight ? "brdr-color-black" : "brder-color-white"}`}

                                        // "menu-btn-img" style={{ borderColor: themeIsLight ? "rgba(0, 0, 0,0.4)" : "rgb(194, 194, 194)" }}
                                        >
                                            <img src={generateFilePath(el.image)} alt="" className={themeIsLight ? "imageToBlack" : "" + 'img-fluid'} />
                                        </div>
                                    </div>
                                    <div className="category-name" style={{ color: themeIsLight ? "black" : "white" }}>{el.name}</div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
{/* <div></div> */ }
                        // <div key={index} className='recomendation-product-card' onClick={() => handleDashRecomendation(el?._id)}>
                        //     <img src={generateFilePath(el.image)} alt="" srcset="" />



                        //     {/* {
                        //         el.productsArr.map((ele, index) => {
                        //             return (
                        //                 <div key={index} style={{ color: themeIsLight ? "black" : "white", fontSize: 12, marginTop: 10 }} className={`${themeIsLight ? "black" : "white"} product-name`}>
                        //                     {ele.itemname}
                        //                     {ele.addonArr ? (`${ele.addonArr ? ele.addonArr.reduce((acc, elex, index) => acc + `${elex.name}${index != (ele?.addonArr?.length - 1) ? ", " : ""}`, "") : ""}`) : ""}
                        //                 </div>
                        //             )
                        //         })
                        //     } */}
                        //     <div key={index} style={{ color: themeIsLight ? "black" : "white", fontSize: 12, marginTop: 10 }} className={`${themeIsLight ? "black" : "white"} product-name`}>
                        //         {el.name}
                        //     </div>

                        //     <div className="space-between" style={{ border: "none" }}>
                        //         {/* <div className="add-to-cart" style={{ color: themeIsLight ? "black" : "white" }}>
                        //             <div onClick={() => handleAddToCart(el)} style={{ border: `solid 1px ${themeIsLight ? "black" : "white"}`, width: "max-content", padding: "7px 15px", borderRadius: 10, marginTop: 5, marginLeft: -10 }} >
                        //                 Add
                        //             </div>
                        //         </div> */}
                        //         {/* <div style={{ color: themeIsLight ? "black" : "white" }}>INR {el.price}</div> */}
                        //     </div>
                        // </div>