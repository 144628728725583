import React, { useContext, useEffect, useState } from "react";
import { restaurantContext, themeContext } from "../../App";

export default function TextBottomBar() {
  const [restaurantObj, setRestaurantObj] = useContext(restaurantContext);
  const [toggleText, setToggleText] = useState(true);

  const [themeIsLight, setThemeIsLight] = useContext(themeContext);

  useEffect(() => {
    setToggleText(false);
  }, [restaurantObj]);

  return (
    <div
      className="bottom_text"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: themeIsLight ? "white" : "black",
      }}
    >
      <p
        className={`${
          themeIsLight ? "color-black" : "color-white"
        } bottom-bar-text`}
      >
        {/* {toggleText
          ? restaurantObj?.disclaimer &&
            restaurantObj?.disclaimer != "" &&
            restaurantObj?.disclaimer.slice(0, 60)
          : restaurantObj?.disclaimer}
        {toggleText && "..."}{" "}
        {restaurantObj?.disclaimer &&
          restaurantObj?.disclaimer?.length > 60 && (
            <span
              onClick={() => setToggleText(!toggleText)}
              style={{
                fontWeight: 600,
                color: themeIsLight ? "black" : "white",
              }}
            >
              Show {`${toggleText ? "More" : "Less"}`}
            </span>
          )} */}
        Prices in INR
      </p>
    </div>
  );
}
