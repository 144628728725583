import React, { useContext, useEffect, useState } from 'react'
import Header from '../ReuseableComponents/Header'
import RenderVideo from '../ReuseableComponents/RenderVideo'
import bgVideo from "../../assets/back.mp4";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toastError, toastSuccess } from '../../utils/toastUtils';
import { SaveKot, orderCallback, payNow } from '../../services/Order.service';
import { BsCurrencyRupee } from 'react-icons/bs';
const Billdetails = () => {
  return (
    <div>
      <Header showBack />
      <RenderVideo mainVideo={bgVideo} />
      <div className="container-fluid ">
        <div className="row">
          <div className="col-12">
            <div className="menu_datils">
              <table className='table'>
                <tr>
                  <th>Menu</th>
                  <th className='leftsection'>Price</th>
                </tr>
                <tr>
                  <td>Chicken Half plate</td>
                  <td className='leftsection'>150 <span><BsCurrencyRupee /> </span></td>
                </tr>
                <tr>
                  <td>Chicken Half plate</td>
                  <td className='leftsection'>150 <span><BsCurrencyRupee /> </span></td>
                </tr>
                <tr>
                  <th>Total</th>
                  <th className='leftsection'>300/- <span><BsCurrencyRupee /> </span></th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Billdetails
