import axiosApiInstance from "../utils/axiosApiInstance";
import { url } from "./url.service";

const serverUrl = url + "/maincategory";

export const getMainCategories = (query) => {
  return axiosApiInstance.get(`${serverUrl}/?${query}`);
};
export const getmaincategoryById = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};
