import React, { useState } from 'react'
import bgVideo from "../../assets/back.mp4"
import logo from "../../assets/logo.png"
import { checkPincodeFromApi } from '../../services/petPujaApi.service';
import { toastError } from '../../utils/toastUtils';
import Header from '../ReuseableComponents/Header';
import { Link } from 'react-router-dom';
import RenderVideo from '../ReuseableComponents/RenderVideo';
export default function CheckPincode() {

    const [pincode, setPincode] = useState("");


    const handleCheckPinCode = async () => {
        try {
            if (pincode == "") {
                toastError("Pincode cannot be empty")
            }
            let obj = {
                pincode,
            }
            let { data: res } = await checkPincodeFromApi(obj)
            if (res.message) {

            }
        }
        catch (err) {
            toastError(err)
        }
    }


    return (
        <>
            <Header />
            <div className='container-fluid '>

                <RenderVideo mainVideo={bgVideo} />
                {/* <video autoplay muted loop autoPlay={true} src={bgVideo} id="myVideo" type="video/mp4"></video> */}
                <div className="logo-container">
                    <Link to="/"><img src={logo} className="main-logo" alt="" /></Link>
                </div>

                <div className="row gap-4 d-flex justify-content-around mt-5 check-pincode">

                    <input type="number" onChange={(e) => setPincode(e.target.value)} value={pincode} className="txt-input" placeholder='Pincode' />
                    <button className='white-button' onClick={() => handleCheckPinCode()}>CHECK</button>
                </div>


            </div>
        </>
    )
}
