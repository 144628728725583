import React, { useContext, useEffect, useRef, useState } from "react";
import { restaurantContext, speedContext, themeContext } from "../../App";
import bgVideo from "../../assets/back.mp4";
import logo from "../../assets/logo.png";
import { generateFilePath } from "../../utils/utils";

const isSafari = () => {
  const ua = navigator?.userAgent?.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

export default function RenderVideo({ mainVideo }) {
  const [restaurantObj, setRestaurantObj] = useContext(restaurantContext);
  const [speed, setSpeed] = useContext(speedContext);
  const [themeIsLight, setThemeIsLight] = useContext(themeContext);

  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);

  const [videoUrl, setVideoUrl] = useState("");

  const getVideoLink = () => {
    if (restaurantObj && restaurantObj.video && restaurantObj.video != "") {
      // console.log(generateFilePath(restaurantObj?.video), "2222222222222");
      return generateFilePath(restaurantObj?.video);
    } else {
      // console.log(restaurantObj, "restaurantObj")
      return bgVideo;
    }
  };

  useEffect(() => {
    if (restaurantObj && restaurantObj.video && restaurantObj.video != "") {
      setVideoUrl(getVideoLink());
      // console.log(getVideoLink(), "getVideoLink()");
    }
  }, [restaurantObj]);

  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    // const ua = navigator.userAgent.toLowerCase();
    // console.log("ISSAFERI", isSafari(), ua)
    // if (speed && speed > 2) {
    // console.log("dalse 22");
    setShouldUseImage(false);
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => { })
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                // videoParentRef.current.style.display = "none";
                // setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
    // }
    // else {
    //   console.log("dalse")
    //   setShouldUseImage(true)
    // }
  }, [speed]);

  useEffect(() => { }, [restaurantObj]);

  return shouldUseImage ? (
    <div id="myVideo" style={{ display: "grid", placeItems: "center" }}>
      <img src={logo} style={{ width: "50vw" }} alt="Muted Video" />
    </div>
  ) : (
    <>
      <div className="videooverlay"></div>
      {/* <div className="videooverlay" style={{ backgroundColor: themeIsLight ? "transparent" : 'rgba(0, 0, 0, 0.6)' }}></div>
      <div

        ref={videoParentRef}
        dangerouslySetInnerHTML={{
          __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
          id="myVideo"
        >
        <source src="${videoUrl}" type="video/mp4" />
        </video>`
        }}
      /> */}
    </>
  );
}
