import axiosApiInstance from "../utils/axiosApiInstance";
import { url } from "./url.service";

const serverUrl = url + "/category";

// export const addProduct = (formData) => {
//   return axios.post(serverUrl + "/addProduct", formData);
// };

export const getCategories = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getCategory?${query}`);
};

// export const deleteProductById = (id) => {
//   return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
// };

export const updateCategoryById = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};