// axios-instance.js
import axios from 'axios';
import { url } from '../services/url.service';
import { toastError } from './toastUtils';

const axiosApiInstance = axios.create();

// Request interceptor
axiosApiInstance.interceptors.request.use(
    (config) => {
        // Add headers or perform other actions before sending the request
        let searchParams = new URLSearchParams(window.location.search)
        console.log(searchParams.get('restId'), "searchParams")
        if (searchParams.get('restId')) {
            config.headers['checkrestid'] = searchParams.get('restId')
            config.headers['checktableid'] = searchParams.get('tableId')
            config.headers['checktableno'] = searchParams.get('tableNo')
        }


        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
axiosApiInstance.interceptors.response.use(
    (response) => {
        // Handle successful responses
        console.log(response, "response");
        return response;
    },
    (error) => {
        // Handle error responses
        if (error && error.response && error.response.status && error.response.status == 402) {
            // toastError('Please complete the payment first');
            window.location.href = '/bill' + window.location.search
        }
        return Promise.reject(error);
    }
);

export default axiosApiInstance;
