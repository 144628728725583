import axiosApiInstance from "../utils/axiosApiInstance";
import { url } from "./url.service";

const serverUrl = url + "/RecomendationCategory";



export const getRecomendationCategory = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getCategory?${query}`);
};


export const getRecomendationCategoryById = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getRecomendationCategoryById/${query}`);
};

