import React, { useContext, useEffect, useState } from "react";
import Header from "../ReuseableComponents/Header";
import bgVideo from "../../assets/back.mp4";
import { FaPlus, FaMinus } from "react-icons/fa";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import Modal from "react-modal";
import RenderVideo from "../ReuseableComponents/RenderVideo";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { SaveKot, registerCustomer } from "../../services/Order.service";
import {
  useCart,
  useCartAddonArr,
  useCartObj,
  useRefreshCart,
} from "../CustomHooks/cartHooks";
import {
  addItemToCart,
  getRecommendedProductsByCart,
  removeItemToCart,
  updateCartAddonById,
  updateItemToCart,
} from "../../services/tableCart.service";
import { sendOtpApi } from "../../services/users.service";
import { loadingContext, themeContext } from "../../App";
import { getAddons } from "../../services/getAddons.service";
import { Checkbox, checkboxClasses } from "@mui/material";
import { ModalHeader } from "react-bootstrap";
import { getTableRecomendation } from "../../services/DashRecomendation.service";
import _ from "lodash";
import { generateFilePath } from "../../utils/utils";
import CartProductRecomendation from "./CartProductRecomendation";

export default function Cart() {
  // const [cartArr_, setCartArr] = useContext(cartArrContext);
  const cartArr = useCart();
  const cartObj = useCartObj();
  const cartAddonArr = useCartAddonArr();
  const refreshCart = useRefreshCart();
  const [loading, setLoading] = useContext(loadingContext);
  const [themeIsLight, setThemeIsLight] = useContext(themeContext);
  const [recomendationArr, setRecomendationArr] = useState([]);

  const [selectedRecomendation, setSelectedRecomendation] = useState({});


  // const [recomendationConfirmationModal, setRecomendationConfirmationModal] = useState(false);


  const [searchQuery, setSearchQuery] = useState("");
  const [instruction, setInstruction] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [verifyotp, setVerifyotp] = useState("");
  const [displayCartArr, setDisplayCartArr] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isotpModel, setisotpModel] = useState(false);
  const [nameModalIsOpen, setNameModelIsOpen] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [selectedProduct, setSelectedProduct] = useState({});

  const [addonArray, setAddonArray] = useState([]);

  const [selectedAddonArray, setSelectedAddonArray] = useState([]);

  const [addonModal, setAddonModal] = useState(false);

  const handleAddonClose = () => {
    setAddonModal(false);
  };

  const [selectedObj, setSelectedObj] = useState(null);

  const handleAddonShow = (id, item) => {
    getAddon(id);
    setSelectedObj(item);
    setSelectedAddonArray(
      item.addonArr.map((el) => {
        let obj = { ...el };
        delete obj?._id;
        return obj;
      })
    );

    setAddonModal(true);
  };

  const handleUpdateAddon = async () => {
    try {
      let obj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        item_id: selectedObj?._id,
        addonArray: selectedAddonArray,
      };

      let res = await updateCartAddonById(obj);
      if (res.data?.message) toastSuccess(res.data?.message);

      handleAddonClose();
      refreshCart();
    } catch (error) {
      toastError(error);
    }
  };

  const isChecked = (group_id, id) => {
    return selectedAddonArray.some(
      (el) => el.group_id == group_id && el.id == id
    );
  };

  const selectAddon = async (groupObj, addonObj) => {
    try {
      let obj = {
        group_id: groupObj.addongroupid,
        group_name: groupObj.addongroup_name,
        id: addonObj.addonitemid,
        name: addonObj.addonitem_name,
        price: addonObj.addonitem_price,
        quantity: 1,
      };
      let addonIndex = selectedAddonArray.findIndex(
        (el) => el.group_id == obj.group_id && el.id == obj.id
      );
      if (addonIndex == -1) {
        // check max quantity
        let max_qty = parseInt(groupObj.max_qty);
        if (
          isNaN(max_qty) ||
          (!isNaN(max_qty) &&
            selectedAddonArray.filter((el) => el.group_id == obj.group_id)
              .length < max_qty)
        ) {
          setSelectedAddonArray((prev) => [...prev, obj]);
        } else {
          throw new Error(
            "You have already selected the max quantity for " + obj.group_name
          );
        }
      } else {
        setSelectedAddonArray((prev) =>
          prev.filter((el) => !(el.group_id == obj.group_id && el.id == obj.id))
        );
      }
    } catch (error) {
      toastError(error);
    }
  };

  const getAddon = async (productId) => {
    try {
      let obj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        productId: productId,
      };

      let query = new URLSearchParams(obj).toString();
      const res = await getAddons(query);

      if (res.data?.data) {
        setAddonArray(res.data?.data);
      }

      // console.log(res.data)
    } catch (error) {
      toastError(error);
    }
  };

  const handleAddCartQuantity = async (obj) => {
    try {
      setLoading(true);
      // console.log("addcart", obj)
      let finalObj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        item: obj,
      };
      const res = await addItemToCart(finalObj);
      refreshCart();
      // let tempCartArr = cartArr.map((el) => {
      //   if (el.itemid == obj.itemid) {
      //     el.quantity += 1;
      //   }
      //   return el;
      // });
      // setCartArr([...tempCartArr]);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleRemoveCartQuantity = async (obj) => {
    try {
      setLoading(true);

      let tempCartArr = cartArr;
      let tempProductIndex = tempCartArr.findIndex(
        (el) => el.itemid == obj.itemid
      );

      if (tempProductIndex != -1) {
        if (tempCartArr[tempProductIndex].quantity - 1 <= 0) {
          let finalObj = {
            restaurantId: searchParams.get("restId"),
            tableId: searchParams.get("tableId"),
            tableNo: searchParams.get("tableNo"),
            item: obj,
          };
          const res = await removeItemToCart(finalObj);
          refreshCart();
          // tempCartArr = tempCartArr.filter((el) => el.itemid != obj.itemid);
        } else {
          obj.quantity = obj.quantity - 1;

          let finalObj = {
            restaurantId: searchParams.get("restId"),
            tableId: searchParams.get("tableId"),
            tableNo: searchParams.get("tableNo"),
            item: obj,
          };
          // const res = await removeItemToCart(finalObj)
          const res = await updateItemToCart(finalObj);
          refreshCart();
          setLoading(false);
          // tempCartArr[tempProductIndex].quantity -= 1;
        }
      }

      // setCartArr([...tempCartArr]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
    // console.log(value, "value");
    let tempArr = cartArr;
    tempArr = tempArr.filter((el) =>
      `${el.itemname}`?.toLowerCase().includes(value?.toLowerCase())
    );
    setDisplayCartArr([...tempArr]);
  };






  useEffect(() => {
    refreshCart();
  }, []);

  useEffect(() => {
    setDisplayCartArr([...cartArr]);
    // HandleGetRecommendedProductsByCart()
  }, [cartArr]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      borderRadius: "10px",
      width: "80%",
      color: themeIsLight ? "black" : "white",
      backgroundColor: themeIsLight ? "bg-white" : "bg-grey",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const customStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      borderRadius: "10px",
      width: "100%",
      color: themeIsLight ? "black" : "white",
      backgroundColor: themeIsLight ? "white" : "black",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      transform: "translate(-50%, -50%)",
    },
  };

  function openNameModal() {
    setNameModelIsOpen(true);
  }

  function closeNameModal() {
    setNameModelIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const handleAddInstructionToProduct = async () => {
    // let tempArr = cartArr.map((el) => {
    //   let obj = {
    //     ...el,
    //   };
    //   if (el.itemid == selectedProduct.itemid) {
    //     obj.instruction = instruction;
    //   }
    //   return obj;
    // });
    let obj = { ...selectedProduct };

    obj.instruction = instruction;

    let finalObj = {
      restaurantId: searchParams.get("restId"),
      tableId: searchParams.get("tableId"),
      tableNo: searchParams.get("tableNo"),
      item: obj,
    };
    // const res = await removeItemToCart(finalObj)
    const res = await updateItemToCart(finalObj);
    refreshCart();
    // console.log(tempArr, "instruction");
    // setCartArr([...tempArr]);
    setInstruction("");
    setSelectedProduct({});
    closeModal();
  };

  const handleNavigateToMenu = () => {
    if (searchParams.get("previousRoute") != "") {
      searchParams.set("previousRoute", "Cart");
    }
    navigate({
      pathname: "/SelectCategory",
      search: encodeURI(searchParams),
    });
  };

  const sendOtp = async () => {
    try {
      if (!customerPhone) {
        throw new Error("Phone No is Invalid");
      }
      if (customerPhone.length != 10) {
        throw new Error(
          "Phone No is Invalid, it must not have country code and should be 10 digits"
        );
      }

      // http://localhost:3000/Product/View?tableId=1735&restaurantId=sjyqo0ib
      if (!customerName) {
        throw new Error("Name is required");
      }
      let otpObj = {
        mobile: customerPhone,
      };
      const { data: res } = await sendOtpApi(otpObj);
      if (res.success) {
        let tempOtp = res.data?.otp;
        toastSuccess(res.data?.message);
        if (tempOtp) {
          setOtp(tempOtp);
          setNameModelIsOpen(false);

          setisotpModel(true);
        }
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleSaveCustomerAndOrder = async () => {
    try {
      if (otp != verifyotp) {
        throw new Error("Otp Is Invalid");
      }

      let finalObj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        customerName: customerName,
        customerPhone: customerPhone,
      };
      if (customerPhone.length != 10) {
        throw new Error("Phone number needs to 10 digits");
      }

      const res = await registerCustomer(finalObj);
      if (res.data?.message) toastSuccess(res.data?.message);
      await refreshCart();

      handleSaveKot();
    } catch (error) {
      toastError(error);
      console.error(error);
    }
  };

  const handleSaveKot = async () => {
    try {
      if (cartArr?.length) {
        let obj = {
          restId: searchParams.get("restId"),
          tableId: searchParams.get("tableId"),
          tableNo: searchParams.get("tableNo"),
          cartArr: cartArr,
        };

        let { data: res } = await SaveKot(obj);
        if (res.message) {
          toastSuccess(res.message);
        }
      }
      // redirect to page

      navigate({
        pathname: "/GetBill",
        search: encodeURI(searchParams),
      });
    } catch (err) {
      toastError(err);
    }
  };

  // useEffect(() => {
  //   console.log("SDFGHJJHGFDF", cartArr.map(el => doesHaveAddon(el)))

  // }, [cartAddonArr, cartArr])

  const doesHaveAddon = (el) => {
    try {
      // console.log(cartAddonArr, el, "DFHGHK")
      if (el.addonArr?.length) {
        return true;
      } else {
        return (
          cartAddonArr?.find((elx) => el.itemid == elx.itemid)
            ?.itemallowvalidaddon == "1"
        );
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  };










  const handleReturnToPreviousPage = () => {
    if (searchParams.get("previousRoute") == "DashExplore") {
      return "/DashExplore"
    }
    if (searchParams.get("previousRoute") == "RecomendationCategory") {
      return "/RecomendationCategory"
    }
    if (searchParams.get("categoryType") && searchParams.get("categoryType") == "beverage") {
      return "/BeveragesCategory"
    }
    else if (searchParams.get("categoryType") && searchParams.get("categoryType") !== "beverage") {
      return "/MainCategory"
    }
    else {
      return null
    }
  }



  const checkLargestPricedVariant = (obj) => {
    if (obj.variation.some(el => el.checked)) {

    }
    else {
      return obj.variation.sort((a, b) => b.price - a.price)[0]
    }
  }




  return (
    <>
      <Header
        showBack
        showBillSearchCombineTop
        text={"Your cart"}
        backUrl={handleReturnToPreviousPage()}
        showSyncCart={true}
        setSearchQuery={(value) => handleSearch(value)}
      />
      <div className="container-fluid ">
        <RenderVideo mainVideo={bgVideo} />

        <div
          className={
            `row pt-3 d-flex justify-content-center`}
        >
          {cartArr &&
            cartArr.length > 0 &&
            displayCartArr &&
            displayCartArr.length
            ? displayCartArr.map((el, index) => {
              return (
                <div
                  key={index}
                  className="cart-Product"
                  style={{
                    backgroundColor: themeIsLight
                      ? "rgba(255, 255, 255, 0.2)"
                      : "rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <div className="d-f-fd-r">
                    <div className="col-lg-8 col-8">
                      <div className="cart-product-name">
                        <h4
                          className="main-head"
                          style={{ color: themeIsLight ? "black" : "white" }}
                        >
                          {el.itemname}
                        </h4>

                        <div className="bottom-part">
                          <div
                            className="cart-price"
                            style={{
                              color: themeIsLight ? "black" : "white",
                            }}
                          >
                            INR {el.price}
                          </div>

                          <div
                            className=" addons-desc"
                            style={{
                              color: themeIsLight ? "black" : "white",
                            }}
                          >
                            {el.addonArr.map((el, index) => {
                              return (
                                <div
                                  style={{
                                    color: themeIsLight ? "black" : "white",
                                  }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: themeIsLight ? "black" : "white",
                                    }}
                                  >
                                    {" "}
                                    {el.group_name}
                                  </span>{" "}
                                  :{" "}
                                  <span
                                    style={{
                                      color: themeIsLight ? "black" : "white",
                                    }}
                                    className="desert-name"
                                  >
                                    {" "}
                                    {el.name}{" "}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" col-lg-4 col-4">
                      <div
                        className="space-between"
                        style={{
                          borderColor: themeIsLight
                            ? "black"
                            : "rgb(194, 194, 194)",
                        }}
                      >
                        <span
                          onClick={() => {
                            handleRemoveCartQuantity(el);
                          }}
                          className="borderraduis"
                        >
                          {" "}
                          <AiOutlineMinus
                            style={{
                              color: themeIsLight ? "black" : "white",
                            }}
                          />
                        </span>
                        <span
                          className="mx-2"
                          style={{ color: themeIsLight ? "black" : "white" }}
                        >
                          {el.quantity}
                        </span>
                        <span
                          onClick={() => {
                            handleAddCartQuantity(el);
                          }}
                          className="borderraduis"
                        >
                          {" "}
                          <AiOutlinePlus
                            style={{
                              color: themeIsLight ? "black" : "white",
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "95vw",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="do"
                      style={{
                        flex: 1,
                        color: themeIsLight ? "black" : "white",
                        textTransform: "uppercase",
                      }}
                      onClick={() => {
                        setSelectedProduct(el);
                        setInstruction(el.instruction);
                        setIsOpen(true);
                      }}
                    >
                      {el.instruction && el.instruction != "" ? (
                        <span
                          style={{ color: themeIsLight ? "black" : "white" }}
                        >
                          {el.instruction}
                        </span>
                      ) : (
                        <>
                          <span
                            style={{
                              color: themeIsLight ? "black" : "white",
                            }}
                          >
                            Add Instructions
                          </span>
                        </>
                      )}
                    </div>
                    {doesHaveAddon(el) && (
                      <div
                        style={{
                          cursor: "pointer",
                          textAlign: "right",
                          color: "white",
                          textTransform: "uppercase",
                        }}
                        onClick={() => handleAddonShow(el.itemid, el)}
                      >
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            color: themeIsLight ? "black" : "white",
                          }}
                        >
                          Add / Edit
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })
            : cartArr &&
            cartArr.length > 0 && (
              <div className="cart-Product" style={{ marginTop: -15 }}>
                <div
                  className="cart-price"
                  style={{ color: themeIsLight ? "black" : "white" }}
                >
                  Could not find the product you were looking for.
                </div>
              </div>
            )}

          {cartArr && cartArr.length <= 0 && (
            <div className="cart-Product" style={{ marginTop: -15 }}>
              <div
                className="cart-price"
                style={{ color: themeIsLight ? "black" : "white" }}
              >
                Your cart is empty , please add some products to your cart{" "}
                <span
                  onClick={() => handleNavigateToMenu()}
                  style={{
                    color: themeIsLight ? "black" : "white",
                    textDecoration: "underline",
                  }}
                >
                  Go to menu
                </span>
              </div>
            </div>
          )}



          <CartProductRecomendation />






          <div
            className="bottom_fixed"
            style={{ backgroundColor: themeIsLight ? "white" : "black" }}
          >
            <div className="leftbootm">
              <h4 style={{ color: themeIsLight ? "black" : "white" }}>
                {" "}
                <span>Total :</span> INR{" "}
                {cartArr?.reduce((acc, el) => acc + el.price * el.quantity, 0) && cartArr?.reduce((acc, el) => acc + el.price * el.quantity, 0) > 0 ? cartArr?.reduce((acc, el) => acc + el.price * el.quantity, 0).toFixed(2) : 0}
              </h4>
              <div className="openmodal text-white">
                <div
                  // onClick={() => {
                  //   setIsOpen(true);
                  // }}
                  style={{ color: themeIsLight ? "black" : "white" }}
                >
                  {cartObj?.customerPhone && (
                    <span
                      style={{ color: themeIsLight ? "black" : "white" }}
                      className="customer-name"
                    >
                      {cartObj?.customerName} ({cartObj?.customerPhone})
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div
              className="proceed-btnn"
              style={{
                backgroundColor: themeIsLight ? "black" : "white",
                color: themeIsLight ? "white" : "black",
              }}
              onClick={() => {
                cartObj?.customerPhone ? handleSaveKot() : openNameModal();
              }}
            >
              Proceed
            </div>
          </div>
        </div>

        {/* cart-modal */}


        {/* <Modal
          isOpen={recomendationConfirmationModal}
          onRequestClose={() => setRecomendationConfirmationModal(false)}
          style={customStyles}
          contentLabel="Example Modal"
          className={`${themeIsLight ? "bg-blackk" : "bg-white"
            } cart-modal-box`}
        >
          <ModalHeader>
            <div
              className={`${themeIsLight ? "color-white" : "color-black"
                } modalHeading`}
            >
              Are you sure this will replace the items in your cart
            </div>
          </ModalHeader>
          <div className="container-fluid">
            <div
              className="row d-flex"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <button
                className="modal-white-button"
                style={{
                  color: themeIsLight ? "black" : "white",
                  backgroundColor: themeIsLight ? "white" : "black",
                }}
                onClick={() => handleAddRecomendationToCart()}
              >
                yes
              </button>
              <button
                style={{
                  color: themeIsLight ? "white" : "black",
                  backgroundColor: "transparent",
                  borderColor: themeIsLight ? "grey" : "black",
                }}
                className="outline-button"
                onClick={() => setRecomendationConfirmationModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal> */}




        <Modal
          isOpen={nameModalIsOpen}
          onRequestClose={closeNameModal}
          style={customStyles}
          contentLabel="Example Modal"
          className={`${themeIsLight ? "bg-blackk" : "bg-white"
            } cart-modal-box`}
        >
          <ModalHeader>
            <div
              className={`${themeIsLight ? "color-white" : "color-black"
                } modalHeading`}
            >
              Customer Details
            </div>
          </ModalHeader>
          <label htmlFor="" style={{ color: themeIsLight ? "white" : "black" }}>
            {" "}
            Name :{" "}
          </label>
          <input
            type="text"
            className={`${themeIsLight ? "bg-ip-grey" : "bg-ip-white"
              } cart-modal-box-ip`}
            onChange={(e) => setCustomerName(e.target.value)}
            value={customerName}
          />
          <label htmlFor="" style={{ color: themeIsLight ? "white" : "black" }}>
            Phone :
          </label>
          <input
            type="number"
            className={`${themeIsLight ? "bg-ip-grey" : "bg-ip-white"
              } cart-modal-box-ip`}
            onChange={(e) => setCustomerPhone(e.target.value)}
            value={customerPhone}
          />
          <div className="container-fluid">
            <div
              className="row d-flex"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <button
                className="modal-white-button"
                style={{
                  color: themeIsLight ? "black" : "white",
                  backgroundColor: themeIsLight ? "white" : "black",
                }}
                onClick={() => sendOtp()}
              >
                Submit
              </button>
              <button
                style={{
                  color: themeIsLight ? "white" : "black",
                  backgroundColor: "transparent",
                  borderColor: themeIsLight ? "grey" : "black",
                }}
                className="outline-button"
                onClick={() => closeNameModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        {/* OTP MODAL */}

        <Modal
          isOpen={isotpModel}
          onRequestClose={() => setisotpModel(false)}
          style={customStyles}
          contentLabel="Example Modal"
          className={`${themeIsLight ? "bg-blackk" : "bg-white"
            } cart-modal-box`}
        >
          <ModalHeader>
            <div
              className={`${themeIsLight ? "color-white" : "color-black"
                } modalHeading`}
            >
              Verify Otp
            </div>
          </ModalHeader>
          <input
            type="text"
            className={`${themeIsLight ? "bg-ip-white" : "bg-ip-white"
              } cart-modal-box-ip`}

            placeholder="Enter Your Otp"
            onChange={(e) => setVerifyotp(e.target.value)}
            value={verifyotp}
          />
          <div className="container-fluid">
            <div
              className="row d-flex"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <button
                className="modal-white-button"
                style={{
                  color: themeIsLight ? "black" : "white",
                  backgroundColor: themeIsLight ? "white" : "black",
                }}
                onClick={() => handleSaveCustomerAndOrder()}
              >
                Submit
              </button>
              <button
                className="outline-button"
                style={{
                  color: themeIsLight ? "white" : "black",
                  backgroundColor: "transparent",
                  borderColor: themeIsLight ? "grey" : "black",
                }}
                onClick={() => setisotpModel(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        {/* special instructions modal */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeNameModal}
          style={customStyles}
          contentLabel="Example Modal"
          className={`${themeIsLight ? "bg-blackk" : "bg-white"
            } cart-modal-box`}
        >
          <ModalHeader>
            <div
              className="modalHeading mb-3"
              style={{ color: themeIsLight ? "white" : "black" }}
            >
              Special Instructions
            </div>
          </ModalHeader>

          <div className="row">
            <div className="col-12 mx-auto">
              <div className="add-product-instructions">
                <h4 style={{ color: themeIsLight ? "black" : "white" }}>
                  {selectedProduct?.itemname}
                </h4>
              </div>
            </div>
          </div>
          <textarea
            type="text"
            className="add-p-inst-textarea"
            onChange={(e) => setInstruction(e.target.value)}
            value={instruction}
            style={{
              color: themeIsLight ? "white" : "black",
              borderColor: themeIsLight ? "white" : "black",
            }}
          />
          <div className="container-fluid">
            <div className="row d-f-s-b">
              <button
                className="modal-white-button"
                style={{
                  color: themeIsLight ? "white" : "black",
                  backgroundColor: themeIsLight ? "black" : "white",
                }}
                onClick={() => handleAddInstructionToProduct()}
              >
                Add
              </button>
              <button
                className="outline-button"
                style={{
                  color: "black",
                  backgroundColor: "white",
                  borderColor: themeIsLight ? "black" : "transparent",
                }}
                onClick={() => closeModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        {/* select-addons-modal */}
        <Modal
          isOpen={addonModal}
          onRequestClose={handleAddonClose}
          style={customStyles2}
          contentLabel="Example Modal"
          backdrop="static"
          keyboard={false}
        >
          <ModalHeader>
            <h4 style={{ color: themeIsLight ? "black" : "white" }}>
              Select Addons
            </h4>
          </ModalHeader>
          <div></div>
          <div
            style={{
              maxHeight: "60vh",
              overflowY: "auto",
              width: "100%",
              borderTop: "solid 1px white",
              borderBottom: "solid 1px white",
            }}
          >
            {addonArray && addonArray.length > 0 ? (
              addonArray.map((el) => (
                <div className="container-fluid mb-2">
                  <div className="row">
                    <div className="col-12">
                      <h5
                        className="selectadd-deserts"
                        style={{ color: themeIsLight ? "black" : "white" }}
                      >
                        {el.addongroup_name} (
                        {
                          selectedAddonArray.filter(
                            (ele) => ele.group_id == el.addongroupid
                          ).length
                        }
                        /{el.max_qty}) :
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 ml-2">
                      {el.addongroupitems.map((elx) => (
                        <div
                          className="row py-2"
                          onClick={() => selectAddon(el, elx)}
                        >
                          <div className="col-8">
                            <div
                              className="addon-item-name"
                              style={{
                                color: themeIsLight ? "black" : "white",
                              }}
                            >
                              {elx.addonitem_name}
                            </div>
                            {elx.addonitem_price != 0 && (
                              <div
                                className="select-addons-inr"
                                style={{
                                  color: themeIsLight ? "black" : "white",
                                }}
                              >
                                INR {elx.addonitem_price}
                              </div>
                            )}
                          </div>
                          <div className="col-4">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="mx-2 ">
                                <Checkbox
                                  checked={isChecked(
                                    el.addongroupid,
                                    elx.addonitemid
                                  )}
                                  name="addon"
                                  id={"addon" + elx._id + el._id}
                                  sx={{
                                    [`&, &.${checkboxClasses.checked}`]: {
                                      color: themeIsLight ? "black" : "white",
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="container-fluid mb-2">
                <div className="row">
                  <div className="col-12">
                    <h5 style={{ color: themeIsLight ? "black" : "white" }}>
                      Loading Please Wait...
                    </h5>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="d-flex justify-content-evenly mt-3"
            style={{ width: "100%" }}
          >
            <button
              className="me-1 btn btnclose flex-1"
              style={{
                color: themeIsLight ? "white" : "black",
                backgroundColor: themeIsLight ? "black" : "white",
              }}
              onClick={handleUpdateAddon}
            >
              Update Addons
            </button>
            <button
              className="ms-1 btn btnclose flex-1"
              style={{
                color: "black",
                backgroundColor: "white",
                borderColor: themeIsLight ? "black" : "transparent",
              }}
              onClick={handleAddonClose}
            >
              Close
            </button>
          </div>
        </Modal>
      </div >
    </>
  );
}






