import axiosApiInstance from "../utils/axiosApiInstance";
import { url } from "./url.service";

const serverUrl = url + "/restaurant";

export const addProduct = (formData) => {
  return axiosApiInstance.post(serverUrl + "/addProduct", formData);
};

export const getRestaurants = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getRestaurants?${query}`);
};
export const getRestaurantsById = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getRestaurantsById/${id}`);
};
export const getRestaurantsMenuByTableById = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getRestaurantsMenuByTableById/${id}`);
};

export const deleteProductById = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateRestaurantById = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};


export const setLocalStorageRestaurant = (obj) => {
  localStorage.setItem("restaurant", obj)
};
export const getLocalStorageRestaurant = () => {
  let restaurantObj = localStorage.getItem("restaurant")
  return JSON.parse(restaurantObj)
};
export const deleteLocalStorageRestaurant = () => {
  localStorage.removeItem("restaurant")
};





