import React, { useContext, useEffect, useRef, useState } from 'react'
import RenderVideo from '../ReuseableComponents/RenderVideo'
import Header from '../ReuseableComponents/Header'
import bgVideo from "../../assets/back.mp4";
import { toastError, toastSuccess } from '../../utils/toastUtils';
import { getTableRecomendation } from '../../services/DashRecomendation.service';
import { useSearchParams } from 'react-router-dom';
import { generateFilePath } from '../../utils/utils';
import { loadingContext, speedContext, themeContext } from '../../App';
import { addItemToCart, removeItemToCart, updateItemToCart } from '../../services/tableCart.service';
import { useCart, useRefreshCart } from '../CustomHooks/cartHooks';
import Modal from "react-bootstrap/Modal";
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { Checkbox, checkboxClasses } from '@mui/material';
import { getAddons } from '../../services/getAddons.service';
import { getRecomendationCategoryById } from '../../services/recomendationcategory.service';

export default function DashRecomendation() {
    const refreshCart = useRefreshCart()
    const [loading, setLoading] = useContext(loadingContext);

    const [themeIsLight, setThemeIsLight] = useContext(themeContext);

    const [searchParams] = useSearchParams();

    const [recomendationArr, setRecomendationArr] = useState([]);



    const videoRef = useRef();
    const [isMuted, setIsMuted] = useState(true);
    // const [cartArr, setCartArr] = useContext(cartArrContext);
    const cartArr = useCart();
    const [productDescription, setProductDescription] = useState("");
    const [showLessDescription, setShowLessDescription] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [speed, setSpeed] = useContext(speedContext);

    const [addonArray, setAddonArray] = useState([]);
    const [selectedAddonArray, setSelectedAddonArray] = useState([]);
    const [addonModal, setAddonModal] = useState(false);

    const [
        selectedItemBeforeAddonAndVariant,
        setSelectedItemBeforeAddonAndVariant,
    ] = useState({});
    const [selectedItemBeforeAddon, setSelectedItemBeforeAddon] = useState({});

    const handleClose = () => setModalVisible(false);
    const handleShow = () => setModalVisible(true);

    const handleAddonClose = () => setAddonModal(false);
    const handleAddonShow = () => setAddonModal(true);
    const [productObj, setProductObj] = useState({});

    const [recomendationCategoryObj, setRecomedationCategoryObj] = useState(null)


    const HandleGetTableRecomendation = async () => {
        try {
            let { data: res } = await getTableRecomendation(`tableId=${searchParams.get("tableId")}&restaurantId=${searchParams.get("restId")}&recomendationcategory=${searchParams.get("recomendationcategory")}`)
            if (res.data) {
                // console.log(res.data, "recomendation")
                setRecomendationArr(res.data)
            }
        }
        catch (err) {
            toastError(err)
        }
    }

    const getRecomendationCategoryObj = async () => {
        try {
            const { data: res } = await getRecomendationCategoryById(searchParams.get("recomendationcategory"));
            if (res) {
                setRecomedationCategoryObj(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }


    const handleAddToCart = async (obj) => {

        // console.log(obj.petPujaRestaurantId)
        for (const el of obj.productsArr) {

            let finalObj = {
                restaurantId: obj.petPujaRestaurantId,
                tableId: obj.petPujaTableId,
                tableNo: searchParams.get("tableNo"),
                item: el
            }
            // console.log(finalObj, "finalObj")
            const res = await addItemToCart(finalObj)

            if (res.status) {

            }

        }
        refreshCart()
    }



    useEffect(() => {
        HandleGetTableRecomendation()
        getRecomendationCategoryObj()
    }, [])

    const checkIfProductIsInCart = (obj) => {
        // console.log(obj, "obj");
        if (obj.itemallowvariation == "1") {
            let productIndex = cartArr.findIndex((el) =>
                obj.variation.some(
                    (ele) => `${obj.itemname} (${ele.name})` == el.itemname
                )
            );
            // console.log(productIndex, "productIndex");
            if (productIndex != -1) {
                return true;
            } else {
                return false;
            }
        } else {
            let productIndex = cartArr.findIndex((el) => el.itemname == obj.itemname);
            if (productIndex != -1) {
                return true;
            } else {
                return false;
            }
        }
    };

    const handleAddOrRemoveProductFromCart = async (obj, tempval = null) => {
        // console.log("obj asdf", obj);
        let localSelectedItemBeforeAddonAndVariant = tempval ? tempval : selectedItemBeforeAddonAndVariant
        setLoading(true);
        if (checkIfProductIsInCart(obj)) {
            handleYoloRemoveFromCart(obj);
            // if (obj.itemallowvariation == "1") {
            //     obj.variation?.forEach(el => {
            //         handleYoloRemoveFromCart({ itemid: el.id });
            //     })
            // }

            setLoading(false);
            setModalVisible(false);
            return;
        }
        // else {

        if (
            obj.isInCart &&
            localSelectedItemBeforeAddonAndVariant?.itemallowvalidaddon != "1"
        ) {
            let finalObj = {
                restaurantId: searchParams.get("restId"),
                tableId: searchParams.get("tableId"),
                tableNo: searchParams.get("tableNo"),
                item: obj,
            };
            const res = await removeItemToCart(finalObj);
            refreshCart();
        } else if (localSelectedItemBeforeAddonAndVariant?.itemallowvalidaddon == "1") {
            setSelectedItemBeforeAddon(obj);
            getAddon(localSelectedItemBeforeAddonAndVariant?.itemid, obj.variationid);
            setSelectedAddonArray([]);
            handleAddonShow();
        } else {
            let finalObj = {
                restaurantId: searchParams.get("restId"),
                tableId: searchParams.get("tableId"),
                tableNo: searchParams.get("tableNo"),
                item: obj,
            };
            const res = await addItemToCart(finalObj);
            refreshCart();
        }
        // }

        setLoading(false);
        setModalVisible(false);
    };


    const getAddon = async (productId, variantId = "") => {
        try {
            let obj = {
                restaurantId: searchParams.get("restId"),
                tableId: searchParams.get("tableId"),
                tableNo: searchParams.get("tableNo"),
                productId: productId,
                variantId: variantId,
            };

            let query = new URLSearchParams(obj).toString();
            const res = await getAddons(query);

            if (res.data?.data) {
                setAddonArray(res.data?.data);
            }

            // console.log(res.data);
        } catch (error) {
            toastError(error);
        }
    };
    const handleCheckIfVariantExists = (obj) => {
        // console.log("checking", obj);
        setSelectedItemBeforeAddonAndVariant(obj);
        if (obj.itemallowvariation == "1") {
            setModalVisible(true);
            // setSelectedVariant(obj);
        } else {
            if (checkIfProductIsInCart(obj)) {
                // handleYoloRemoveFromCart(obj);
                return;
            }
            // console.log("checking wtf", obj.itemallowvalidaddon, obj.itemallowvalidaddon == '1')
            if (obj.itemallowvalidaddon == "1") {
                // console.log("checking wtf 2", obj.itemallowvalidaddon, obj.itemallowvalidaddon == '1')

                getAddon(obj.itemid);
                setSelectedAddonArray([]);
                handleAddonShow();
            } else {
                handleAddOrRemoveProductFromCart({
                    ...obj,
                    quantity: obj.isInCart ? -1 : 1,
                }, obj);
            }
        }
    };

    const handleYoloRemoveFromCart = async (obj) => {
        try {
            let finalObj = {
                restaurantId: searchParams.get("restId"),
                tableId: searchParams.get("tableId"),
                tableNo: searchParams.get("tableNo"),
                item: obj,
            };
            const res = await removeItemToCart(finalObj);
            refreshCart();
        } catch (err) {
            toastError(err);
        }
    };

    const isChecked = (group_id, id) => {
        return selectedAddonArray.some(
            (el) => el.group_id == group_id && el.id == id
        );
    };

    const selectAddon = async (groupObj, addonObj) => {
        try {
            let obj = {
                group_id: groupObj.addongroupid,
                group_name: groupObj.addongroup_name,
                id: addonObj.addonitemid,
                name: addonObj.addonitem_name,
                price: addonObj.addonitem_price,
                quantity: 1,
            };
            let addonIndex = selectedAddonArray.findIndex(
                (el) => el.group_id == obj.group_id && el.id == obj.id
            );
            if (addonIndex == -1) {
                // check max quantity
                let max_qty = parseInt(groupObj.max_qty);
                if (
                    isNaN(max_qty) ||
                    (!isNaN(max_qty) &&
                        selectedAddonArray.filter((el) => el.group_id == obj.group_id)
                            .length < max_qty)
                ) {
                    setSelectedAddonArray((prev) => [...prev, obj]);
                } else {
                    throw new Error(
                        "You have already selected the max quantity for " + obj.group_name
                    );
                }
            } else {
                setSelectedAddonArray((prev) =>
                    prev.filter((el) => !(el.group_id == obj.group_id && el.id == obj.id))
                );
            }
        } catch (error) {
            toastError(error);
        }
    };

    const addCartWithAddon = async () => {
        try {
            // checking min qty
            let minErrorArr = [];
            for (let obj of addonArray) {
                let min_qty = parseInt(obj.min_qty);
                if (
                    !isNaN(min_qty) &&
                    min_qty > 0 &&
                    selectedAddonArray.filter((el) => obj.addongroupid == el.group_id)
                        .length < min_qty
                ) {
                    minErrorArr.push({ min_qty, name: obj.addongroup_name });
                }
            }

            if (minErrorArr.length) {
                let errStr = minErrorArr.reduce(
                    (acc, el) => acc + el.min_qty + " option(s) in " + el.name + "; ",
                    "Please select aleast "
                );
                throw new Error(errStr);
            } else {
                // call api here

                let cartObj = {
                    ...selectedItemBeforeAddonAndVariant,
                    ...selectedItemBeforeAddon,
                    addonArr: selectedAddonArray,
                };

                let finalObj = {
                    restaurantId: searchParams.get("restId"),
                    tableId: searchParams.get("tableId"),
                    tableNo: searchParams.get("tableNo"),
                    item: cartObj,
                };

                const res = await addItemToCart(finalObj);
                refreshCart();
                handleAddonClose();
            }
        } catch (error) {
            toastError(error);
        }
    };

    const handleRemoveCartQuantity = async (obj) => {
        try {
            setLoading(true);

            let tempCartArr = cartArr;
            let tempProductIndex = tempCartArr.findIndex(
                (el) => el.itemid == obj.itemid
            );

            if (tempProductIndex != -1) {
                if (tempCartArr[tempProductIndex].quantity - 1 <= 0) {
                    let finalObj = {
                        restaurantId: searchParams.get("restId"),
                        tableId: searchParams.get("tableId"),
                        tableNo: searchParams.get("tableNo"),
                        item: obj,
                    };
                    const res = await removeItemToCart(finalObj);
                    refreshCart();
                    setLoading(false);
                    // tempCartArr = tempCartArr.filter((el) => el.itemid != obj.itemid);
                } else {
                    obj.quantity = obj.quantity - 1;

                    let finalObj = {
                        restaurantId: searchParams.get("restId"),
                        tableId: searchParams.get("tableId"),
                        tableNo: searchParams.get("tableNo"),
                        item: obj,
                    };
                    // const res = await removeItemToCart(finalObj)
                    const res = await updateItemToCart(finalObj);
                    refreshCart();
                    setLoading(false);
                    // tempCartArr[tempProductIndex].quantity -= 1;
                }
            }
            setLoading(false);

            // setCartArr([...tempCartArr]);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const handleAddCartQuantity = async (obj) => {
        try {
            setLoading(true);
            // console.log("addcart", obj)
            let finalObj = {
                restaurantId: searchParams.get("restId"),
                tableId: searchParams.get("tableId"),
                tableNo: searchParams.get("tableNo"),
                item: obj,
            };
            const res = await addItemToCart(finalObj);
            refreshCart();
            // let tempCartArr = cartArr.map((el) => {
            //   if (el.itemid == obj.itemid) {
            //     el.quantity += 1;
            //   }
            //   return el;
            // });
            // setCartArr([...tempCartArr]);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const checkIsVariantInCartForVariantAddAndDelete = (variantObj) => {
        if (cartArr.some((el) => `${el?.itemname}` == variantObj.itemname)) {
            // return true
            // console.log(cartArr, "asd", cartArr.find(el => `${el?.itemname}` == variantObj.itemname))
            return cartArr.find((el) => `${el?.itemname}` == variantObj.itemname);
        } else {
            return false;
        }
    };


    const checkLargestPricedVariant = (obj) => {
        if (obj.variation.some((el) => el.checked)) {
        } else {
            return obj.variation.sort((a, b) => b.price - a.price)[0];
        }
    };

    return (
        <>

            <Header
                showBack
                backUrl={"/RecomendationCategory"}
                showBill showCartIconOnTop />
            <RenderVideo mainVideo={bgVideo} />
            {recomendationCategoryObj?.isCombo ?
                <>
                    {
                        recomendationArr && recomendationArr.length > 0 && recomendationArr.map((el, index) => {
                            return (
                                <div key={index} className='recomendation-product-card'>
                                    <img src={generateFilePath(el.image)} alt="" srcset="" />



                                    {
                                        el.productsArr.map((ele, index) => {
                                            return (
                                                <div key={index} style={{ color: themeIsLight ? "black" : "white", fontSize: 12, marginTop: 5 }} className={`${themeIsLight ? "black" : "white"} product-name`}>
                                                    {ele.itemname}
                                                    {ele.addonArr ? (`${ele.addonArr ? ele.addonArr.reduce((acc, elex, index) => acc + `${elex.name}${index != (ele?.addonArr?.length - 1) ? ", " : ""}`, "") : ""}`) : ""}
                                                </div>
                                            )
                                        })
                                    }

                                    <div className="space-between" style={{ border: "none", padding: "0px 0px 0px 0px" }}>
                                        <div className='product-price' style={{ color: themeIsLight ? "black" : "white" }}>INR {el.price}</div>
                                        <div className="add-to-cart" style={{ color: themeIsLight ? "black" : "white" }}>
                                            <div
                                                onClick={() => { handleAddToCart(el); toastSuccess("Added to cart") }}
                                                style={{
                                                    border: "solid 1px transparent",
                                                    color: themeIsLight ? "black" : "white",
                                                    // padding: "0px 0px 0px 0px"
                                                }}
                                                className="space-between"
                                            >
                                                ADD <span className="plus-icn ms-2">+</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div></div>

                </>
                :
                <>
                    {
                        recomendationArr && recomendationArr.length > 0 && recomendationArr.map((el, index) => {
                            // console.log(el, "elm")
                            return (
                                <div
                                    key={index}
                                    className="product-card"
                                    style={
                                        el?.productsArr && el?.productsArr[0]?.productObj?.item_image_url
                                            ? {
                                                backgroundImage: `url(${generateFilePath(
                                                    el?.productsArr[0]?.productObj?.item_image_url
                                                )})`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "100% 100%",
                                                borderBottom: `solid 1px ${themeIsLight ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.6)"}`
                                            }
                                            : {
                                                borderBottom: `solid 1px ${themeIsLight ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.6)"}`
                                            }
                                    }
                                // onClick={() => handleNavigateToProductDetails(el)}
                                >
                                    {el?.productsArr && el?.productsArr[0]?.productObj?.video && el?.productsArr && el?.productsArr[0]?.productObj?.video != "" && speed && speed > 2 && (
                                        <>
                                            <video
                                                src={generateFilePath(el?.productsArr && el?.productsArr[0]?.productObj?.video)}
                                                playsInline
                                                autoPlay
                                                muted
                                                loop
                                                style={{
                                                    position: "absolute",
                                                    left: "50%",
                                                    top: 0,
                                                    minHeight: "100%",
                                                    width: "100%",
                                                    transform: "translate(-50%)",
                                                }}
                                            ></video>
                                        </>
                                    )}

                                    <div
                                        className="overlay"
                                        style={{
                                            background: themeIsLight
                                                ? "transparent"
                                                : "linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 1) 100%)",
                                        }}
                                    ></div>
                                    <div className="products-card-content">
                                        <div className="row d-f-fr ">
                                            <div className="col-lg-8 col-8">
                                                <div
                                                    className="product-name"
                                                    style={{ color: themeIsLight ? "black" : "white" }}
                                                >
                                                    {(
                                                        <span
                                                            className="highlighter"
                                                            style={{
                                                                backgroundColor:
                                                                    el?.productsArr[0]?.productObj?.item_attributeid == "1" ? "green" : "red",
                                                            }}
                                                        ></span>
                                                    )}
                                                    <div>
                                                        <h5
                                                            className="heading"
                                                            style={{
                                                                color: themeIsLight ? "black" : "white",
                                                            }}
                                                        >
                                                            {el?.productsArr[0]?.productObj?.itemname}{" "}
                                                            {el?.productsArr[0]?.productObj?.itemallowvariation == "1" &&
                                                                `(${checkLargestPricedVariant(el?.productsArr && el?.productsArr[0]?.productObj)?.name
                                                                    })`.slice(0, 40)}{" "}
                                                        </h5>

                                                        <div
                                                            className="product-price"
                                                            style={{
                                                                color: themeIsLight ? "black" : "white",
                                                            }}
                                                        >
                                                            INR{" "}
                                                            {el?.productsArr[0]?.productObj.itemallowvariation == "1"
                                                                ? checkLargestPricedVariant(el?.productsArr[0]?.productObj).price
                                                                : el?.productsArr[0]?.productObj.price}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-4">
                                                <div className="product-card-bottom-part">
                                                    <div
                                                        className="cart"
                                                        style={{ zIndex: 150 }}

                                                    >
                                                        {/* <div className="cart" style={{ zIndex: 150 }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleCheckIfVariantExists({ ...el, isInCart: checkIfProductIsInCart(el) }) }}> */}
                                                        <div
                                                            className="add-to-cart"
                                                            style={{
                                                                color: themeIsLight ? "black" : "white",
                                                            }}
                                                        >
                                                            {checkIfProductIsInCart(el?.productsArr[0]?.productObj) &&
                                                                el?.productsArr[0]?.productObj.itemallowvariation == "1" ? (
                                                                <>
                                                                    ALTER <span>+ / -</span>
                                                                </>
                                                            ) : checkIfProductIsInCart(el?.productsArr[0]?.productObj) &&
                                                                el?.productsArr[0]?.productObj.itemallowvariation != "1" ? (
                                                                <>
                                                                    <div
                                                                        className="space-between"
                                                                        style={{
                                                                            borderColor: themeIsLight
                                                                                ? "black"
                                                                                : "white",
                                                                        }}
                                                                    >
                                                                        <span
                                                                            onClick={() => {
                                                                                checkIsVariantInCartForVariantAddAndDelete(
                                                                                    { ...el?.productsArr[0]?.productObj }
                                                                                ).quantity > 0 &&
                                                                                    handleRemoveCartQuantity({
                                                                                        ...el?.productsArr[0]?.productObj,
                                                                                        AddonItem:
                                                                                            checkIsVariantInCartForVariantAddAndDelete(
                                                                                                { ...el?.productsArr[0]?.productObj }
                                                                                            ).AddonItem,
                                                                                        addonArr:
                                                                                            checkIsVariantInCartForVariantAddAndDelete(
                                                                                                { ...el?.productsArr[0]?.productObj }
                                                                                            ).addonArr,
                                                                                        quantity:
                                                                                            checkIsVariantInCartForVariantAddAndDelete(
                                                                                                { ...el?.productsArr[0]?.productObj }
                                                                                            )
                                                                                                ? checkIsVariantInCartForVariantAddAndDelete(
                                                                                                    { ...el?.productsArr[0]?.productObj }
                                                                                                ).quantity
                                                                                                : 0,
                                                                                    });
                                                                            }}
                                                                            className="borderraduis"
                                                                            style={{
                                                                                color: themeIsLight ? "black" : "white",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            <AiOutlineMinus
                                                                                style={{
                                                                                    color: themeIsLight
                                                                                        ? "black"
                                                                                        : "white",
                                                                                }}
                                                                            />
                                                                        </span>
                                                                        <span
                                                                            className="mx-2"
                                                                            style={{
                                                                                color: themeIsLight ? "black" : "white",
                                                                            }}
                                                                        >
                                                                            {checkIsVariantInCartForVariantAddAndDelete(
                                                                                { ...el?.productsArr[0]?.productObj }
                                                                            )
                                                                                ? checkIsVariantInCartForVariantAddAndDelete(
                                                                                    { ...el?.productsArr[0]?.productObj }
                                                                                ).quantity
                                                                                : 0}
                                                                        </span>
                                                                        <span
                                                                            onClick={() => {
                                                                                handleAddCartQuantity({
                                                                                    ...el?.productsArr[0]?.productObj,
                                                                                    AddonItem: checkIsVariantInCartForVariantAddAndDelete({ ...el?.productsArr[0]?.productObj }).AddonItem,
                                                                                    addonArr: checkIsVariantInCartForVariantAddAndDelete({ ...el?.productsArr[0]?.productObj }).addonArr,
                                                                                    quantity: checkIsVariantInCartForVariantAddAndDelete({ ...el?.productsArr[0]?.productObj }) ? checkIsVariantInCartForVariantAddAndDelete({ ...el?.productsArr[0]?.productObj }).quantity + 1 : 1,
                                                                                });
                                                                            }}
                                                                            className="borderraduis"
                                                                            style={{
                                                                                color: themeIsLight ? "black" : "white",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            <AiOutlinePlus
                                                                                style={{
                                                                                    color: themeIsLight
                                                                                        ? "black"
                                                                                        : "white",
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                //   <>
                                                                //         Remove<span>-</span>
                                                                //     </>
                                                                <>
                                                                    <div
                                                                        onClick={(e) => {


                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            handleCheckIfVariantExists({
                                                                                ...el?.productsArr[0]?.productObj,
                                                                                isInCart: checkIfProductIsInCart({ ...el?.productsArr[0]?.productObj }),
                                                                            })
                                                                            if (el?.productsArr) {

                                                                                setProductObj(el?.productsArr[0]?.productObj);
                                                                            }
                                                                        }}
                                                                        style={{
                                                                            border: "solid 1px transparent",
                                                                            color: themeIsLight ? "black" : "white",
                                                                        }}
                                                                        className="space-between"
                                                                    >
                                                                        ADD <span className="plus-icn ms-2">+</span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                // <div key={index} className='recomendation-product-card'>
                                //     <img src={generateFilePath(el?.productsArr && el?.productsArr[0]?.productObj?.item_image_url)} alt="" srcset="" />



                                //     {
                                //         el.productsArr.map((ele, index) => {
                                //             return (
                                //                 <div key={index} style={{ color: themeIsLight ? "black" : "white", fontSize: 12, marginTop: 10 }} className={`${themeIsLight ? "black" : "white"} product-name`}>
                                //                     {ele.itemname}
                                //                     {ele.addonArr ? (`${ele.addonArr ? ele.addonArr.reduce((acc, elex, index) => acc + `${elex.name}${index != (ele?.addonArr?.length - 1) ? ", " : ""}`, "") : ""}`) : ""}
                                //                 </div>
                                //             )
                                //         })
                                //     }

                                //     <div className="space-between" style={{ border: "none" }}>
                                //         <div className="add-to-cart" style={{ color: themeIsLight ? "black" : "white" }}>
                                //             <div onClick={() => {
                                //                 setProductObj(el.productsArr[0]?.productObj);
                                //                 handleCheckIfVariantExists({
                                //                     ...el.productsArr[0]?.productObj,
                                //                     isInCart: checkIfProductIsInCart({ ...el.productsArr[0]?.productObj }),
                                //                 })
                                //             }
                                //             } style={{ border: `solid 1px ${themeIsLight ? "black" : "white"}`, width: "max-content", padding: "7px 15px", borderRadius: 10, marginTop: 5, marginLeft: -10 }} >
                                //                 Add
                                //             </div>
                                //         </div>
                                //         <div style={{ color: themeIsLight ? "black" : "white" }}>INR {el.price}</div>
                                //     </div>
                                // </div>
                            )
                        })
                    }
                    <div></div>

                </>
            }




            <Modal
                show={modalVisible}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                backdropClassName=""
                className={themeIsLight ? "modelvaraitionLight" : "modelvaraition"}
            // style={{ backgroundColor: themeIsLight ? "white" : "black" }}
            >
                <Modal.Header
                    closeButton
                    closeVariant={themeIsLight ? "black" : "white"}
                >
                    <Modal.Title>
                        {" "}
                        <h4 style={{ color: themeIsLight ? "black" : "white" }}>
                            Select Variations
                        </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                    {productObj &&
                        productObj.variation &&
                        productObj.variation.length > 0 &&
                        productObj.variation.map((el) => {
                            return (
                                <div className={`container-fluid`}>
                                    <div className={`row d-flex align-items-center py-2`}>
                                        <div className="col-8 product-name-variations ">
                                            <div
                                                style={{ color: themeIsLight ? "black" : "white" }}
                                                onClick={() =>
                                                    handleAddOrRemoveProductFromCart({
                                                        ...el,
                                                        itemname: `${productObj?.itemname} (${el?.name})`,
                                                        itemid: el.id,
                                                        price: el.price,
                                                    })
                                                }
                                            >
                                                {productObj?.itemname} ({el?.name})
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="space-between">
                                                <span
                                                    onClick={() => {
                                                        checkIsVariantInCartForVariantAddAndDelete({
                                                            ...el,
                                                            itemname: `${productObj?.itemname} (${el?.name})`,
                                                        }).quantity > 0 &&
                                                            handleRemoveCartQuantity({
                                                                ...el,
                                                                itemname: `${productObj?.itemname} (${el?.name})`,
                                                                itemid: el.id,
                                                                price: el.price,
                                                                quantity:
                                                                    checkIsVariantInCartForVariantAddAndDelete({
                                                                        ...el,
                                                                        itemname: `${productObj?.itemname} (${el?.name})`,
                                                                    })
                                                                        ? checkIsVariantInCartForVariantAddAndDelete(
                                                                            {
                                                                                ...el,
                                                                                itemname: `${productObj?.itemname} (${el?.name})`,
                                                                            }
                                                                        ).quantity
                                                                        : 0,
                                                            });
                                                    }}
                                                    className="borderraduis"
                                                >
                                                    {" "}
                                                    <AiOutlineMinus
                                                        style={{ color: themeIsLight ? "black" : "white" }}
                                                    />
                                                </span>
                                                <span
                                                    className="mx-2"
                                                    style={{ color: themeIsLight ? "black" : "white" }}
                                                >
                                                    {checkIsVariantInCartForVariantAddAndDelete({
                                                        ...el,
                                                        itemname: `${productObj?.itemname} (${el?.name})`,
                                                    })
                                                        ? checkIsVariantInCartForVariantAddAndDelete({
                                                            ...el,
                                                            itemname: `${productObj?.itemname} (${el?.name})`,
                                                        }).quantity
                                                        : 0}
                                                </span>
                                                <span
                                                    onClick={() => {
                                                        handleAddCartQuantity({
                                                            ...el,
                                                            itemname: `${productObj?.itemname} (${el?.name})`,
                                                            itemid: el.id,
                                                            price: el.price,
                                                            quantity:
                                                                checkIsVariantInCartForVariantAddAndDelete({
                                                                    ...el,
                                                                    itemname: `${productObj?.itemname} (${el?.name})`,
                                                                })
                                                                    ? checkIsVariantInCartForVariantAddAndDelete({
                                                                        ...el,
                                                                        itemname: `${productObj?.itemname} (${el?.name})`,
                                                                    }).quantity + 1
                                                                    : 1,
                                                        });
                                                    }}
                                                    className="borderraduis"
                                                >
                                                    {" "}
                                                    <AiOutlinePlus
                                                        style={{ color: themeIsLight ? "black" : "white" }}
                                                    />
                                                </span>
                                            </div>
                                            <div className="price-section mt-3">
                                                <h4 style={{ color: themeIsLight ? "black" : "white" }}>
                                                    {" "}
                                                    INR{" "}
                                                    {productObj.itemallowvariation == "1"
                                                        ? el.price
                                                        : productObj.price}{" "}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        style={{
                            color: "black",
                            backgroundColor: "white",
                            borderColor: themeIsLight ? "black" : "transparent",
                        }}
                        className="btn btnclose"
                        onClick={handleClose}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={addonModal}
                onHide={handleAddonClose}
                backdrop="static"
                keyboard={false}
                className={`${themeIsLight ? "modelvaraitionLight" : "modelvaraition"
                    }  prod-selectaddons`}
            >
                <Modal.Header
                    closeButton
                    closeVariant={themeIsLight ? "black" : "white"}
                >
                    <Modal.Title>
                        {" "}
                        <h4 style={{ color: themeIsLight ? "black" : "white" }}>
                            Select Addons
                        </h4>{" "}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{ maxHeight: "60vh", minHeight: "60vh", overflowY: "auto" }}
                >
                    {addonArray && addonArray.length > 0 ? (
                        addonArray.map((el) => (
                            <div className="container-fluid mb-2">
                                <div className="row">
                                    <div className="col-12">
                                        <h5
                                            className="selectadd-deserts"
                                            style={{ color: themeIsLight ? "black" : "white" }}
                                        >
                                            {el.addongroup_name} (
                                            {
                                                selectedAddonArray.filter(
                                                    (ele) => ele.group_id == el.addongroupid
                                                ).length
                                            }
                                            /{el.max_qty}) :
                                        </h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 ml-2">
                                        {el.addongroupitems.map((elx) => (
                                            <div
                                                className="row py-1"
                                                onClick={() => selectAddon(el, elx)}
                                            >
                                                <div className="col-8">
                                                    <div
                                                        className="addon-item-name"
                                                        style={{ color: themeIsLight ? "black" : "white" }}
                                                    >
                                                        {elx.addonitem_name}
                                                    </div>
                                                    {elx.addonitem_price != 0 && (
                                                        <div
                                                            className="select-addons-inr"
                                                            style={{
                                                                color: themeIsLight ? "black" : "white",
                                                            }}
                                                        >
                                                            INR {elx.addonitem_price}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-4">
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <div className="mx-2">
                                                            <Checkbox
                                                                checked={isChecked(
                                                                    el.addongroupid,
                                                                    elx.addonitemid
                                                                )}
                                                                id={"addon" + elx._id + el._id}
                                                                name="addon"
                                                                sx={{
                                                                    [`&, &.${checkboxClasses.checked}`]: {
                                                                        color: themeIsLight ? "black" : "white",
                                                                    },
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="container-fluid mb-2">
                            <div className="row">
                                <div className="col-12">
                                    <h5>Loading Please Wait...</h5>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btnclose"
                        style={{
                            color: themeIsLight ? "white" : "black",
                            backgroundColor: themeIsLight ? "black" : "white",
                        }}
                        onClick={addCartWithAddon}
                    >
                        Add
                    </button>
                    <button
                        className="btn btnclose"
                        style={{
                            color: "black",
                            backgroundColor: "white",
                            borderColor: themeIsLight ? "black" : "transparent",
                        }}
                        onClick={handleAddonClose}
                    >
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}