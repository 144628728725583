import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { speedContext } from '../../App';

const SpeedChecker = () => {
    const navigation = useNavigate();
    const [speed, setSpeed] = useContext(speedContext);
    const location = useLocation();

    const checkSpeed = () => {

        // var userImageLink =
        //     "https://media.geeksforgeeks.org/wp-content/cdn-uploads/20200714180638/CIP_Launch-banner.png";
        // var time_start, end_time;

        // // The size in bytes
        // var downloadSize = 5616998;
        // var downloadImgSrc = new Image();

        // downloadImgSrc.onload = function () {
        //     end_time = new Date().getTime();
        //     displaySpeed();
        // };
        // time_start = new Date().getTime();
        // downloadImgSrc.src = userImageLink;

        // function displaySpeed() {
        //     var timeDuration = (end_time - time_start) / 1000;
        //     var loadedBits = downloadSize * 8;

        //     /* Converts a number into string using toFixed(2) rounding to 2 */
        //     var bps = (loadedBits / timeDuration).toFixed(2);
        //     var speedInKbps = (bps / 1024).toFixed(2);
        //     var speedInMbps = (speedInKbps / 1024).toFixed(2);
        //     var speedInMBps = parseInt((speedInMbps / 8).toFixed(1));

        // }
        setSpeed(500)

    };


    useEffect(() => {
        checkSpeed()
    }, [location])

    return (
        <span>
            {/* <bu tton onClick={checkSpeed}>Check Speed</button> */}
            {/* {speed && <p style={{ color: "white" }}>Internet Speed: {speed} Mbps</p>} */}
        </span>
    );
};

export default SpeedChecker;



