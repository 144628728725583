import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { loadingContext, restaurantContext } from '../../App'
import bgVideo from "../../assets/back.mp4"
import { getMainCategories } from '../../services/maincategory.service'
import { toastError } from '../../utils/toastUtils'
import { generateFilePath } from '../../utils/utils'
import Header from '../ReuseableComponents/Header'
import RenderVideo from '../ReuseableComponents/RenderVideo'
import LoadingPage from './LoadingPage'
import TextBottomBar from './TextBottomBar'
export default function Category() {
    const [categoryMainArr, setCategoryMainArr] = useState([]);
    const [categoryArr, setCategoryArr] = useState([]);
    const [restaurantObj, setRestaurantObj] = useContext(restaurantContext);
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useContext(loadingContext);

    const handleGetCategory = async (restaurantId) => {
        try {
            setLoading(true)
            let { data: res } = await getMainCategories(`restaurantId=${restaurantId}&tableId=${searchParams.get("tableId")}&mainCategoryId=${searchParams.get("maincategoryId")}`)
            if (res.data) {
                // console.log(res.data[0])
                setLoading(false)
                setCategoryMainArr(res.data[0].categoryArr)
                // setCategoryArr(res.data)
            }
        }
        catch (err) {
            setLoading(false)
            toastError(err)
        }
    }


    useEffect(() => {
        if (restaurantObj && restaurantObj._id) {
            handleGetCategory(restaurantObj?.menu_sharing_code)
        }
    }, [restaurantObj])


    const handleNavigateToProductsPage = (catId) => {
        searchParams.set("categoryId", catId)

        navigation({
            pathname: "/Products", search: encodeURI(searchParams)
        })

    }




    useEffect(() => {
        let tempArr = categoryMainArr
        if (searchParams.get("allergen")) {
            let tempSelectedAllergenArr = decodeURIComponent(searchParams.get("allergen")).split(",");
            // console.log(decodeURIComponent(searchParams.get("allergen")).split(","), 'searchParams.get("allergen")')
            tempArr = tempArr.filter(el => el.allergensArr.some(ele => tempSelectedAllergenArr.some(elx => elx == ele)))
        }
        if (searchParams.get("filter") == "veg") {
            tempArr = tempArr.filter(el => el.categoryType == "veg")
        }
        // console.log(tempArr, "tempArr")
        setCategoryArr([...tempArr])

    }, [categoryMainArr, searchParams])


    const navigation = useNavigate()





    return (
        <>


            {
                loading && <LoadingPage />
            }
            {/* <div className="sticky-top"> */}
            <Header
                showSearch
                // showFilterInPlaceOfBack
                showFilter
                showBack
                text="Select Category"
            />
            {/* </div> */}

            <div className='container-fluid '>

                <RenderVideo mainVideo={bgVideo} />

                <div className="row d-flex justify-content-around mt-5">
                    {
                        categoryArr && categoryArr.length > 0 ? categoryArr.map((el, index) => {
                            return (


                                <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-6 my-2" onClick={() => handleNavigateToProductsPage(el.categoryid)}>
                                    <div className="menu-btn">
                                        <div className="menu-btn-img">
                                            <img src={generateFilePath(el.category_image_url)} alt="" className='img-fluid' />
                                        </div>


                                    </div>
                                    <div className="category-name">{el.categoryname}</div>

                                </div>

                            )
                        })
                            :
                            <div className="no-categories-found">  No Categories Found </div>
                    }
                </div>

            </div>
            <TextBottomBar />
        </>
    )
}
