import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import bgVideo from "../../assets/back.mp4";
import {
  createOrderForBillReceived,
  getTableBill,
  orderCallback,
  payNow
} from "../../services/Order.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { useCart, useCartObj } from "../CustomHooks/cartHooks";
import Header from "../ReuseableComponents/Header";
import RenderVideo from "../ReuseableComponents/RenderVideo";
import { loadingContext, themeContext } from "../../App";
import Modal from 'react-bootstrap/Modal';
import LoadingPage from "./LoadingPage";

export default function Bill() {
  const cartArr = useCart();
  const cartObj = useCartObj();
  const [themeIsLight, setThemeIsLight] = useContext(themeContext);
  const [loading, setLoading] = useContext(loadingContext);

  const [searchQuery, setSearchQuery] = useState("");
  const [instruction, setInstruction] = useState("");
  const [displayCartArr, setDisplayCartArr] = useState([]);

  const [billedData, setBilledData] = useState(null);

  const [modalIsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [selectedProduct, setSelectedProduct] = useState({});


  const [modal, setModal] = useState(false);

  const handleClose = () => setModal(false);

  const handleShow = () => setModal(true);



  useEffect(() => {
    getBillDetails();
  }, []);

  useEffect(() => {
    let myInterval;
    let tempCartCleared = localStorage.getItem("cartCleared");

    if (!billedData && !tempCartCleared) {
      myInterval = setInterval(() => { getBillDetails(); console.log("asd") }, 10000);
    }
    else if (billedData) {
      clearInterval(myInterval)
    }
    return () => { clearInterval(myInterval); localStorage.removeItem("cartCleared"); localStorage.removeItem("orderId") };
  }, [billedData]);







  const getBillDetails = async () => {
    try {
      let finalObj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
      };
      let res = await getTableBill(finalObj);

      if (res.data?.data) {
        console.log(res.data.data)
        setBilledData(res.data.data);
      }

      // if(cartObj?.billId)
    } catch (error) {
      toastError(error);
    }
  };


  const CreateOrder = async () => {
    try {
      let finalObj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
      };
      let res = await createOrderForBillReceived(finalObj);

      if (res) {
        handleClose()
        toastSuccess("Payment marked as payed as store");
        let query = `restId=${searchParams.get("restId")}&tableId=${searchParams.get("tableId")}&tableNo=${searchParams.get("tableNo")}`
        navigate(`/SelectCategory?${query}`)
        localStorage.setItem("orderId", res.data.orderId)
        // setBilledData(res.data.data);

      }

      // if(cartObj?.billId)
    } catch (error) {
      toastError(error);
    }
  };

  // const handleNavigateToMenu = () => {
  //   na vigate({
  //     pathname: "/SelectCategory",
  //     search: encodeURI(searchParams),
  //   });
  // };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  async function displayRazorpay(obj, orderId) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    try {


      const options = {
        key: "rzp_live_8B2dDg27ywlvng", // Enter the Key ID generated from the Dashboard
        // key: "rzp_test_ZQQJJGs52bq0Aa", // Enter the Key ID generated from the Dashboard
        amount: obj.amount,
        currency: obj.currency,
        name: "DashQr",
        description: "Order",
        // image: { logo },
        order_id: obj.id,
        handler: async function (response) {
          // console.log("RESPONSE", response);
          const data = {
            orderCreationId: obj.id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };

          const serialize = function (obj) {
            var str = [];
            for (var p in obj)
              if (obj.hasOwnProperty(p)) {
                str.push(
                  encodeURIComponent(p) + "=" + encodeURIComponent(obj[p])
                );
              }
            return str.join("&");
          };
          // console.log(data);
          let { data: res } = await orderCallback(
            serialize({
              ...obj,
              ...data,
              restaurantId: searchParams.get("restId"),
              tableId: searchParams.get("tableId"),
              tableNo: searchParams.get("tableNo"),
            }),
            orderId
          );
          if (res) {

            navigate({ pathname: "/SelectCategory", search: encodeURI(searchParams) });
            alert(res.message);
          }
          setLoading(false)
        },

        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }

    catch (err) {
      setLoading(false)

      console.error(err, "error")
    }
  }

  const handlePayment = async () => {
    try {
      setLoading(true)
      handleClose()
      let obj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        total: billedData?.order_data?.total,
        orderId: localStorage.getItem("orderId"),
      };

      let res = await payNow(obj);

      displayRazorpay(res.data.data, res.data.orderId);
      // let { data: res } = await SaveKot(obj)
      // if (res.message) {
      //   toastSuccess(res.message)
      // }
      // redirect to page

      // navigate({
      //   pathname: "/GetBill",
      //   search: encodeURI(searchParams),
      // });
    } catch (err) {
      toastError(err);
    }
  };

  const showPayedManually = () => {

    CreateOrder()

  }

  return (
    <>

      {
        loading && <LoadingPage message={"Please do not refresh the page or go back , your payment is being processed..."} />
      }

      <Header showBack />
      <div className="container-fluid ">
        <RenderVideo mainVideo={bgVideo} />
        <div className="row mt-3 d-flex justify-content-center">
          {billedData &&
            billedData?.items_data?.length &&
            billedData?.items_data.map((el, index) => {
              return (
                <div key={index} className="cart-Product" style={{ backgroundColor: themeIsLight ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)" }}>
                  <div className="d-f-fd-r">
                    <div className="col-lg-8 col-8">
                      <div className="cart-product-name">
                        <div style={{
                          display: "flex", flexDirection: "row", width: "95vw", justifyContent: "space-between"
                        }}>

                          <h4 className="main-head" style={{ color: themeIsLight ? "black" : "#B1B1B1" }}>
                            {el.name}
                          </h4>
                          <div className="cart-price" style={{ color: themeIsLight ? "black" : "#B1B1B1" }}>
                            {!(`${el.name} `.toLowerCase() == "cgst" || `${el.name} `.toLowerCase() == "sgst") && `${el.quantity} X`}
                            {" "}{el.price} {!(`${el.name} `.toLowerCase() == "cgst" || `${el.name} `.toLowerCase() == "sgst") && ` = INR ${el.quantity * el.price} `}
                            {(`${el.name} `.toLowerCase() == "cgst" || `${el.name} `.toLowerCase() == "sgst") && "%"}
                          </div>
                        </div>

                        <div className="bottom-part">
                          <div className=" addons-desc" style={{ color: themeIsLight ? "black" : "#B1B1B1" }}>
                            {el.addons && JSON.parse(el.addons).map((el, index) => {
                              return (
                                <div key={index} style={{ color: themeIsLight ? "black" : "#B1B1B1" }}> <span> {el.group_name}</span> : <span className="desert-name"> {el.name} </span></div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "95vw", alignItems: "center" }}>
                  </div>
                </div>
              );
            })}

          {!billedData ? (
            <div className="cart-Product1" style={{ background: themeIsLight && "white" }}>
              <div className="cart-price mt-4" style={{ color: themeIsLight ? "black" : "#B1B1B1" }}>
                Your bill has not been generated yet, Please wait as it may take
                some time to be generated{" "}, we will automatically refresh after every 10 seconds to get your bill

              </div>
            </div>
          ) : (
            <div className="cart-Product1" style={{ backgroundColor: themeIsLight ? "rgba(242, 240, 240,0.7)" : "#1a1b1ed1" }}>
              <div className="brder-bottm">
                <div className="d-f-fd-r mt-2">
                  <h6 className="sub-total-head" style={{ color: themeIsLight ? "black" : "#B1B1B1" }}>Sub-Total</h6>
                  <h6 className="sub-total-price" style={{ color: themeIsLight ? "grey" : "#B1B1B1" }}>INR {billedData?.order_data?.core_price}</h6>
                </div>
                {!!billedData?.order_data?.discount && (
                  <div className="d-f-fd-r">
                    <h6 className="sub-total-head">Discount</h6>
                    <h6 className="sub-total-price" style={{ color: themeIsLight ? "grey" : "#B1B1B1" }}>INR {billedData?.order_data?.discount}</h6>
                  </div>
                )}
                <div className="d-f-fd-r">
                  <h6 className="sub-total-head" style={{ color: themeIsLight ? "black" : "#B1B1B1" }}>Tax</h6>
                  <h6 className="sub-total-price" style={{ color: themeIsLight ? "grey" : "#B1B1B1" }} >INR {billedData?.order_data?.tax}</h6>
                </div>
              </div>
              <div className="d-f-fd-r pt-3">
                <h4 className="grand-total-head">Grand Total</h4>
                <h4 className="grand-total-price">INR {billedData?.order_data?.total}</h4>
              </div>
              <div className="d-f-fd-r">

                <button className="proceed-to-pay-buttn">Proceed to Pay    <span className="grand-total-price1">INR {billedData?.order_data?.total}</span></button>

              </div>
            </div>
          )}

          <div className="bottom_fixed" style={{ backgroundColor: themeIsLight ? "white" : "black" }}>
            <div className="leftbootm">
              <h4 style={{ color: themeIsLight ? "black" : "white" }}> Total INR {billedData?.order_data?.total}</h4>
            </div>
            <div className="centerbootm">
              <div className="openmodal">
                {
                  // billedData &&
                  <div className="proceed-to-pay" onClick={() => handleShow()}>
                    <span style={{ color: themeIsLight ? "black" : "white" }} className="cursor-pointer">Proceed To Pay</span>
                  </div>
                }
              </div>
            </div>
            <div className="rightbootm"></div>
          </div>
        </div>
        <Modal
          show={modal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          className={`${themeIsLight ? "modelvaraitionLight" : 'modelvaraition'} prod - selectaddons`}

        // className='modelvaraition prod-selectaddons'
        >
          <Modal.Header closeButton closeVariant={themeIsLight ? "black" : "white"} >
            <Modal.Title> <h4 style={{ color: themeIsLight ? "black" : "white" }}>Please select a payment method</h4> </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <button className='btn btnclose' style={{ color: themeIsLight ? "white" : "black", backgroundColor: themeIsLight ? "black" : "white" }} onClick={() => showPayedManually()}>
              Pay offline
            </button>
            <button className='btn btnclose' style={{ color: "black", backgroundColor: "white", borderColor: themeIsLight ? "black" : "transparent" }} onClick={() => handlePayment()}>
              Pay online
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
