import React, { useContext, useEffect } from 'react'
import Home from './Home'
import { useNavigate, useSearchParams } from 'react-router-dom'
import logo from "../../assets/logo.png"
import '../..'
import { loadingContext, restaurantContext } from '../../App'
import { generateFilePath } from '../../utils/utils'
import { getRestaurantsById } from '../../services/restaurant.service'
import { toastError } from '../../utils/toastUtils'
import LoadingPage from './LoadingPage'
import RenderVideo from '../ReuseableComponents/RenderVideo'
export default function SplashScreen() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [restaurantObj, setRestaurantObj] = useContext(restaurantContext);
  const [loading, setLoading] = useContext(loadingContext);

  const [searchsParams, setSearchsParams] = useSearchParams()
  useEffect(() => {
    if (searchsParams.get("restId")) {
      localStorage.setItem("RESTAURANT_ID", searchsParams.get("restId"))
      localStorage.setItem("firstLoad", "true")
    }
  }, [searchsParams.get("restId")])



  useEffect(() => {
    if (loading == false) {
      const timer = setTimeout(() => {
        navigate({
          pathname: "/SelectCategory",
          search: encodeURI(searchParams),
        });
      }, 4000)
      return () => {
        if (timer)
          clearTimeout(timer)
      }
    }
  }, [loading])


  const handleGetRestaurant = async () => {
    try {
      setLoading(true)
      let { data: res } = await getRestaurantsById(searchParams.get("restId"));
      if (res.data) {
        // console.log(res.data, "restaurantObj")
        setRestaurantObj(res.data);
        setLoading(false)

      }
    } catch (err) {
      setLoading(false)
      toastError(err);
    }
  };
  useEffect(() => {
    if (!searchParams.get("restId")) {
      toastError("Invalid Qr scanned !!!");
    } else {
      handleGetRestaurant();
    }
  }, [searchParams.get("restId")]);
  if (loading) {
    return (
      <LoadingPage />
    )
  }
  else {

    return (
      <>
        <div className="flexe1page">
          <RenderVideo />
          <h2 className=' animate fadeInUp one1' style={{ textAlign: "center" }}>Welcome to {restaurantObj.restaurantname}</h2>
          <img src={restaurantObj?.logo && generateFilePath(restaurantObj?.logo)} alt="" className='restaurantLogo animate fadeInUp one2' />
          <div className="loading-content-sscreen animate fadeInUp one3">
            <h6>It's not an app,
              <span>It's an appetiser</span>
            </h6>
          </div>
          <div className='brandLogo'>
            <h6 className='poweredBy  animate fadeInUp one3'>Powered by</h6>
            <img src={logo} alt="" className='img-fluid animate fadeInUp one3' />
          </div>
        </div>
      </>
    )
  }
}