import axiosApiInstance from "../utils/axiosApiInstance";
import { url } from "./url.service";

const serverUrl = url + "/recomendation";

export const getTableRecomendation = (query) => {
    return axiosApiInstance.get(`${serverUrl}/?${query}`);
};

export const updateCategoryById = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};