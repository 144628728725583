import { Checkbox, checkboxClasses } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { loadingContext, speedContext, themeContext } from "../../App";
import bgVideo from "../../assets/back.mp4";
import { getAddons } from "../../services/getAddons.service";
import { getProductByCategoryId } from "../../services/product.service";
import {
  addItemToCart,
  removeItemToCart,
  updateItemToCart,
} from "../../services/tableCart.service";
import { toastError } from "../../utils/toastUtils";
import { generateFilePath } from "../../utils/utils";
import { useCart, useRefreshCart } from "../CustomHooks/cartHooks";
import Header from "../ReuseableComponents/Header";
import RenderVideo from "../ReuseableComponents/RenderVideo";
import LoadingPage from "./LoadingPage";
import { FaMinus, FaPlus } from "react-icons/fa";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

// 1 veg
// 2 non - veg
// 24 egg
// 5 other

export default function FoodCategoryProducts() {
  const refreshCart = useRefreshCart();
  const cartArr = useCart();
  const [themeIsLight, setThemeIsLight] = useContext(themeContext);

  const [speed, setSpeed] = useContext(speedContext);

  const [loading, setLoading] = useContext(loadingContext);

  const [addonArray, setAddonArray] = useState([]);
  const [selectedAddonArray, setSelectedAddonArray] = useState([]);
  const [addonModal, setAddonModal] = useState(false);


  const [showProductsNotFound, setShowProductsNotFound] = useState(false);
  const [
    selectedItemBeforeAddonAndVariant,
    setSelectedItemBeforeAddonAndVariant,
  ] = useState({});
  const [selectedItemBeforeAddon, setSelectedItemBeforeAddon] = useState({});

  const handleAddonClose = () => setAddonModal(false);
  const handleAddonShow = () => setAddonModal(true);
  const [categoryObj, setCategoryObj] = useState({});
  const [searchParams] = useSearchParams();
  const [productsArr, setProductsArr] = useState([]);
  const [productMainArr, setProductMainArr] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(false);
  const handleClose = () => setModalVisible(false);
  const handleShow = () => setModalVisible(true);
  const navigate = useNavigate();

  const checkIfProductIsInCart = (obj) => {
    if (obj.itemallowvariation == "1") {
      let productIndex = cartArr.findIndex((el) =>
        obj.variation.some(
          (ele) => `${obj.itemname} (${ele.name})` == el.itemname
        )
      );
      if (productIndex != -1) {
        return true;
      } else {
        return false;
      }
    } else {
      let productIndex = cartArr.findIndex((el) => el.itemname == obj.itemname);
      if (productIndex != -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getAddon = async (productId, variantId = "") => {
    try {
      let obj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        productId: productId,
        variantId: variantId,
      };

      let query = new URLSearchParams(obj).toString();
      const res = await getAddons(query);

      if (res.data?.data) {
        setAddonArray(res.data?.data);
      }

      // console.log(res.data);
    } catch (error) {
      toastError(error);
    }
  };

  const handleGetCategoryProducts = async () => {
    try {
      setLoading(true);
      let { data: res } = await getProductByCategoryId(
        `restaurantId=${searchParams.get("restId")}&tableId=${searchParams.get(
          "tableId"
        )}&maincategoryId=${searchParams.get("maincategoryId")}`
      );
      if (res.data) {
        let tempArr = res.data.map((el) => {
          let obj = {
            ...el,
          };
          return obj;
        });
        setCategoryObj(res.categoryObj);
        // console.log(tempArr, "tempArr");
        setProductsArr([...tempArr]);
        setProductMainArr([...tempArr]);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  useEffect(() => {
    handleGetCategoryProducts(searchParams.get("categoryId"));
  }, [searchParams.get("categoryId")]);

  const handleSearch = (value) => {
    setSearchQuery(value);
    let tempArr = productMainArr;
    tempArr = tempArr.filter((el) =>
      `${el.itemname}`?.toLowerCase().includes(value?.toLowerCase())
    );
    setProductsArr([...tempArr]);
  };

  const handleAddOrRemoveProductFromCart = async (obj, temp = null) => {
    // console.log("obj asdf", obj);
    setLoading(true);
    let localSelectedItemBeforeAddonAndVariant = temp ? temp : selectedItemBeforeAddonAndVariant
    if (checkIfProductIsInCart(obj)) {
      handleYoloRemoveFromCart(obj);
      // if (obj.itemallowvariation == "1") {
      //     obj.variation?.forEach(el => {
      //         handleYoloRemoveFromCart({ itemid: el.id });
      //     })
      // }

      setLoading(false);
      setModalVisible(false);
      return;
    }
    // else {

    if (
      obj.isInCart &&
      localSelectedItemBeforeAddonAndVariant?.itemallowvalidaddon != "1"
    ) {
      let finalObj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        item: obj,
      };
      const res = await removeItemToCart(finalObj);
      refreshCart();
    } else if (localSelectedItemBeforeAddonAndVariant?.itemallowvalidaddon == "1") {
      setSelectedItemBeforeAddon(obj);
      getAddon(localSelectedItemBeforeAddonAndVariant?.itemid, obj.variationid);
      setSelectedAddonArray([]);
      handleAddonShow();
    } else {
      let finalObj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        item: obj,
      };
      const res = await addItemToCart(finalObj);
      refreshCart();
    }
    // }

    setLoading(false);
    setModalVisible(false);
  };

  const handleYoloRemoveFromCart = async (obj) => {
    try {
      let finalObj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        item: obj,
      };
      const res = await removeItemToCart(finalObj);
      refreshCart();
    } catch (err) {
      toastError(err);
    }
  };

  const handleCheckIfVariantExists = (obj) => {
    // console.log(obj, "object");

    setSelectedItemBeforeAddonAndVariant(obj);
    if (obj.itemallowvariation == "1") {
      setModalVisible(true);
      setSelectedVariant(obj);
    } else {
      if (checkIfProductIsInCart(obj)) {
        // handleYoloRemoveFromCart(obj);
        return;
      }

      if (obj.itemallowvalidaddon == "1") {
        getAddon(obj.itemid);
        setSelectedAddonArray([]);
        handleAddonShow();
      } else {
        handleAddOrRemoveProductFromCart({
          ...obj,
          quantity: obj.isInCart ? -1 : 1,
        });
      }
    }
  };

  const isChecked = (group_id, id) => {
    return selectedAddonArray.some(
      (el) => el.group_id == group_id && el.id == id
    );
  };

  const selectAddon = async (groupObj, addonObj) => {
    try {
      let obj = {
        group_id: groupObj.addongroupid,
        group_name: groupObj.addongroup_name,
        id: addonObj.addonitemid,
        name: addonObj.addonitem_name,
        price: addonObj.addonitem_price,
        quantity: 1,
      };
      let addonIndex = selectedAddonArray.findIndex(
        (el) => el.group_id == obj.group_id && el.id == obj.id
      );
      if (addonIndex == -1) {
        // check max quantity
        let max_qty = parseInt(groupObj.max_qty);
        if (
          isNaN(max_qty) ||
          (!isNaN(max_qty) &&
            selectedAddonArray.filter((el) => el.group_id == obj.group_id)
              .length < max_qty)
        ) {
          setSelectedAddonArray((prev) => [...prev, obj]);
        } else {
          throw new Error(
            "You have already selected the max quantity for " + obj.group_name
          );
        }
      } else {
        setSelectedAddonArray((prev) =>
          prev.filter((el) => !(el.group_id == obj.group_id && el.id == obj.id))
        );
      }
    } catch (error) {
      toastError(error);
    }
  };

  const addCartWithAddon = async () => {
    try {
      // checking min qty
      let minErrorArr = [];
      for (let obj of addonArray) {
        let min_qty = parseInt(obj.min_qty);
        if (
          !isNaN(min_qty) &&
          min_qty > 0 &&
          selectedAddonArray.filter((el) => obj.addongroupid == el.group_id)
            .length < min_qty
        ) {
          minErrorArr.push({ min_qty, name: obj.addongroup_name });
        }
      }

      if (minErrorArr.length) {
        let errStr = minErrorArr.reduce(
          (acc, el) => acc + el.min_qty + " option(s) in " + el.name + "; ",
          "Please select aleast "
        );
        throw new Error(errStr);
      } else {
        // call api here

        let cartObj = {
          ...selectedItemBeforeAddonAndVariant,
          ...selectedItemBeforeAddon,
          addonArr: selectedAddonArray,
        };

        let finalObj = {
          restaurantId: searchParams.get("restId"),
          tableId: searchParams.get("tableId"),
          tableNo: searchParams.get("tableNo"),
          item: cartObj,
        };

        const res = await addItemToCart(finalObj);
        refreshCart();
        handleAddonClose();
        // console.log("added to cart");
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleNavigateToProductDetails = (obj) => {
    searchParams.set("productId", obj.itemid);
    searchParams.set("previousRoute", "FoodCategoryProducts");

    navigate({ pathname: "/Productdatails", search: encodeURI(searchParams) });
  };

  const checkLargestPricedVariant = (obj) => {
    if (obj.variation.some((el) => el.checked)) {
    } else {
      return obj.variation.sort((a, b) => b.price - a.price)[0];
    }
  };

  const checkIsVariantInCart = (variantObj) => {
    if (cartArr.some((el) => `${el?.itemname}` == variantObj.itemname)) {
      return true;
      // return cartArr.find(el => `${el?.itemname}` == variantObj.itemname)[0]
    } else {
      return false;
    }
  };

  const checkIsVariantInCartForVariantAddAndDelete = (variantObj) => {
    if (cartArr.some((el) => `${el?.itemname}` == variantObj.itemname)) {
      // return true
      // console.log(cartArr, "asd", cartArr.find(el => `${el?.itemname}` == variantObj.itemname))
      return cartArr.find((el) => `${el?.itemname}` == variantObj.itemname);
    } else {
      return false;
    }
  };

  useEffect(() => {
    let tempArr = productMainArr;
    if (searchParams.get("filter")) {
      if (searchParams.get("filter") == "veg") {
        tempArr = tempArr.filter((el) => el.item_attributeid == "1");
      }
    }
    if (searchParams.get("allergen")) {
      let tempSelectedAllergenArr = decodeURIComponent(
        searchParams.get("allergen")
      ).split(",");
      // console.log(
      //   decodeURIComponent(searchParams.get("allergen")).split(","),
      //   'searchParams.get("allergen")'
      // );
      tempArr = tempArr.filter((el) =>
        el.allergensArr.some((ele) =>
          tempSelectedAllergenArr.some((elx) => elx == ele.name)
        )
      );
    }

    setProductsArr([...tempArr]);
  }, [searchParams, productMainArr]);

  const handleRemoveCartQuantity = async (obj) => {
    try {
      setLoading(true);

      let tempCartArr = cartArr;
      let tempProductIndex = tempCartArr.findIndex(
        (el) => el.itemid == obj.itemid
      );

      if (tempProductIndex != -1) {
        if (tempCartArr[tempProductIndex].quantity - 1 <= 0) {
          let finalObj = {
            restaurantId: searchParams.get("restId"),
            tableId: searchParams.get("tableId"),
            tableNo: searchParams.get("tableNo"),
            item: obj,
          };
          const res = await removeItemToCart(finalObj);
          refreshCart();
          setLoading(false);
          // tempCartArr = tempCartArr.filter((el) => el.itemid != obj.itemid);
        } else {
          obj.quantity = obj.quantity - 1;

          let finalObj = {
            restaurantId: searchParams.get("restId"),
            tableId: searchParams.get("tableId"),
            tableNo: searchParams.get("tableNo"),
            item: obj,
          };
          // const res = await removeItemToCart(finalObj)
          const res = await updateItemToCart(finalObj);
          refreshCart();
          setLoading(false);
          // tempCartArr[tempProductIndex].quantity -= 1;
        }
      }
      setLoading(false);

      // setCartArr([...tempCartArr]);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleAddCartQuantity = async (obj) => {
    try {
      setLoading(true);
      // console.log("addcart", obj)
      let finalObj = {
        restaurantId: searchParams.get("restId"),
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        item: obj,
      };
      const res = await addItemToCart(finalObj);
      refreshCart();
      // let tempCartArr = cartArr.map((el) => {
      //   if (el.itemid == obj.itemid) {
      //     el.quantity += 1;
      //   }
      //   return el;
      // });
      // setCartArr([...tempCartArr]);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };


  useEffect(() => {
    if (loading == false) {
      setTimeout(() => {
        setShowProductsNotFound(true)
      }, 1000)
    }
    return setShowProductsNotFound(false)
  }, [loading])


  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className="sticky-top">
        <Header
          showBack
          showSearch
          showFilter
          showCart
          showBill
          text={categoryObj?.name}
          showVideo={showVideo}
          setShowVideo={setShowVideo}
          showVideoToggle={true}
          setSearchQuery={(value) => handleSearch(value)}
          backUrl={"/MainCategory"}
        />
      </div>

      <div className="container-fluid">
        <RenderVideo mainVideo={bgVideo} />

        <div className="row  d-flex justify-content-around px-3">
          {productsArr && productsArr.length > 0 ? (
            productsArr.map((el, index) => {
              return (
                <div
                  key={index}
                  className="product-card"
                  style={
                    el.item_image_url && el.item_image_url != ""
                      ? {
                        backgroundImage: `url(${generateFilePath(
                          el.item_image_url
                        )})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "100% 100%",
                        borderBottom: `solid 1px ${themeIsLight ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.6)"}`
                      }
                      : {
                        borderBottom: `solid 1px ${themeIsLight ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.6)"}`
                      }
                  }
                  onClick={() => handleNavigateToProductDetails(el)}
                >
                  {el.video && el.video != "" && speed && speed > 2 && (
                    <>
                      <video
                        src={generateFilePath(el.video)}
                        playsInline
                        autoPlay
                        muted
                        loop
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: 0,
                          minHeight: "100%",
                          width: "100%",
                          transform: "translate(-50%)",
                        }}
                      ></video>
                    </>
                  )}

                  <div
                    className="overlay"
                    style={{
                      background: themeIsLight
                        ? "transparent"
                        : "linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%)",
                    }}
                  ></div>
                  <div className="products-card-content">
                    <div className="row d-f-fr ">
                      <div className="col-lg-8 col-8">
                        <div
                          className="product-name"
                          style={{ color: themeIsLight ? "black" : "white" }}
                        >
                          {!searchParams
                            .get("categoryType")
                            ?.toLowerCase()
                            .includes("beverage") && (
                              <span
                                className="highlighter"
                                style={{
                                  backgroundColor:
                                    el.item_attributeid == "1" ? "green" : "red",
                                }}
                              ></span>
                            )}
                          <div>
                            <h5
                              className="heading"
                              style={{
                                color: themeIsLight ? "black" : "white",
                              }}
                            >
                              {el.itemname}{" "}
                              {el.itemallowvariation == "1" &&
                                `(${checkLargestPricedVariant(el)?.name
                                  })`.slice(0, 40)}{" "}
                            </h5>

                            <div
                              className="product-price"
                              style={{
                                color: themeIsLight ? "black" : "white",
                              }}
                            >
                              INR{" "}
                              {el.itemallowvariation == "1"
                                ? checkLargestPricedVariant(el).price
                                : el.price}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-4">
                        <div className="product-card-bottom-part">
                          <div
                            className="cart"
                            style={{ zIndex: 150 }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleCheckIfVariantExists({
                                ...el,
                                isInCart: false,
                              });
                            }}
                          >
                            {/* <div className="cart" style={{ zIndex: 150 }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleCheckIfVariantExists({ ...el, isInCart: checkIfProductIsInCart(el) }) }}> */}
                            <div
                              className="add-to-cart"
                              style={{
                                color: themeIsLight ? "black" : "white",
                              }}
                            >
                              {checkIfProductIsInCart(el) &&
                                el.itemallowvariation == "1" ? (
                                <>
                                  ALTER <span>+ / -</span>
                                </>
                              ) : checkIfProductIsInCart(el) &&
                                el.itemallowvariation != "1" ? (
                                <>
                                  <div
                                    className="space-between"
                                    style={{
                                      borderColor: themeIsLight
                                        ? "black"
                                        : "white",
                                    }}
                                  >
                                    <span
                                      onClick={() => {
                                        checkIsVariantInCartForVariantAddAndDelete(
                                          { ...el }
                                        ).quantity > 0 &&
                                          handleRemoveCartQuantity({
                                            ...el,
                                            AddonItem:
                                              checkIsVariantInCartForVariantAddAndDelete(
                                                { ...el }
                                              ).AddonItem,
                                            addonArr:
                                              checkIsVariantInCartForVariantAddAndDelete(
                                                { ...el }
                                              ).addonArr,
                                            quantity:
                                              checkIsVariantInCartForVariantAddAndDelete(
                                                { ...el }
                                              )
                                                ? checkIsVariantInCartForVariantAddAndDelete(
                                                  { ...el }
                                                ).quantity
                                                : 0,
                                          });
                                      }}
                                      className="borderraduis"
                                      style={{
                                        color: themeIsLight ? "black" : "white",
                                      }}
                                    >
                                      {" "}
                                      <AiOutlineMinus
                                        style={{
                                          color: themeIsLight
                                            ? "black"
                                            : "white",
                                        }}
                                      />
                                    </span>
                                    <span
                                      className="mx-2"
                                      style={{
                                        color: themeIsLight ? "black" : "white",
                                      }}
                                    >
                                      {checkIsVariantInCartForVariantAddAndDelete(
                                        { ...el }
                                      )
                                        ? checkIsVariantInCartForVariantAddAndDelete(
                                          { ...el }
                                        ).quantity
                                        : 0}
                                    </span>
                                    <span
                                      onClick={() => {
                                        handleAddCartQuantity({
                                          ...el,
                                          AddonItem:
                                            checkIsVariantInCartForVariantAddAndDelete(
                                              { ...el }
                                            ).AddonItem,
                                          addonArr:
                                            checkIsVariantInCartForVariantAddAndDelete(
                                              { ...el }
                                            ).addonArr,
                                          quantity:
                                            checkIsVariantInCartForVariantAddAndDelete(
                                              { ...el }
                                            )
                                              ? checkIsVariantInCartForVariantAddAndDelete(
                                                { ...el }
                                              ).quantity + 1
                                              : 1,
                                        });
                                      }}
                                      className="borderraduis"
                                      style={{
                                        color: themeIsLight ? "black" : "white",
                                      }}
                                    >
                                      {" "}
                                      <AiOutlinePlus
                                        style={{
                                          color: themeIsLight
                                            ? "black"
                                            : "white",
                                        }}
                                      />
                                    </span>
                                  </div>
                                </>
                              ) : (
                                //   <>
                                //         Remove<span>-</span>
                                //     </>
                                <>
                                  <div
                                    style={{
                                      border: "solid 1px transparent",
                                      color: themeIsLight ? "black" : "white",
                                    }}
                                    className="space-between"
                                  >
                                    ADD <span className="plus-icn ms-2">+</span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="product-card">
              <div className="no-product-name">
                {
                  showProductsNotFound &&
                  <p className="no-products"> No products found</p>
                }
              </div>
            </div>
          )}
        </div>

        <Modal
          show={modalVisible}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          className={themeIsLight ? "modelvaraitionLight" : "modelvaraition"}
        >
          <Modal.Header
            closeButton
            closeVariant={themeIsLight ? "black" : "white"}
          >
            <Modal.Title className="s-variation-head">
              <h4 style={{ color: themeIsLight ? "black" : "white" }}>
                Select Variations
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "40vh", overflowY: "scroll" }}>
            {selectedVariant &&
              selectedVariant.variation &&
              selectedVariant.variation.length > 0 &&
              selectedVariant.variation.map((el) => {
                return (
                  <>
                    {/* //${cartArr.some(elx => elx.itemid == el.id) ? "selectedVariant" : ""}// */}
                    <div className={`container-fluid `}>
                      <div className={`row d-flex align-items-center py-2`}>
                        <div className="col-8 product-name-variations ">
                          <h5
                            className="heading"
                            style={{ color: themeIsLight ? "black" : "white" }}
                            onClick={() =>
                              handleAddOrRemoveProductFromCart({
                                ...el,
                                itemname: `${selectedVariant?.itemname} (${el?.name})`,
                                itemid: el.id,
                                price: el.price,
                                quantity: checkIsVariantInCart(el) ? -1 : 1,
                              })
                            }
                          >
                            {selectedVariant?.itemname} ({el?.name})
                            {/* {checkIsVariantInCartForVariantAddAndDelete({ ...el, itemname: `${selectedVariant?.itemname} (${el?.name})` }) ? "yes" : "no"} */}
                          </h5>
                        </div>
                        <div className="col-4">
                          <div className="space-between">
                            <span
                              style={{
                                color: themeIsLight ? "black" : "white",
                              }}
                              onClick={() => {
                                checkIsVariantInCartForVariantAddAndDelete({
                                  ...el,
                                  itemname: `${selectedVariant?.itemname} (${el?.name})`,
                                }).quantity > 0 &&
                                  handleRemoveCartQuantity({
                                    ...el,
                                    itemname: `${selectedVariant?.itemname} (${el?.name})`,
                                    itemid: el.id,
                                    price: el.price,
                                    quantity:
                                      checkIsVariantInCartForVariantAddAndDelete(
                                        {
                                          ...el,
                                          itemname: `${selectedVariant?.itemname} (${el?.name})`,
                                        }
                                      )
                                        ? checkIsVariantInCartForVariantAddAndDelete(
                                          {
                                            ...el,
                                            itemname: `${selectedVariant?.itemname} (${el?.name})`,
                                          }
                                        ).quantity
                                        : 0,
                                  });
                              }}
                              className="borderraduis"
                            >
                              {" "}
                              {/* <span style={{ fontSize: 15 }}> -</span> */}
                              <AiOutlineMinus
                                style={{
                                  color: themeIsLight ? "black" : "white",
                                }}
                              />
                            </span>
                            <span
                              className="mx-2"
                              style={{
                                color: themeIsLight ? "black" : "white",
                              }}
                            >
                              {checkIsVariantInCartForVariantAddAndDelete({
                                ...el,
                                itemname: `${selectedVariant?.itemname} (${el?.name})`,
                              })
                                ? checkIsVariantInCartForVariantAddAndDelete({
                                  ...el,
                                  itemname: `${selectedVariant?.itemname} (${el?.name})`,
                                }).quantity
                                : 0}
                            </span>
                            <span
                              onClick={() => {
                                handleAddCartQuantity({
                                  ...el,
                                  itemname: `${selectedVariant?.itemname} (${el?.name})`,
                                  itemid: el.id,
                                  price: el.price,
                                  quantity:
                                    checkIsVariantInCartForVariantAddAndDelete({
                                      ...el,
                                      itemname: `${selectedVariant?.itemname} (${el?.name})`,
                                    })
                                      ? checkIsVariantInCartForVariantAddAndDelete(
                                        {
                                          ...el,
                                          itemname: `${selectedVariant?.itemname} (${el?.name})`,
                                        }
                                      ).quantity + 1
                                      : 1,
                                });
                              }}
                              className="borderraduis"
                              style={{
                                color: themeIsLight ? "black" : "white",
                              }}
                            >
                              {" "}
                              {/* + */}
                              <AiOutlinePlus
                                style={{
                                  color: themeIsLight ? "black" : "white",
                                }}
                              />
                            </span>
                          </div>
                          <div
                            className="price-section mt-3"
                            style={{ color: themeIsLight ? "black" : "white" }}
                          >
                            <h4>
                              {" "}
                              INR{" "}
                              {selectedVariant.itemallowvariation == "1"
                                ? el.price
                                : selectedVariant.price}{" "}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btnclose"
              style={{
                color: "black",
                backgroundColor: "white",
                borderColor: themeIsLight ? "black" : "transparent",
              }}
              onClick={handleClose}
            >
              Close
            </button>
            {/* <button className='btn btnclose '>Understood</button> */}
          </Modal.Footer>
        </Modal>

        <Modal
          show={addonModal}
          onHide={handleAddonClose}
          backdrop="static"
          keyboard={false}
          className={`${themeIsLight ? "modelvaraitionLight" : "modelvaraition"
            }  prod-selectaddons`}
        >
          <Modal.Header
            closeButton
            closeVariant={themeIsLight ? "black" : "white"}
          >
            <Modal.Title>
              {" "}
              <h4 style={{ color: themeIsLight ? "black" : "white" }}>
                Select Addons
              </h4>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{ maxHeight: "60vh", minHeight: "60vh", overflowY: "auto" }}
          >
            {addonArray && addonArray.length > 0 ? (
              addonArray.map((el) => (
                <div className="container-fluid mb-2">
                  <div className="row">
                    <div className="col-12">
                      <h5
                        className="selectadd-deserts"
                        style={{ color: themeIsLight ? "black" : "white" }}
                      >
                        {el.addongroup_name} (
                        {
                          selectedAddonArray.filter(
                            (ele) => ele.group_id == el.addongroupid
                          ).length
                        }
                        /{el.max_qty}) :
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 ml-2">
                      {el.addongroupitems.map((elx) => (
                        <div
                          className="row py-2"
                          onClick={() => selectAddon(el, elx)}
                        >
                          <div className="col-8">
                            <div
                              className="addon-item-name"
                              style={{
                                color: themeIsLight ? "black" : "white",
                              }}
                            >
                              {elx.addonitem_name}
                            </div>
                            {elx.addonitem_price != 0 && (
                              <div
                                className="select-addons-inr"
                                style={{
                                  color: themeIsLight ? "black" : "white",
                                }}
                              >
                                INR {elx.addonitem_price}
                              </div>
                            )}
                          </div>
                          <div className="col-4">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="mx-2">
                                <Checkbox
                                  checked={isChecked(
                                    el.addongroupid,
                                    elx.addonitemid
                                  )}
                                  name="addon"
                                  id={"addon" + elx._id + el._id}
                                  sx={{
                                    [`&, &.${checkboxClasses.checked}`]: {
                                      color: themeIsLight ? "black" : "white",
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="container-fluid mb-2">
                <div className="row">
                  <div className="col-12">
                    <h5>Loading Please Wait...</h5>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btnclose"
              style={{
                color: themeIsLight ? "white" : "black",
                backgroundColor: themeIsLight ? "black" : "white",
              }}
              onClick={addCartWithAddon}
            >
              Add
            </button>
            <button
              className="btn btnclose"
              style={{
                color: "black",
                backgroundColor: "white",
                borderColor: themeIsLight ? "black" : "transparent",
              }}
              onClick={handleAddonClose}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
