import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { loadingContext, restaurantContext, themeContext } from '../../App';
import bgVideo from "../../assets/back.mp4";
import { getMainCategories } from '../../services/maincategory.service';
import { toastError } from '../../utils/toastUtils';
import { generateFilePath } from '../../utils/utils';
import Header from '../ReuseableComponents/Header';
import RenderVideo from '../ReuseableComponents/RenderVideo';
import LoadingPage from './LoadingPage';
import TextBottomBar from './TextBottomBar';
export default function MainCategory() {
    const [categoryMainArr, setCategoryMainArr] = useState([]);
    const [categoryArr, setCategoryArr] = useState([]);
    const [restaurantObj, setRestaurantObj] = useContext(restaurantContext);
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const [loading, setLoading] = useContext(loadingContext);
    const [themeIsLight, setThemeIsLight] = useContext(themeContext);
    const [showProductsNotFound, setShowProductsNotFound] = useState(false);

    const handleGetCategory = async (restaurantId) => {
        try {
            setLoading(true)
            let { data: res } = await getMainCategories(`restaurantId=${restaurantId}&tableId=${searchParams.get("tableId")}&foodType=${searchParams.get("categoryType")}`)
            if (res.data) {
                // console.log(res.data)
                setLoading(false)
                setCategoryMainArr(res.data)
                // setCategoryArr(res.data)
            }
        }
        catch (err) {
            setLoading(false)
            toastError(err)
        }
    }


    useEffect(() => {
        if (restaurantObj && restaurantObj._id) {
            handleGetCategory(restaurantObj?.menu_sharing_code)
        }
    }, [restaurantObj])


    const handleNavigateToCategoryPage = (catId, categoryName) => {

        searchParams.set("maincategoryId", catId)
        searchParams.set("previousRoute", location.pathname.split("/")[1])
        navigation({
            pathname: "/FoodCategoryProducts", search: encodeURI(searchParams)
        })
    }




    useEffect(() => {
        let tempArr = categoryMainArr
        if (searchParams.get("allergen")) {
            let tempSelectedAllergenArr = decodeURIComponent(searchParams.get("allergen")).split(",");
            // console.log(decodeURIComponent(searchParams.get("allergen")).split(","), 'searchParams.get("allergen")')
            tempArr = tempArr.filter(el => el.allergensArr.some(ele => tempSelectedAllergenArr.some(elx => elx == ele)))
        }
        if (searchParams.get("filter") == "veg") {
            tempArr = tempArr.filter(el => el.categoryArr.some(el => el.categoryType == "veg"))
        }
        // console.log(tempArr, "tempArr")
        setCategoryArr([...tempArr])

    }, [categoryMainArr, searchParams])


    const navigation = useNavigate()

    useEffect(() => {
        if (loading == false) {
            setTimeout(() => {
                setShowProductsNotFound(true)
            }, 1000)
        }
        return setShowProductsNotFound(false)
    }, [loading])


    return (
        <>


            {
                loading && <LoadingPage />
            }
            {/* <div className="sticky-top"> */}
            <Header
                showSearch
                // showFilterInPlaceOfBack
                showFilter
                showBack
                showBill
                text="Select Category"
                backUrl={`/SelectCategory`}
            />
            {/* </div> */}

            <div className='container-fluid '>

                <RenderVideo mainVideo={bgVideo} />

                <div className="row d-flex justify-content-around mt-5">
                    {
                        categoryArr && categoryArr.length > 0 ? categoryArr.map((el, index) => {
                            return (


                                <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-6 my-2" onClick={() => handleNavigateToCategoryPage(el._id, el.categoryname)}>
                                    <div className="menu-btn">
                                        <div className={`menu-btn-img ${themeIsLight ? "brdr-color-black" : "brder-color-white"}`}

                                        // "menu-btn-img" style={{ borderColor: themeIsLight ? "rgba(0, 0, 0,0.4)" : "rgb(194, 194, 194)" }}
                                        >
                                            <img src={generateFilePath(el.image)} alt="" className={themeIsLight ? "imageToBlack" : "" + 'img-fluid'} />
                                        </div>
                                    </div>
                                    <div className="category-name" style={{ color: themeIsLight ? "black" : "white" }}>{el.name}</div>

                                </div>

                            )
                        })
                            :
                            <div className="no-categories-found">
                                {
                                    showProductsNotFound &&
                                    <span>
                                        No Categories Found
                                    </span>
                                }
                            </div>
                    }
                </div>
                <TextBottomBar />
            </div>

        </>
    )
}
