import React, { useContext, useEffect, useState } from "react";
import { BsCheck, BsFilter } from "react-icons/bs";
import { FaChevronLeft } from "react-icons/fa";
import { MdClear } from "react-icons/md";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import bgVideo from "../../assets/back.mp4";
import blckiconfilter from "../../assets/blacknonvegetarian.png";
import iconfilter from "../../assets/wine.png";
import RenderVideo from "../ReuseableComponents/RenderVideo";
import Header from "../ReuseableComponents/Header";
import { images } from "../../utils/images";
import { restaurantContext, themeContext } from "../../App";
import { generateFilePath } from "../../utils/utils";
import { toastError } from "../../utils/toastUtils";
import { getAllergens } from "../../services/Allergens.service";
import { Checkbox } from "@mui/material";
export default function Filter() {
  const [mainAllergensArr, setMainAllergensArr] = useState([]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [activeitem, setActiveitem] = useState("");
  const [restaurantObj, setRestaurantObj] = useContext(restaurantContext);
  const [allergensArr, setAllergensArr] = useState([]);
  const [selectedAllergen, setSelectedAllergen] = useState({});
  const [themeIsLight, setThemeIsLight] = useContext(themeContext);

  const [filteritem, setFilteritem] = useState([
    // {
    //     image: restaurantObj?.nonAlcoholicIcon && restaurantObj?.nonAlcoholicIcon != "" ? generateFilePath(restaurantObj?.nonAlcoholicIcon) : images.beverage,
    //     activeimg: blckiconfilter,
    //     itemname: "Non alcoholic",
    //     category: "nonalcoholic",
    //     active: false,
    // },
    // {
    //     image: restaurantObj?.alcoholicIcon && restaurantObj?.alcoholicIcon != "" ? generateFilePath(restaurantObj?.alcoholicIcon) : images.beverage,
    //     itemname: "Alcoholic",
    //     category: "alcoholic",
    //     active: false,
    // },
    {
      image:
        restaurantObj?.vegIcon && restaurantObj?.vegIcon != ""
          ? generateFilePath(restaurantObj?.vegIcon)
          : images.veg,
      activeimg: blckiconfilter,
      itemname: "I am Vegetarian",
      category: "veg",
      active: false,
    },
    {
      image:
        restaurantObj?.nonVegIcon && restaurantObj?.nonVegIcon != ""
          ? generateFilePath(restaurantObj?.nonVegIcon)
          : images.nonVeg,
      itemname: "I eat everthing",
      category: "nonVeg",
      active: false,
    },
  ]);

  useEffect(() => {
    let tempArr = [
      {
        image:
          restaurantObj?.vegIcon && restaurantObj?.vegIcon != ""
            ? generateFilePath(restaurantObj?.vegIcon)
            : images.veg,
        activeimg: blckiconfilter,
        itemname: "I am Vegetarian",
        category: "veg",
        active: false,
      },
      {
        image:
          restaurantObj?.nonVegIcon && restaurantObj?.nonVegIcon != ""
            ? generateFilePath(restaurantObj?.nonVegIcon)
            : images.nonVeg,
        itemname: "I eat everthing",
        category: "nonVeg",
        active: false,
      },
    ];

    if (restaurantObj && searchParams.get("filter") && tempArr) {
      let tempfilterItemsArr = tempArr;
      tempfilterItemsArr = tempfilterItemsArr.map((el) => {
        // console.log(
        //   el.category.replaceAll(" ", "") ==
        //   searchParams.get("filter").replaceAll(" ", ""),
        //   el.category.replaceAll(" ", ""),
        //   searchParams.get("filter").replaceAll(" ", ""),
        //   'searchParams.get("filter")'
        // );
        if (
          el.category.replaceAll(" ", "") ==
          searchParams.get("filter").replaceAll(" ", "")
        ) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      });
      setFilteritem([...tempfilterItemsArr]);
    }
  }, [restaurantObj, searchParams.get("filter")]);

  const handleGetAllAllergens = async () => {
    try {
      let { data: res } = await getAllergens();
      if (res.data) {
        setMainAllergensArr(res.data.map((el) => ({ ...el, active: false })));
        setAllergensArr(res.data.map((el) => ({ ...el, active: false })));
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGetAllAllergens();
  }, []);

  const handleActive = (index) => {
    let tempObj = [...filteritem];
    tempObj = tempObj.map((el, i) =>
      i === index ? { ...el, active: true } : { ...el, active: false }
    );
    // console.log(tempObj, "tempObjtempObjtempObj");
    setFilteritem([...tempObj]);
  };

  const handleFilter = () => {
    let tempObj = filteritem.find((el) => el.active == true);
    searchParams.set(
      "allergen",
      allergensArr
        .filter((ele) => ele.active)
        .reduce(
          (acc, el, index) =>
            acc +
            `${el.name}${index != allergensArr.filter((ele) => ele.active).length - 1
              ? ","
              : ""
            }`,
          ""
        )
    );
    if (tempObj) {
      searchParams.set("filter", tempObj.category);
      setSearchParams((searchParams) => {
        return searchParams;
      });
    }
    navigate({
      pathname: `/${searchParams.get("previousRoute")}`,
      search: encodeURI(searchParams),
    });
  };

  const handleClearFilter = () => {
    searchParams.delete("filter");
    searchParams.delete("allergen");
    setFilteritem((prev) => {
      return prev.map((el) => ({ ...el, active: false }));
    });
    setAllergensArr((prev) => {
      return prev.map((el) => ({ ...el, active: false }));
    });
    navigate({ pathname: location.pathname, search: encodeURI(searchParams) });
  };

  const handleCheckIfFilterIsSelected = () => {
    if (filteritem.some((el) => el?.active)) {
      return true;
    } else {
      return false;
    }
  };

  const handleSelectAllergens = (obj) => {
    let tempArr = allergensArr.map((el) => {
      if (el.name == obj.name) {
        el.active = !el.active;
      }
      return el;
    });
    setAllergensArr([...tempArr]);
  };

  useEffect(() => {
    // console.log("asd");
    if (searchParams.get("allergen")) {
      let tempSelectedAllergenArr = decodeURI(
        searchParams.get("allergen")
      ).split("%2C");
      // console.log(tempSelectedAllergenArr, "tempSelectedAllergenArr");
      let tempArr2 = mainAllergensArr.map((el) => {
        if (tempSelectedAllergenArr.some((ele) => ele == el.name)) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      });

      setAllergensArr([...tempArr2]);
    }
  }, [
    searchParams.get("filter"),
    searchParams.get("allergen"),
    mainAllergensArr,
  ]);

  return (
    <>
      <RenderVideo mainVideo={bgVideo} />
      <Header showBack showBillSearchCombineTop />

      <div className="filterContainer">
        <div className="filter_item">
          <div className="container-fluid">
            <div className="row d-flex">
              {filteritem.map((el, indes) => {
                return (
                  <div key={indes} className="col-6 col-md-6 col-lg-6">
                    <div
                      onClick={() => handleActive(indes)}
                      className={`filter-card`}
                    >
                      <div
                        className={`iconborder ${el?.active == true ? "active" : ""
                          }`}
                        style={{
                          borderColor: themeIsLight
                            ? "rgba(0, 0, 0,0.4)"
                            : "rgb(194, 194, 194)",
                        }}
                      >
                        <img
                          src={el?.image}
                          alt=""
                          className={
                            themeIsLight ? "imageToBlack blackimg" : "blackimg"
                          }
                        />
                      </div>
                      <h5 style={{ color: themeIsLight ? "black" : "white" }}>
                        {el?.itemname}
                      </h5>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {
        // handleCheckIfFilterIsSelected() &&
        <div className="row mt-5 pl-20">
          {allergensArr &&
            allergensArr.length > 0 &&
            allergensArr.map((el, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleSelectAllergens(el)}
                  className={`allergenItems ${el?.active ? "activeAllergen" : ""
                    }`}
                >
                  <Checkbox checked={el.active} />
                  <span>{el.name}</span>
                </div>
              );
            })}
        </div>
      }
      <div className="apply-clear-btns mt-5">
        <div className="row mx-auto align-items-center">
          <div className="col-6">
            <button
              className=" buttn-clear"
              onClick={() => handleClearFilter()}
            >
              {" "}
              {/* <MdClear /> */}
              Clear{" "}
            </button>
          </div>
          <div className="col-6">
            <button className=" buttn-filter" onClick={() => handleFilter()}>
              {" "}
              {/* <BsCheck />  */}
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
