import React, { useContext, useEffect, useRef, useState } from "react";
import bgVideo from "../../assets/back.mp4";
import RenderVideo from "../ReuseableComponents/RenderVideo";
import Header from "../ReuseableComponents/Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { images } from "../../utils/images";
import { restaurantContext, themeContext } from "../../App";
import { generateFilePath } from "../../utils/utils";
import { useCart, useRefreshCart } from "../CustomHooks/cartHooks";
import { Modal } from "react-bootstrap";
import { clearCart } from "../../services/tableCart.service";
import { toastSuccess } from "../../utils/toastUtils";
// import TextBottomBar from "./TextBottomBar";
export default function SelectCategory() {
  const navigate = useNavigate();
  const [restaurantObj, setRestaurantObj] = useContext(restaurantContext);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [themeIsLight, setThemeIsLight] = useContext(themeContext);
  const refreshCart = useRefreshCart();

  const [modal, setModal] = useState(false);

  const handleClose = () => setModal(false);

  const handleShow = () => setModal(true);

  const cartArr = useCart();

  const headerRef = useRef();
  useEffect(() => {
    let firstLoad = localStorage.getItem("firstLoad")
    if ((cartArr.length > 0) && firstLoad) {
      console.log(cartArr, "cart arr")
      handleShow()
    }
  }, [cartArr])


  useEffect(() => {
    if (headerRef && headerRef.current) {
      // console.log(window.innerHeight)
      if (window.innerHeight < 700) {
        setHeaderHeight((window.innerHeight - headerRef.current.clientHeight));
      }
      else {
        setHeaderHeight((window.innerHeight - headerRef.current.clientHeight) - 100);
      }
    }
  }, [headerRef, headerRef.current])


  const handleBeverage = () => {
    searchParams.set("categoryType", "beverage");
    searchParams.set("previousRoute", "SelectCategory");
    searchParams.delete("maincategoryId");
    searchParams.delete("categoryId");
    navigate({
      pathname: "/BeveragesCategory",
      search: encodeURI(searchParams),
    });
  };


  const handleDashRecomendation = () => {
    searchParams.set("categoryType", "beverage");
    searchParams.set("previousRoute", "SelectCategory");
    searchParams.delete("maincategoryId");
    searchParams.delete("categoryId");
    navigate({
      pathname: "/RecomendationCategory",
      search: encodeURI(searchParams),
    });
  };

  const handleFood = () => {
    searchParams.set("categoryType", "food");
    searchParams.set("previousRoute", "SelectCategory");
    searchParams.delete("maincategoryId");
    searchParams.delete("categoryId");
    navigate({
      pathname: "/MainCategory",
      search: encodeURI(searchParams),
    });
  };

  useEffect(() => {
    searchParams.delete("maincategoryId");
    searchParams.delete("categoryId");
    searchParams.delete("categoryType");
    searchParams.delete("previousRoute");

    navigate({
      pathname: "/SelectCategory",
      search: encodeURI(searchParams),
    });
    // console.log(searchParams.forEach(el => el.   ), "searchParams")
  }, [searchParams]);

  const handleClearCart = async () => {
    try {
      let obj = {
        tableId: searchParams.get("tableId"),
        tableNo: searchParams.get("tableNo"),
        restaurantId: searchParams.get("restId")
      }
      let { data: res } = await clearCart(obj)
      if (res.message) {
        toastSuccess(res.message);
        refreshCart()
        handleClose()
        localStorage.removeItem("firstLoad")
      }
    }
    catch (err) {

    }
  }


  return (
    <>
      <div ref={headerRef}>
        <Header
          showBillIconOnTop />
      </div>
      <RenderVideo mainVideo={bgVideo} />
      <div style={{
        height: headerHeight,
        // marginTop: `-${headerHeight - 10}px`
      }} className="container-fluid d-flex justify-content-center align-items-center">
        <div className="select-category-main-box">
          <div className="row d-flex mt-1 px-3">
            <div className="col-12" onClick={() => handleDashRecomendation()}>

              {/* <div className="row"> */}
              <div className="black-logo" style={{ marginTop: 0 }}>
                {/* {themeIsLight ? (
<img src={images.Dashblacklogo} alt="" className=" img-fluid" />
) : ( */}
                <img src={images.Dashwhitelogo} alt="" className={`${themeIsLight ? "imageToBlack" : ""} img-fluid`} />
                {/* )} */}
              </div>
              {/* </div> */}

            </div>
            <div className="col-12 category-box-card" style={{ marginTop: 20 }}>
              <div
                className="product-cardbill d-flex"
                onClick={() => handleFood()}
              >
                <div
                  className="d-flex align-items-center justify-content-center flex-1"
                  style={{ flex: 1 }}
                >
                  <div
                    className="product-cardbill-white-item"
                    style={{
                      borderColor: themeIsLight
                        ? "black"
                        : "rgb(194, 194, 194)",
                    }}
                  >
                    <img
                      src={
                        restaurantObj?.foodIcon && restaurantObj?.foodIcon != ""
                          ? generateFilePath(restaurantObj?.foodIcon)
                          : images.food
                      }
                      alt=""
                      className={
                        themeIsLight ? "imageToBlack" : "" + "img-fluid"
                      }
                      srcset=""
                    />
                  </div>
                </div>
              </div>
              <span
                className="product-cardbill-white-item-text"
                style={{ color: themeIsLight ? "black" : "white" }}
              >
                Food
              </span>
            </div>
            <div
              className="col-12"
              style={{ display: "grid", placeItems: "center" }}
            >
              <div
                className="product-cardbill d-flex"
                onClick={() => handleBeverage()}
              >
                <div
                  className="d-flex align-items-center justify-content-center flex-1"
                  style={{ flex: 1 }}
                >
                  <div
                    className="product-cardbill-white-item"
                    style={{
                      borderColor: themeIsLight
                        ? "black"
                        : "rgb(194, 194, 194)",
                    }}
                  >
                    <img
                      src={
                        restaurantObj?.beverageIcon &&
                          restaurantObj?.beverageIcon != ""
                          ? generateFilePath(restaurantObj?.beverageIcon)
                          : images.beverage
                      }
                      alt=""
                      className={
                        themeIsLight ? "imageToBlack" : "" + "img-fluid"
                      }
                      srcset=""
                    />
                  </div>
                </div>
              </div>
              <span
                className="product-cardbill-white-item-text"
                style={{ color: themeIsLight ? "black" : "white" }}
              >
                Beverage
              </span>
            </div>


          </div>
        </div>
        <Modal
          show={modal}
          // onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          className={`${themeIsLight ? "modelvaraitionLight" : 'modelvaraition'} prod - selectaddons`}

        // className='modelvaraition prod-selectaddons'
        >
          <Modal.Header closeVariant={themeIsLight ? "black" : "white"} >
            <Modal.Title> <h4 style={{ color: themeIsLight ? "black" : "white" }}>We already have a cart running for this table do you want to ?</h4> </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <button className='btn btnclose' onClick={() => handleClearCart()} style={{ color: themeIsLight ? "white" : "black", width: "45%", backgroundColor: themeIsLight ? "black" : "white" }}>
              Clear previous
            </button>
            <button className='btn btnclose' onClick={() => { handleClose(); localStorage.removeItem("firstLoad") }} style={{ color: "black", backgroundColor: "white", width: "45%", borderColor: themeIsLight ? "black" : "transparent" }}>
              Proceed now
            </button>
          </Modal.Footer>
        </Modal>
      </div>

      {/* <TextBottomBar /> */}
    </>
  );
}
