import React from 'react'
import logo from "../../assets/logo.png";
import bgVideo from "../../assets/back.mp4";
import RenderVideo from "../ReuseableComponents/RenderVideo";
import { Form,InputGroup } from 'react-bootstrap';
import { BsArrowRight } from 'react-icons/bs';
function OtpConfirmation() {
  return (
    <>
   <header className='reg-mobile-header'>
    <div className="logo-img">
    <img src={logo} alt="" className="img-fluid" style={{ height: 60, width: 60 }} />
    </div>
    </header>
    <div className="container-fluid">

    <RenderVideo mainVideo={bgVideo} />
  

{/* otp-bg-img */}

<section className="otp-confirmation-sec">
    <div className="otp-confirmation-bg">
    <div className="container">
        <div className="row">
  
     
        </div>
    </div>
    </div>
</section>
<p className="otp-confirm-msg">
We’re here to serve you.
Bear with us, while our service man 
will confirm the OTP with you.
   </p>

<div className="otp-confirmation-info">
    <div className="row">
    <div className="col-9 col-xl-9">
        <InputGroup className="mb-4 otp-ip">
        <Form.Control
          placeholder="ENTER OTP"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>
        </div>
        <div className="col-xl-3 col-3">
<div className="confirm-buttn">
    <BsArrowRight/>
</div>
        </div>
    </div>


</div>

</div>
    </>
  )
}

export default OtpConfirmation