import React, { useContext, useEffect, useState } from "react";
import { BsBag, BsFilter } from "react-icons/bs";
import { FaChevronLeft, FaSearch } from "react-icons/fa";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { restaurantContext, themeContext } from "../../App";
import { getRestaurantsById } from "../../services/restaurant.service";
import { toastError } from "../../utils/toastUtils";
import { useCart, useRefreshCart } from "../CustomHooks/cartHooks";
import SpeedChecker from "./SpeedChecker";
import { images } from "../../utils/images";
import { getCart, getCartAddon } from "../../services/tableCart.service";
import { Modal } from "react-bootstrap";
export default function Header({
  showFilterInPlaceOfBack,
  showHeadingInPlaceOfText,
  showCartHeadingInPlaceOfText,
  showCartInPlaceofSearch,
  showCartIconOnTop,
  showBillIconOnTop,
  showBillSearchCombineTop,
  showCart,
  showBill,
  showSearch,
  showFilter,
  showBack,
  backUrl,
  text,
}) {
  const refreshCart = useRefreshCart();
  const navigate = useNavigate();
  const location = useLocation();
  const cartArr = useCart();
  const [themeLoaded, setThemeLoaded] = useState(false);
  const [restaurantObj, setRestaurantObj] = useContext(restaurantContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [themeIsLight, setThemeIsLight] = useContext(themeContext);
  const [sentToKitchen, setSentToKitchen] = useState(false);
  const [confirmBillModal, setConfirmBillModal] = useState(false);

  useEffect(() => {
    refreshCart();
  }, []);
  useEffect(() => {
    let timeOut = setTimeout(() => {
      setThemeLoaded(true);
    }, 350);

    return () => {
      clearTimeout(timeOut);
      setThemeLoaded(false);
    };
  }, []);
  useEffect(() => {
    if (!localStorage.getItem("previousRoute") || localStorage.getItem("previousRoute") == "") {
      localStorage.setItem("previousRoute", location.pathname);
    }
    else {
      if (localStorage.getItem("previousRoute") !== location.pathname) {
        localStorage.setItem("previousRoute", location.pathname);
      }
      else {
        // console.log("already on the same route")
      }
    }

  }, [location.pathname]);

  ///////////Restaurant
  const handleGetRestaurant = async () => {
    try {
      let { data: res } = await getRestaurantsById(searchParams.get("restId"));
      if (res.data) {
        // console.log(res.data, "restaurantObj");
        setRestaurantObj(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };
  useEffect(() => {
    if (!searchParams.get("restId")) {
      toastError("Invalid Qr scanned !!!");
    } else {
      // if (!restaurantObj && !restaurantObj?.name) {
      handleGetRestaurant();
      // }
    }
  }, [searchParams.get("restId")]);

  useEffect(() => {
    setRestaurantObj(restaurantObj);
  }, [restaurantObj, location]);
  ///////////Restaurant

  const handleGoBack = () => {
    let previousLocation = location.pathname.split("/")[1]
    // console.log(searchParams.get("previousRoute"), backUrl, location, "location", previousLocation, "previousLocation");
    if (backUrl) {
      navigate({ pathname: backUrl, search: encodeURI(searchParams) });
    } else if (searchParams.get("previousRoute") && searchParams.get("previousRoute") != "") {
      // console.log("BACK USING PREVIOUS ROUTE");
      navigate({
        pathname: `/${searchParams.get("previousRoute")}`,
        search: encodeURI(searchParams),
      });
      // console.log(previousLocation, "previousLocation")
      searchParams.set("previousRoute", previousLocation)
    } else {
      navigate(-1);
    }
  };
  const [isClassAdded, setClassAdded] = useState(true);
  const toggleClass = () => {
    setThemeIsLight(!themeIsLight)
    setClassAdded((prevState) => !prevState); // Toggle the state value
  };


  const GetCartIsSentToKitchen = async () => {
    try {
      let { data: res } = await getCart(searchParams.get("tableId"), searchParams.get("tableNo"), searchParams.get("restId"))
      if (res?.data) {
        // console.log("data, eerr", res.data.sentToKitchen)
        setSentToKitchen(res.data.sentToKitchen);
        // setCartAddonArr(res.data);
      }
      else {
        setSentToKitchen(false);
      }
    }
    catch (err) {
      toastError(err);
    }

  }


  useEffect(() => {
    GetCartIsSentToKitchen()
    // console.log(cartArr, "cartArr")
  }, [cartArr])

  const handleViewBill = () => {

    searchParams.set(
      "previousRoute",
      location.pathname.split("/")[1]
    );

    navigate({
      pathname: "/Bill",
      search: encodeURI(searchParams),
    });

  }
  return (
    <div
      className="header-container-container px-2"
      style={{ backgroundColor: "transparent" }}
    >
      <div className="header-container">
        <div className="d-flex">
          {showBack && (
            <div onClick={() => handleGoBack()} className="back-button">
              <FaChevronLeft
                style={{
                  color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white",
                  width: 15,
                }}
              />
            </div>
          )}

          <div
            onClick={(e) => e.preventDefault()}
            style={{ zIndex: 1 }}
            className={`switch-toggle ms-3  ${themeLoaded && "themeloaded"}`}
          >
            {/* 
            
            */}
            <input
              type="checkbox"
              // onChange={(e) => setThemeIsLight(!e.target.checked)}
              id="toggle"
              className="toggle--checkbox"
              checked={themeIsLight}
            />
            <label htmlFor="toggle" className={`toggle--label`}>
              <div
                className="position-relative checktoggle"
                onClick={toggleClass}
              >
                <p className={!themeIsLight ? "noneclass" : "whitetheme"}>
                  Dark
                </p>
                <span className="toggle--label-background"></span>
                <p className={!themeIsLight ? "blcktheme" : "noneclass"}>
                  Light
                </p>
              </div>
            </label>
            <div className="background"></div>
          </div>
        </div>

        {showFilterInPlaceOfBack && (
          <>
            <div
              className="svgicon"
              onClick={() => {
                // console.log(location.pathname.split("/"), "location.pathname");
                searchParams.set(
                  "previousRoute",
                  location.pathname.split("/")[1]
                );

                navigate({
                  pathname: "/Filter",
                  search: encodeURI(searchParams),
                });
              }}
            >
              <BsFilter
                style={{
                  color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white",
                  width: 15,
                }}
              />
            </div>
          </>
        )}
        <div
          className="header-title d-flex"
          style={{ display: "flex", flexDirection: "column", zIndex: 0 }}
        // onClick={() =>
        //   navigate({
        //     pathname: "/SelectCategory",
        //     search: encodeURI(searchParams),
        //   })
        // }
        >
          {showHeadingInPlaceOfText && (
            <span
              className="search-heading"
              style={{ color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white" }}
            >
              Search
            </span>
          )}
          {showCartHeadingInPlaceOfText && (
            <span
              className="search-heading"
              style={{ color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white" }}
            >
              Your Cart
            </span>
          )}

          {text != "" && (
            <span
              className="pt-2 mt-5 main-heading"
              style={{ color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white" }}
              onClick={() => {
                searchParams.delete("categoryType");
                searchParams.delete("previousRoute");
                searchParams.delete("maincategoryId");
                searchParams.delete("categoryId");
                navigate({
                  pathname: "/SelectCategory",
                  search: encodeURI(searchParams),
                });
              }}
            >
              {text}
            </span>
          )}
        </div>
        {showCartInPlaceofSearch && (
          <div
            onClick={() => {
              searchParams.delete("previousRoute");
              searchParams.set(
                "previousRoute",
                location.pathname.split("/")[1]
              )
              navigate({
                pathname: "/Cart",
                search: encodeURI(searchParams),
              })
            }
            }
            style={{ position: "relative", padding: "0px 10px 0px 0px" }}
          >
            {cartArr.length > 0 && (
              <span
                className="nuubarcart1"
                style={{ color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white" }}
              >
                {cartArr.length}
              </span>
            )}
            <BsBag style={{ width: 15 }} />
            {/* <img src={Cart} style={{ height: 20, width: 20 }} alt="" /> */}
          </div>
        )}

        {showSearch && (
          <>
            <div className="col-6"></div>
            <div
              onClick={() => {
                searchParams.set(
                  "previousRoute",
                  location.pathname.split("/")[1]
                );

                navigate({
                  pathname: "/SearchProducts",
                  search: encodeURI(searchParams),
                });
              }}
              className="search-button"
            >
              <FaSearch
                style={{
                  color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white",
                  width: 15,
                }}
              />
            </div>


          </>
        )}

        {
          showBillSearchCombineTop && (
            <div className="row">
              <div className="col-6 m-0 p-0">

              </div>

              <div className="d-f-j-c-e">
                <div
                  onClick={() => {
                    searchParams.delete("previousRoute");
                    searchParams.set(
                      "previousRoute",
                      location.pathname.split("/")[1]
                    );

                    // console.log(searchParams, "searchParams")
                    navigate({
                      pathname: "/SearchProducts",
                      search: encodeURI(searchParams),
                    });
                  }}
                  className="search-button"
                >
                  <FaSearch
                    style={{
                      color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white",
                      width: 15,
                    }}
                  />
                </div>
                {
                  sentToKitchen &&

                  <span className="view-bill-buttn ml-10" onClick={() => {
                    setConfirmBillModal(true)

                  }
                  }>
                    <img
                      src={images.DashMainbill}
                      alt=""
                      className={`${themeIsLight ? "imageToBlack" : ""} img-fluid`}
                      style={{ width: "18px", height: "18px" }}
                    />

                  </span>
                }

              </div>


            </div>

          )

        }

        {showBillIconOnTop && sentToKitchen && (
          <>
            <div className="col-6 m-0 p-0">

            </div>
            <div className="d-f-j-c-e">
              <span className="view-bill-buttn ml-10" onClick={() => {
                searchParams.set(
                  "previousRoute",
                  location.pathname.split("/")[1]
                );

                navigate({
                  pathname: "/Bill",
                  search: encodeURI(searchParams),
                })
              }
              }>
                <img
                  src={images.DashMainbill}
                  alt=""
                  className={`${themeIsLight ? "imageToBlack" : ""} img-fluid`}
                  style={{ width: "18px", height: "18px" }}
                />
                {/* this is black image of view-bill */}
                {/* <img
                  src={images.DashMainbill}
                  alt=""
                  className="img-fluid"
                  style={{ width: "18px", height: "18px" }}
                /> */}
              </span>
            </div>
          </>
        )
        }

        {showCartIconOnTop && (
          <>
            <div className="col-6 m-0 p-0"></div>
            <div
              className="d-f-j-c-e"
              onClick={() => {
                searchParams.delete("previousRoute");
                searchParams.set(
                  "previousRoute",
                  location.pathname.split("/")[1]
                );
                navigate({
                  pathname: "/Cart",
                  search: encodeURI(searchParams),
                })
              }
              }
              style={{
                color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white",
                position: "relative",
              }}
            >
              {cartArr.length > 0 && (
                <span
                  className="nuubarcart"
                  style={{
                    top: 1,
                    right: 7,
                    backgroundColor: themeIsLight ? "black" : "white",
                    color: themeIsLight ? "white" : "rgba(0, 0, 0, 1)",
                  }}
                >
                  {cartArr.length}
                </span>
              )}
              <BsBag
                style={{
                  color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white",
                  width: 15,
                }}
              />
            </div>


          </>
        )}
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-4">
            <div className="row">
              <div className="col-4 m-0 p-0">
                {showFilter && !showFilterInPlaceOfBack && (
                  <div
                    className="svgicon"
                    onClick={() => {
                      // console.log(
                      //   location.pathname.split("/"),
                      //   "location.pathname"
                      // );
                      searchParams.set(
                        "previousRoute",
                        location.pathname.split("/")[1]
                      );

                      navigate({
                        pathname: "/Filter",
                        search: encodeURI(searchParams),
                      });
                    }}
                  >
                    <BsFilter
                      style={{
                        color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-8 text-end m-0 p-0 d-f-j-c-e">
            <div className="d-flexbetween">
              {showCart && (
                <div
                  onClick={() => {
                    searchParams.delete("previousRoute");
                    searchParams.set(
                      "previousRoute",
                      location.pathname.split("/")[1]
                    )
                    navigate({
                      pathname: "/Cart",
                      search: encodeURI(searchParams),
                    })
                  }
                  }
                  style={{
                    position: "relative",
                    padding: 0,
                    flex: 1,
                    color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white",
                  }}
                >
                  {cartArr.length > 0 && (
                    <span
                      className="nuubarcart"
                      style={{
                        backgroundColor: themeIsLight ? "black" : "white",
                        color: themeIsLight ? "white" : "rgba(0, 0, 0, 1)",
                      }}
                    >
                      {cartArr.length}
                    </span>
                  )}
                  <BsBag
                    style={{
                      color: themeIsLight ? "rgba(0, 0, 0, 1)" : "white",
                      width: 15,
                    }}
                  />
                </div>
              )}

              {showBill && sentToKitchen && (
                <span className="view-bill-buttn" onClick={() => {
                  setConfirmBillModal(true)

                }}>
                  {/* this is white image */}
                  <img
                    src={images.DashMainbill}
                    alt=""
                    className={`${themeIsLight ? "imageToBlack" : ""} img-fluid`}
                    style={{ width: "18px", height: "18px" }}
                  />
                  {/* this is black image of view bill  */}
                  {/* <img
                    src={images.DashMainbillblack}
                    alt=""
                    className="img-fluid"
                    style={{ width: "18px", height: "18px" }}
                  /> */}
                </span>
              )}
            </div>
          </div>
        </div>
        <Modal
          show={confirmBillModal}
          onHide={() => setConfirmBillModal(true)}
          backdrop="static"
          keyboard={false}
          centered
          className={`${themeIsLight ? "modelvaraitionLight" : 'modelvaraition'} prod - selectaddons`}

        // className='modelvaraition prod-selectaddons'
        >
          <Modal.Header closeButton closeVariant={themeIsLight ? "black" : "white"} >
            <Modal.Title> <h4 style={{ color: themeIsLight ? "black" : "white" }}>Are you sure you want to continue ?</h4> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 style={{ color: themeIsLight ? "black" : "white" }}>This will send a request to the restaurant to generete the final bill and you will not be able to add more items.</h6>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btnclose' style={{ width: "50%", color: themeIsLight ? "white" : "black", backgroundColor: themeIsLight ? "black" : "white" }} onClick={() => handleViewBill()}>
              Continue to bill
            </button>
            <button className='btn btnclose' style={{ color: "black", backgroundColor: "white", borderColor: themeIsLight ? "black" : "transparent" }} onClick={() => setConfirmBillModal(false)}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <SpeedChecker />
    </div>
  );
}
