import beverage from "../assets/newImages/Beverage.png";
import food from "../assets/newImages/food.png";
import order from "../assets/newImages/order.svg";
import viewBill from "../assets/newImages/view-bill.svg";
import veg from "../assets/newImages/i am veg.png";
import nonVeg from "../assets/newImages/i m non veg.png";
import Dashblacklogo from '../assets/newImages/dashblacklogo.png'
import Dashwhitelogo from '../assets/newImages/dashwhitelogo.png'
import DashMainbill from '../assets/newImages/view-bill.svg'
import DashMainbillblack from '../assets/newImages/view-bill-black.png'
export const images = {
    beverage: beverage,
    food: food,
    viewBill: viewBill,
    veg: veg,
    order: order,
    nonVeg: nonVeg,
    Dashblacklogo:Dashblacklogo,
    Dashwhitelogo:Dashwhitelogo,
    DashMainbill:DashMainbill,
    DashMainbillblack:DashMainbillblack,
} 