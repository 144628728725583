import React, { useContext, useEffect } from 'react'
import logo from "../../assets/logo.gif";
import { useSearchParams } from 'react-router-dom';
import { themeContext } from '../../App';

function LoadingPage({ message }) {
    const [searchsParams, setSearchsParams] = useSearchParams()
    const [themeIsLight, setThemeIsLight] = useContext(themeContext);

    useEffect(() => {
        if (searchsParams.get("restId")) {
            localStorage.setItem("RESTAURANT_ID", searchsParams.get("restId"))
        }
    }, [searchsParams.get("restId")])
    return (
        <>
            {/* loading-page */}
            <section className="loading-page">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="main-box">
                                <div className="logo-img">
                                    <img src={logo} alt="" className="img-fluid" />
                                </div>
                            </div>
                            {
                                message &&
                                <div>
                                    <h5 style={{ textAlign: "center", color: themeIsLight ? "black" : "white" }}>{message}</h5>
                                </div>
                            }
                            {/* <div className="loading-content">
                                <h6>It's not an app,
                                    <span>It's an appetiser</span>
                                </h6>
                            </div> */}

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoadingPage